import {
  FilterAutocompleteItem,
  FilterSelectItem,
  FilterTextItem,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { useOptions } from '../../../hooks/UseOptions';
import { SignQueueStatusEnum, SignTypeEnum } from '../../../model';
import { EntityQueryKey } from '../../../query/query.keys';

export function useFilter() {
  const statusOptions = useOptions(SignQueueStatusEnum, 'signer:status.')
  const typeOptions = useOptions(SignTypeEnum, 'signer:signType.')

  return [
    new FilterTextItem({
      field: 'document',
      labelKey: 'signer:field.document',
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'signer:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    new FilterAutocompleteItem({
      field: 'employee',
      labelKey: 'signer:field.employee',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Employee,
    }),
    new FilterSelectItem({
      field: 'status',
      labelKey: 'signer:field.status',
      options: statusOptions,
    }),
    new FilterSelectItem({
      field: 'signType',
      labelKey: 'signer:field.signType',
      options: typeOptions,
    }),
  ];
}
