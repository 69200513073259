import {
  FilterAutocompleteItem,
  FilterDateRangeItem,
  FilterHiddenItem,
  FilterTextItem,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EntityQueryKey } from '../../../query/query.keys';
import { CertificateTypeEnum } from '../../../model';

export function useFilter(type: CertificateTypeEnum) {
  return [
    UUID_FILTER,
    new FilterTextItem({
      field: 'search',
      labelKey: 'certificate:field.name',
    }),
    new FilterTextItem({
      field: 'serialId',
      labelKey: 'certificate:field.serialNumber',
    }),
    new FilterTextItem({
      field: 'hash',
      labelKey: 'certificate:field.thumbprint',
    }),
    new FilterAutocompleteItem({
      field: 'user',
      labelKey: 'common:field.user',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'common:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    new FilterAutocompleteItem({
      field: 'employee',
      labelKey: 'common:field.employee',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Employee,
    }),
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    useBoolFilterItem(
      {
        field: 'isValid',
        labelKey: 'certificate:status.valid',
      },
      'true',
    ),
    useBoolFilterItem(
      {
        field: 'isRevoked',
        labelKey: 'certificate:status.revoked',
      },
      'false',
    ),
    new FilterHiddenItem({
      field: 'type',
      value: type,
    }),
  ];
}
