import { useTranslation } from 'react-i18next';
import { useColumns } from './table.definition';
import { useFilter } from './filter.definition';
import { useActions } from './action.definition';
import { DataTable, Title } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { EmployeeService } from '../../../api/employee/employee.service';

export function EmployeeList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('employee:list.pageTitle')} />
      <DataTable
        id="employee-list"
        queryKey={EntityQueryKey.Employee}
        columns={columns}
        fetchData={EmployeeService.listFn}
        filter={filter}
        sortBy="user"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}