import React, { useCallback } from 'react';
import { PhotoLibrary, PictureAsPdf } from '@mui/icons-material';

export function useFileIcon() {
  return useCallback((type: string) => {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
        return <PhotoLibrary/>;
      case 'application/pdf':
        return <PictureAsPdf/>;
      default:
        return null;
    }
  }, []);
}