import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { CertificateList } from '../CertificateList';
import { CERTIFICATE_ROUTES } from '../../route';
import { CertificateTypeEnum } from '../../../model';

export const CERTIFICATE_ROUTE = (
  <Route>
    <Route
      path={CERTIFICATE_ROUTES.list(`/${CertificateTypeEnum.INNER_OPENSSL}`)}
      element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}
    >
      <Route path="" element={<CertificateList type={CertificateTypeEnum.INNER_OPENSSL} />} />
    </Route>
  </Route>
);
