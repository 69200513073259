import { UserAction } from '../../../model/actions/user.action';
import { SnilsBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import {
  useAddAction,
  useEditAction,
  useRemoveAction,
  useToggleActiveAction,
} from '../../../model/actions/common.actions';
import { SNILS_ROUTES } from '../../route';
import { SnilsService } from '../../../api/snils/snils.service';

type AvailableActions = 'add' | 'edit' | 'remove' | 'toggleActive' | 'toggleVerified';

export function useActions(): Record<AvailableActions, UserAction<SnilsBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: SnilsBrief) => entity != undefined &&  !entity.isDeleted && user != null && (user.isAdmin || user.uuid === entity.userUuid),
    [user],
  );
  const add = useAddAction<SnilsBrief>(SNILS_ROUTES);
  const edit = useEditAction<SnilsBrief>(SNILS_ROUTES, (entity) => !entity?.isVerified && canModify(entity));
  const remove = useRemoveAction<SnilsBrief>(SnilsService, (entity) => canModify(entity));
  const toggleActive = useToggleActiveAction<SnilsBrief>({
    service: SnilsService,
    available: (entity) => entity?.isVerified === true && canModify(entity),
    localeBtnNS: 'snils',
    localeConfirmNS: 'snils',
  });

  return {
    add,
    edit,
    remove,
    toggleActive,
    toggleVerified: {
      key: 'toggleVerified',
      btnText: () => t('client:button.toggleVerified.false'),
      btnColor: (entity) => entity?.isVerified ? 'warning' : 'success',
      available: (entity) => user?.isAdmin === true && entity?.isVerified === true,
      action: (entity) => SnilsService.toggle('isVerified', entity?.uuid ?? '', false),
      confirm: () => ({
        description: 'snils:confirm.toggleVerified',
      }),
    }
  };
}