import {
  DataGrid,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowId,
  GridRowSelectionModel,
  GridSortModel,
  GridValidRowModel,
  ruRU,
} from '@mui/x-data-grid';
import { DataTableProps, DEFAULT_DATA_TABLE_PROPS } from './data.table.props';
import { useTranslation } from 'react-i18next';
import { getFilter, getFilterModel, loadSettings, saveSettings } from './table.utils';
import { useEffect, useState } from 'react';
import { QueryKey, useQuery } from 'react-query';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { TableLoader } from './table.loader';
import { TableFooter } from './table.footer';
import { useTableRowActionsColumn } from './table.row.actions.column';
import { TablePagination } from './table.pagination';
import { Grid, Icon } from '@mui/material';
import { FontAwesomeScalableIcon } from '../../layout';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TableToolbar } from './table.toolbar';
import { TableFilterPanel } from './table.filter.panel';
import { BaseEntity } from '../../../../model';
import { useLocation } from 'react-router-dom';
import { DataTypeQueryKey } from '../../../../query/query.keys';

export function DataTable<T extends BaseEntity>(options: DataTableProps<T>) {
  const props = {
    ...DEFAULT_DATA_TABLE_PROPS,
    ...options,
  };

  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { state } = useLocation();

  const [settings, setSettings] = useState(loadSettings(props.id, props.onPage, props.sortBy, props.sortOrder));
  const [{ page: currentPage, pageSize }, setPaginationModel] = useState({ page: 0, pageSize: settings.onPage });
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: settings.sortBy, sort: settings.sortOrder }]);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    props.columns
      .filter((item) => item.hideable !== false)
      .reduce(
        (acc, col) => ({
          ...acc,
          [col.field]: !settings.visibleColumns.length || settings.visibleColumns.includes(col.field),
        }),
        {},
      ),
  );

  const [filter, setFilter] = useState(getFilter(props.filter, state)); //todo load saved filter
  const [filterModel, setFilterModel] = useState(getFilterModel(filter));

  const fetchRows = async () => {
    return props.fetchData({
      filter: filterModel,
      sort: settings.sortBy.split(',').filter((s) => s.length > 0).map((item) => ({ column: item, direction: settings.sortOrder })),
      offset: currentPage * pageSize,
      limit: settings.onPage,
      count: true,
    });
  };

  const fetch = (auxModel?: Record<string, any>) => {
    const model = auxModel ?? filterModel;
    filter?.forEach((item) => {
      item.value = model[item.field];
      item.view = model[`${item.field}View`];
    });
    setFilter(() => [...(filter ?? [])]); //todo save filter to storage
    setFilterModel(() => ({ ...model }));
    setFetchEnabled(true);
  };

  const [fetchEnabled, setFetchEnabled] = useState(true);
  const { data, isFetching, refetch } = useQuery(
    [
      DataTypeQueryKey.Table,
      ...new Array<QueryKey>().concat(props.queryKey),
      currentPage,
      pageSize,
      settings.sortBy,
      settings.sortOrder,
      JSON.stringify(filterModel),
    ],
    fetchRows,
    {
      onSettled: () => {
        setFetchEnabled(false);
      },
      onError: (err) => {
        errorHandler(`${props.id} loadDataError`, err);
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
      },
      placeholderData: { items: [], count: 0 },
      enabled: fetchEnabled && filterModel !== undefined,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    saveSettings(props.id, settings);
  }, [settings]);

  function onSortModelChange(newSortModel: GridSortModel) {
    setSettings((prev) => ({
      ...prev,
      sortBy: newSortModel[0]?.field ?? '',
      sortOrder: newSortModel[0]?.sort ?? 'asc',
    }));
    setSortModel(newSortModel);
    setFetchEnabled(true);
  }

  function onPaginationModelChange({ page, pageSize }: GridPaginationModel) {
    setSettings((prev) => ({ ...prev, onPage: pageSize }));
    setPaginationModel({ page, pageSize });
    setFetchEnabled(true);
  }

  function getRowId(row: GridValidRowModel): GridRowId {
    if (!props.itemId) return row.uuid ?? row.id;

    return typeof props.itemId === 'string' ? row[props.itemId] : props.itemId(row);
  }

  function onSelectionModelChange(newSelectionModel: GridRowSelectionModel) {
    setSelectionModel(newSelectionModel);
  }

  function onColumnVisibilityModelChange(newVisibilityModel: GridColumnVisibilityModel) {
    setSettings((prev) => ({
      ...prev,
      visibleColumns: Object.entries(newVisibilityModel)
        .filter(([, visible]) => visible)
        .map(([column]) => column),
    }));
    setColumnVisibilityModel(newVisibilityModel);
  }

  function getMinWidth() {
    return props.columns.length * 75 + (props.rowActions ? 50 : 0) + (props.groupActions ? 50 : 0);
  }

  const rowActionsColumn = useTableRowActionsColumn(props.rowActions ?? [], refetch, props.queryKey);

  const [panelAnchor, setPanelAnchor] = useState<HTMLElement | null>(null);
  const [panelPlacement, setPanelPlacement] = useState<string>();

  return (
    <Grid item xs={12} pl={0}>
      <div style={{ overflowX: 'auto' }}>
        <DataGrid
          columns={props.rowActions?.length ? [rowActionsColumn, ...props.columns] : props.columns}
          disableColumnMenu
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          rows={data?.items ?? []}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => (props.rowActions?.length ? 67 : 51)}
          checkboxSelection={props.groupActions && props.groupActions.length > 0}
          disableRowSelectionOnClick
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
          filterMode="server"
          sortingMode="server"
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          pagination
          paginationMode="server"
          paginationModel={{ page: currentPage, pageSize }}
          pageSizeOptions={[...new Set([settings.onPage, 10, 20, 50])].sort()}
          onPaginationModelChange={onPaginationModelChange}
          rowCount={data?.count ?? 0}
          getRowId={getRowId}
          loading={isFetching}
          slots={{
            footer: TableFooter,
            loadingOverlay: TableLoader,
            columnSortedAscendingIcon: () => (
              <FontAwesomeScalableIcon htmlColor="white" fontSize="small" icon={solid('sort-up')} />
            ),
            columnSortedDescendingIcon: () => (
              <FontAwesomeScalableIcon htmlColor="white" fontSize="small" icon={solid('sort-down')} />
            ),
            pagination: TablePagination,
            columnSelectorIcon: Icon,
            toolbar: TableToolbar,
            filterPanel: TableFilterPanel,
          }}
          slotProps={{
            footer: {
              selectionModel,
              setSelectionModel,
              groupActions: props.groupActions,
              columns: props.columns,
              columnVisibilityModel,
              columnVisibilityChange: onColumnVisibilityModelChange,
              setPanelAnchor,
              setPanelPlacement,
              refetch,
              queryKey: props.queryKey,
            },
            panel: {
              anchorEl: panelAnchor,
              placement: panelPlacement,
              sx: {
                '& .MuiPaper-root': {
                  marginTop: '10px',
                },
              },
            },
            filterPanel: {
              sx: { width: `${panelAnchor?.offsetWidth}px` },
              filter: filter,
              filterModel,
              fetch,
            },
            toolbar: {
              filter: filter,
              filterModel,
              setFilterModel,
              setPanelAnchor,
              setPanelPlacement,
              fetch,
              toolbarActions: props.toolbarActions,
            },
            baseCheckbox: { sx: { p: 0 } },
          }}
          localeText={{
            ...ruRU.components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: t('common:table.dataNotFound') ?? '',
          }}
          autoHeight
          sx={{
            '&.MuiDataGrid-root': { minWidth: getMinWidth(), borderRadius: 0 },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            '& .MuiDataGrid-footerContainer .MuiDataGrid-footerContainer': { border: 'none' },
            '& .MuiDataGrid-columnHeaders': {
              background: 'rgba(99,99,115,.5)',
              color: '#fff',
              textTransform: 'uppercase',
              borderRadius: 0,
            },
            '& .MuiDataGrid-row': { background: '#fff' },
            '& .MuiDataGrid-sortIcon': { color: '#fff' },
            '& .MuiDataGrid-row:hover': { background: '#fff' },
            '& .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
              { outline: 'none' },
            '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          }}
          {...{}}
        />
      </div>
    </Grid>
  );
}
