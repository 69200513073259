import IValidator from './validator';
import ValidationRule from '../validation.rule.enum';
import validator from 'validator';
import isNumberString = validator.isNumeric;

export class IsNumberStringValidator implements IValidator<string> {
  rule = ValidationRule.IsNumberString;

  validate(value: string) {
    return !value || isNumberString(value) ? null : ['common:validation.numberString'] as [string, ...any[]]
  }
}