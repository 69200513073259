import { UserBrief } from '../../interfaces';
import { EditableEntityDto } from '../common';

export class UserEditDto extends EditableEntityDto<UserBrief>{
  email = '';
  lastName = '';
  firstName = '';
  secondName = '';
  phone?: string;
  password?: string;
  passwordConfirmation?: string;
  isActive? = true;

  constructor(entity: UserBrief|undefined) {
    super(entity);
    Object.entries(entity ?? {}).forEach(([key, val]) => {
      if (this.hasOwnProperty(key)) {
        this[key as keyof UserEditDto] = val as never
      }
    });
  }
}