import { CrudService } from '../crud.service';
import { PASSPORT_ROUTES } from '../../page/route';
import { PassportBrief, PassportTypeEnum } from '../../model';

class CPassportService extends CrudService {
  constructor() {
    super('v1/passport', PASSPORT_ROUTES);
  }

  getFullName(passport: PassportBrief | undefined) {
    return [passport?.lastName, passport?.firstName, passport?.secondName].filter((s) => !!s).join(' ');
  }

  getNumberView(entity: PassportBrief | undefined) {
    if (!entity) return '';
    switch (entity.type) {
      case PassportTypeEnum.PASSPORT:
        return `${entity.series} ${entity.number}`;
      default:
        return entity.number;
    }
  }

  fullView(entity: undefined | PassportBrief) {
    return `${this.getFullName(entity)} ${this.getNumberView(entity)}`;
  }
}

export const PassportService = new CPassportService();
