import { useTranslation } from 'react-i18next';
import { DataTable, Title } from '../../../components/common';
import { useFilter } from './filter.definition';
import { useColumns } from './table.definition';
import { EntityQueryKey } from '../../../query/query.keys';
import { useActions } from './action.definition';
import { SnilsService } from '../../../api/snils/snils.service';

export function SnilsList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('snils:list.pageTitle')} />
      <DataTable
        id="passport-list"
        queryKey={EntityQueryKey.Snils}
        columns={columns}
        fetchData={SnilsService.listFn}
        filter={filter}
        sortBy="lastName,firstName,secondName"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}