import {
  FilterAutocompleteItem,
  FilterDateRangeItem,
  FilterSelectItem,
  FilterTextItem,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EntityQueryKey } from '../../../query/query.keys';
import { useOptions } from '../../../hooks/UseOptions';
import { VerificationRequestStatusEnum } from '../../../model';

export function useFilter() {
  const statusOptions = useOptions(VerificationRequestStatusEnum, 'verification:status.');
  return [
    UUID_FILTER,
    new FilterAutocompleteItem({
      field: 'createdBy',
      labelKey: 'common:field.user',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'common:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    new FilterSelectItem({
      field: 'status',
      labelKey: 'common:field.status',
      options: statusOptions,
    }),
    new FilterAutocompleteItem({
      field: 'type',
      labelKey: 'verification:field.type',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.VerifyType,
    }),
    new FilterAutocompleteItem({
      field: 'dataType',
      labelKey: 'verification:field.data',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.VerifyingData,
      multiple: true,
    }),
    useBoolFilterItem({
      field: 'isAutomatic',
      labelKey: 'verification:field.isAutomatic',
    }),
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common:field.updateDate',
    }),
    new FilterTextItem({
      field: 'search',
      labelKey: 'common:field.client',
    }),
  ];
}
