import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

interface IProps {
  key?: string;
  params?: any;
  prevailOnUnmount?: boolean;
}

export function useDocumentTitle({ key, params, prevailOnUnmount = false }: IProps = {}) {
  const defaultTitle = useRef(document.title);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const routeParams = useParams();

  let defaultKey = pathname;
  if (Object.keys(routeParams).length) {
    Object.entries(routeParams).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        defaultKey = defaultKey.replace(paramValue, `:${paramName}`);
      }
    });
  }


  useEffect(() => {
    document.title = `SIGNX: ${t(key ?? `common:pageTitle.${defaultKey}`, { 
      defaultValue: 'SIGNX',
      ...params,
    })}`;
  }, [t, defaultKey, key, params]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}