import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { UserService } from '../../../api/user/user.service';
import { UserBrief } from '../../../model';
import { Check } from '@mui/icons-material';
import { Box, Link } from '@mui/material';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { CLIENT_ROUTES } from '../../route';

export function useColumns() {
  const { t } = useTranslation();
  const isDeleted = useBoolTableColumn<UserBrief>({
    headerName: t('common:field.deletedM'),
    field: 'isDeleted',
  });
  const isActive = useBoolTableColumn<UserBrief>({
    headerName: t('common:field.isActiveM'),
    field: 'isActive',
    sortable: true,
  });
  const isAdmin = useBoolTableColumn<UserBrief>({
    headerName: t('user:field.isAdmin'),
    field: 'isAdmin',
  });

  return useMemo(
    () => [
      new TableLinkColumn<UserBrief>({
        headerName: t('user:field.fullName'),
        field: 'lastName,firstName,secondName',
        text: (row) => UserService.getFullName(row),
        linkProps: (row) => ({ to: UserService.detailsUrl(row.uuid) }),
        sortable: true,
      }),
      isActive,
      new TableColumn<UserBrief>({
        headerName: t('common:field.email'),
        field: 'email',
        renderCell: ({ row }) => (
          <Box className="MuiDataGrid-cellContent">
            <Link
              sx={{ whiteSpace: 'nowrap' }}
              title={row.email}
              component={RouteLink}
              to={`${UserService.detailsUrl(row.uuid as string)}`}
            >
              {row.emailVerified && <Check color="success" fontSize="small" sx={{ mb: '-5px' }} />}
              {row.email}
            </Link>
          </Box>
        ),
      }),
      new TableColumn<UserBrief>({
        headerName: t('common:field.phone'),
        field: 'phone',
        renderCell: ({ row }) => (
          <Box className="MuiDataGrid-cellContent" title={row.phone ?? ''} sx={{ whiteSpace: 'nowrap' }}>
            {row.phoneVerified && <Check color="success" fontSize="small" sx={{ mb: '-5px' }} />}
            {row.phone}
          </Box>
        ),
      }),
      new TableLinkColumn<UserBrief>({
        headerName: t('user:field.clients'),
        field: 'clients',
        text: () => t('user:field.clients'),
        linkProps: (row) => ({
          to: CLIENT_ROUTES.list(),
          state: { user: row.uuid, userView: UserService.getFullName(row) },
        })
      }),
      isAdmin,
      new TableDateTimeColumn<UserBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<UserBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
      isDeleted,
    ],
    [],
  );
}
