import { Link, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../model/constants';

export function Footer(props: TypographyProps) {
  const { data: apiBuild } = useQuery('build', () => process.env.REACT_APP_BRANCH
    ? axios.get<string>(`${API_URL}buildInfo`)
    : Promise.resolve(null)
  );

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`Copyright (${apiBuild?.data}/${process.env.REACT_APP_BRANCH}) © `}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
