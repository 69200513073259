import { UserAction } from '../../../model/actions/user.action';
import {
  useAddAction,
} from '../../../model/actions/common.actions';
import { SIGNER_ROUTES } from '../../route';
import { SignQueueListItem } from '../../../model/interfaces/signqueue';

type AvailableActions = 'add';

export function useActions(): Record<AvailableActions, UserAction<SignQueueListItem>> {
  const add = useAddAction<SignQueueListItem>(SIGNER_ROUTES);

  return { add };
}