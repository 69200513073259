import { PropsWithChildren } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  labelKey: string;
}

export function InnerBlock(props: PropsWithChildren<IProps>) {
  const { t } = useTranslation();
  return (
    <>
      <Grid2 xs={4}>
        <Typography fontWeight="bold">{t(props.labelKey)}</Typography>
      </Grid2>
      <Grid2 xs={8} pl={2}/>
      <Grid2 xs={12} container fontSize="95%" pb={2} sx={{
        '& .MuiTypography-root': { fontSize: '95%' },
        '& .MuiTypography-root.display-label': { pl: 2 },
      }}>
        {props.children}
      </Grid2>
    </>
  );
}
