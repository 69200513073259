import { Box, Button, Grid } from '@mui/material';
import { FilterPanelPropsOverrides, GridPanelContent } from '@mui/x-data-grid';
import React, { useCallback, useState } from 'react';
import { FilterAutocompleteItem, FilterItem } from '../../../../model/filter';
import { FilterTypeEnum } from '../../../../model/filter/enum/filter.type.enum';
import InputControl from '../../ui/InputControl';
import SelectControl from '../../ui/SelectControl';
import DateRangeControl from '../../ui/DateRangeControl';
import { getFilterModel } from './table.utils';
import { useTranslation } from 'react-i18next';
import AutocompleteControl from '../../ui/AutocompleteControl';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';

declare module '@mui/x-data-grid' {
  interface FilterPanelPropsOverrides {
    filter: FilterItem[];
    filterModel: Record<string, any>;
    fetch: (auxModel?: Record<string, any>) => void;
    sx?: any;
  }
}

export function TableFilterPanel(props: FilterPanelPropsOverrides) {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const [filterModel, setFilterModel] = useState(props.filterModel);
  const updateFilter = useCallback((item: FilterItem, value: any, view?: string) => {
    setFilterModel((prev) => ({
      ...prev,
      [item.field]: value,
      [`${item.field}View`]: view,
    }));
  }, []);

  return (
    <GridPanelContent sx={{ p: 2, width: '70vw', ...props.sx }}>
      <Grid container>
        {/*<Grid item xs={0}>*/}
        {/*  здесь будет настройка фильтров (?)*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          {props.filter.map((item, idx) => {
            switch (item.type) {
              case FilterTypeEnum.TEXT:
                return item.field === 'search' ? null : (
                  <InputControl
                    key={idx}
                    labelKey={item.labelKey}
                    value={filterModel[item.field]}
                    onChange={(value) => updateFilter(item, value)}
                  />
                );
              case FilterTypeEnum.SELECT:
                return (
                  <SelectControl
                    key={idx}
                    options={item.options ?? []}
                    labelKey={item.labelKey}
                    value={filterModel[item.field] ?? ''}
                    onChange={(value, option) => {
                      updateFilter(item, value, option?.text);
                    }}
                    nullable={item.nullable}
                  />
                );
              case FilterTypeEnum.DATE_RANGE:
              case FilterTypeEnum.DATETIME_RANGE:
                return (
                  <DateRangeControl
                    key={idx}
                    labelKey={item.labelKey}
                    value={filterModel[item.field] ?? ''}
                    onChange={(value, view) => {
                      updateFilter(item, value, view);
                    }}
                    withTime={item.type === FilterTypeEnum.DATETIME_RANGE}
                  />
                );
              case FilterTypeEnum.AUTOCOMPLETE:
                return (
                  <AutocompleteControl
                    key={idx}
                    multiple={item.multiple === true}
                    value={filterModel[item.field]}
                    entity={(item as FilterAutocompleteItem).queryKey}
                    options={(item as FilterAutocompleteItem).fetch}
                    labelKey={item.labelKey}
                    onChange={(value: any, option: any) => {
                      updateFilter(item, value, Array.isArray(option) ? option.map((o) => o.text).join(', ') : option?.text);
                    }}
                  />
                );
              default:
                return null;
            }
          })}
          <Box display="flex" justifyContent="center" mt={2}>
            <Button variant="outlined" color="success" onClick={() => {
              props.fetch(filterModel);
              apiRef.current.hideFilterPanel();
            }}>
              {t('common:button.find')}
            </Button>
            <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={() => {
              props.fetch(getFilterModel(props.filter, true));
              apiRef.current.hideFilterPanel();
            }}>
              {t('common:button.clear')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </GridPanelContent>
  );
}
