import { RouteRestriction } from '../../../../service/routeRestriction/route.restriction';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthService } from '../../../../auth/auth.service';

interface IProps
{
  all?: RouteRestriction[];
  any?: RouteRestriction[];
}

export function RestrictedRoute({ all = [], any = [] }: IProps) {
  const user = AuthService.getUser();
  const allowed = (!all?.length || all.every((r) => r(user))) && (!any?.length || any.some((r) => r(user)));
  return allowed
    ? <Outlet/>
    : <Navigate to="/access-denied" replace />;
}
