import React, { useCallback } from 'react';
import { LeftMenuItem } from '../../../../model';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export function useMenuItemClick(cookieName: string, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) {

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([cookieName]);
  return useCallback((e: React.MouseEvent, item: LeftMenuItem, query: Record<string, any> = {}) => {
    if (e) {
      e.preventDefault();
    }

    if (!item.children?.length) {
      if (e.ctrlKey || e.metaKey || e.button === 1) {
        const newTab = window.open(item.path, '_blank');
        if (newTab)
          newTab.history.pushState({ query }, 'query')
      } else {
        navigate(item.path);
      }
    } else {
      setCookie(cookieName, cookies[cookieName] !== 'true');
      setIsOpen((prev) => !prev);
    }
  }, [])
}