import { PropsWithChildren } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

interface IProps {
  text: string;
}

export function Title({ text, children }: PropsWithChildren<IProps>) {
  return (
    <Grid item xs={12}>
      <Paper elevation={3} sx={{ px: 1, py: 2 }}>
        <Typography variant="h4" component="h1" fontSize="1.5rem">
          {text}
          <br />
          {children}
        </Typography>
      </Paper>
    </Grid>
  );
}
