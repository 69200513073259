import { CrudService } from '../crud.service';
import { VERIFICATION_ROUTES } from '../../page/route';
import { VerificationRequestStatusEnum } from '../../model';
import { API } from '../index';

class CVerificationService extends CrudService {
  constructor() {
    super('v1/verification-request', VERIFICATION_ROUTES);
  }

  async changeStatus(uuid: string | undefined, status: VerificationRequestStatusEnum, rejectCause: string | undefined) {
    await API.patch(`${this.baseUrl}/status/${uuid}`, {
      status,
      rejectCause
    });
  }
}

export const VerificationService = new CVerificationService();
