import ModelValidator from '../model.validator';
import { EmployeeEditDto } from '../../../model/dto/employee';

class Validator extends ModelValidator<EmployeeEditDto> {
  validate(model: EmployeeEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.user, errors);
    this.checkRequired(model.client, errors);
    this.checkLength(model.post, errors, 255);
    console.log(model)
    return errors;
  }
}

export const EditEmployeeValidator = new Validator();
