import { VerifyingDataEnum } from '../../enum';

export class VerifyingDataDto {
  guid: string;
  code: VerifyingDataEnum;
  view: string;
  value: string;
  complexValueUuid: string | null;

  constructor(guid: string, value: string, view: string, code: VerifyingDataEnum, complexValueUuid: string | null) {
    this.guid = guid;
    this.value = value;
    this.view = view;
    this.code = code;
    this.complexValueUuid = complexValueUuid;
  }
}
