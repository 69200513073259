import createQueryClient from './query/query.client';
import { QueryClientProvider } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Page, CustomAlert } from './components/common';
import { createTheme, ThemeProvider } from '@mui/material';
import { ruRU } from '@mui/material/locale';
import { ruRU as dpRu } from '@mui/x-date-pickers';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { useErrorHandler } from './hooks/UseErrorHandler';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfirmProvider } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';

export function App() {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { t } = useTranslation('common');

  const queryClient = createQueryClient((error: any) => {
    const path = handleError('query-error', error);
    if (path && typeof path === 'string') {
      navigate(path, { replace: true });
    }
  });

  const theme = createTheme(
    {
      typography: {
        allVariants: {
          color: '#4f4f4f',
        },
      },
      components: {
        MuiListItemIcon: {
          defaultProps: {
            sx: {
              // minWidth: '45px',
            },
          },
        },
      },
    },
    ruRU,
    dpRu,
  );

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            Components={{
              error: CustomAlert,
            }}
          >
            <ConfirmProvider defaultOptions={{
              title: t('confirm.common.title'),
              confirmationText: t('button.ok'),
              cancellationText: t('button.cancel'),
              dialogProps: { maxWidth: 'sm' }
            }}>
              <Page />
            </ConfirmProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
