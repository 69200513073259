/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/common';
import { Content } from '../../../components/common';
import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { CryptoProWrapper } from '../../../utils/cryptopro/cryptopro.wrapper';
import { CertificateListItem } from '../../../utils/cryptopro/interfaces/certificate.list.item';

declare global {
  interface Window { cadesplugin: any; }
}

export function TestPage() {
  const { t } = useTranslation('admin', { keyPrefix: 'testPage' });

  const [cert, setCert] = useState('');
  const [data, setData] = useState('');
  const [sign, setSign] = useState<any>('');
  const [info, setInfo] = useState<any>(null);
  const [tsa, setTsa] = useState('http://www.cryptopro.ru/tsp/tsp.srf');
  const tsps = [
    'http://www.cryptopro.ru/tsp/tsp.srf',
    'https://www.cryptopro.ru/tsp/tsp.srf',
    'http://qs.cryptopro.ru/tsp/tsp.srf',
    'http://testca2012.cryptopro.ru/tsp/tsp.srf',
    'https://testca2012.cryptopro.ru/tsp/tsp.srf',
    'http://pki.sertum-pro.ru/tsp/tsp.srf',
    'http://pki.skbkontur.ru/tsp/tsp.srf',
    'http://tsp.ncarf.ru/tsp/tsp.srf',
    'http://tsp.taxcom.ru/tsp/tsp.srf',
    'http://tax4.tensor.ru/tsp-tensor_gost2012/tsp.srf',
    'http://service.itk23.ru/tsp/tsp.srf',
    'http://ocsp.ntssoft.ru/tsp/tsp.srf',
    'http://tsp.e-notary.ru/tsp/tsp.srf',
  ];

  const keys = ['name', 'serialNumber', 'thumbprint', 'validFromDate', 'validToDate'];

  const { data: list } = useQuery<CertificateListItem[]>('cert', () => CryptoProWrapper.getCertificatesList());

  const fileRef = useRef<HTMLInputElement | null>(null);

  const signData = async () => {
    if (! window.cadesplugin) return '';

    const cadesplugin = window.cadesplugin;
    if (!fileRef.current || !fileRef.current.files?.length) {
      alert('Select the file.');
      return '';
    }

    const oFile = fileRef.current.files[0];
    const oFReader = new FileReader();

    if (typeof oFReader.readAsDataURL != 'function') {
      alert('Method readAsDataURL() is not supported in FileReader.');
      return '';
    }

    oFReader.readAsDataURL(oFile);

    oFReader.onload = function (oFREvent) {
      if ('cadesplugin' in window)
      cadesplugin.async_spawn(function* () {
        const header = ';base64,';
        const sFileData = oFREvent.target!.result as string;
        const sBase64Data = sFileData.substring(sFileData.indexOf(header) + header.length);

        if (!cert) {
          alert('Сертификат не выбран');
          return '';
        }

        // @ts-ignore
        const oStore: any = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
        yield oStore.Open(
          cadesplugin.CAPICOM_CURRENT_USER_STORE,
          cadesplugin.CAPICOM_MY_STORE,
          cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        );

        // @ts-ignore
        const oStoreCerts = yield oStore.Certificates;
        // @ts-ignore
        const oCertificates = yield oStoreCerts.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, cert);
        // @ts-ignore
        const oCertificate = yield oCertificates.Item(1);

        // @ts-ignore
        const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
        yield oSigner.propset_Certificate(oCertificate);
        yield oSigner.propset_CheckCertificate(true);
        yield oSigner.propset_TSAAddress(tsa);

        // @ts-ignore
        const oSignedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
        yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
        yield oSignedData.propset_Content(sBase64Data);

        try {
          // @ts-ignore
          const sSignedMessage = yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_X_LONG_TYPE_1, true);
          setSign(sSignedMessage);
        } catch (err) {
          alert('Failed to create signature. Error: ' + cadesplugin.getLastError(err));
          return;
        }

        yield oStore.Close();
      });
    };
  };

  return (
    <>
      <Title text={t('pageTitle')} />
      <Content>
        <p>
          Тестовая страница для подписания УКЭПом. Подразумевается, что плагин установлен. Данные из полей "инфо" и
          "подпись" нужно скопировать в запрос постмана. Для тестирования не имеет значения, что подписывать. В
          дальнейшем мы также не сможем проверить, что подписали именно тот файл, который надо, так как у нас нет
          сертификата для такой проверки. Эндпойнт принимает сформированную подпись. Дальнейшие проверки валидности уже
          на совести клиента, мы можем только сделать страничку в приложении, которая будет это делать средствами
          плагина.
          <br />
          Плагин не работает с тестовым сертом. Можно закинуть в наше апи любую строку в формате base64
        </p>
        <p>
          файл для подписи
          <input type={'file'} ref={fileRef} />
        </p>
        <p>
          данные для подписи, можно ввести что угодно.
          <input value={data} onChange={(e) => setData(e.target.value)}/>
        </p>
        <p>
          сертификат
          <br />
          <select
            value={cert}
            onChange={(e) => {
              setCert(e.target.value);
              if (e.target.value) {
                CryptoProWrapper.getCertificateInfo(e.target.value).then((r) => {
                  console.log(r);
                  setInfo(r);
                });
              }
            }}
          >
            <option>null</option>
            {list?.map((c) => (
              <option value={c.thumbprint}>{c.name}</option>
            ))}
          </select>
        </p>
        <p>
          tsp cthdth
          <br />
          <select
            value={tsa}
            onChange={(e) => {
              setTsa(e.target.value);
            }}
          >
            {tsps?.map((s) => (
              <option value={s}>{s}</option>
            ))}
          </select>
        </p>
        <p>
          инфо
          <br />
          <textarea
            value={keys
              .map(
                (key) =>
                  `"${key}": "${
                    typeof info?.[key] === 'string' ? info?.[key] : new Date(info?.[key]).toJSON()
                  }"`,
              )
              .join(',\n')}
          ></textarea>
        </p>
        <p>
          подпись
          <br />
          <textarea value={sign}></textarea>
        </p>
        <button
          onClick={() => {
            CryptoProWrapper.signSimple(data, cert).then((r) => setSign(r));
          }}
        >
          simple
        </button>
        <button
          onClick={() => {
            CryptoProWrapper.signTSP(data, cert).then((r) => setSign(r));
          }}
        >
          tsp
        </button>
        <button
          onClick={() => {
            CryptoProWrapper.signOCSP(data, cert).then((r) => setSign(r));
          }}
        >
          ocsp
        </button>
      </Content>
    </>
  );
}
