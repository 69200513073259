import ValidationRule from "../validation.rule.enum";
import IValidator from "./validator";

export default class StrongPasswordValidator implements IValidator<string> {
   rule = ValidationRule.StrongPassword;
   validate(value: string) {
      /*
         ^                                            Start anchor
         (?=.*[A-Z])                                  Ensure string has uppercase letters.
         (?=.*[-#!$@£%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]) Ensure string has one special case letter.
         (?=.*[0-9])                                     Ensure string has two digits.
         (?=.*[a-z])                                  Ensure string has three lowercase letters.
         .{8}                                         Ensure string is of length 8.
         $                                            End anchor.
       */
      return !value ||  /^(?=.*[A-Z])(?=.*[-#!$@£%^&*()_+|~=`{}\[\]:";'<>?,.\/ ])(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(value)
        ? null : ['common:validation.strongPassword'] as [string, ...any[]]
   }
}