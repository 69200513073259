import { ITableColumn, TableColumn, TableGroupAction, TableRowAction, TableSettings } from './table.model';
import { useMemo } from 'react';
import { GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { LocalStorageService } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { FilterItem } from '../../../../model/filter';
import { FilterTypeEnum } from '../../../../model/filter/enum/filter.type.enum';
import { BaseEntity } from '../../../../model';
import { UserAction } from '../../../../model/actions/user.action';

export function saveSettings(key: string, settings: TableSettings) {
  LocalStorageService.setItem(`table-${key}-settings`, settings);
}

export function loadSettings(key: string, onPage?: number, sortBy?: string, sortOrder?: string) {
  return new TableSettings(LocalStorageService.getItem(`table-${key}-settings`), onPage, sortBy, sortOrder);
}

//-------------------------------- FILTER --------------------------------
export function getFilter(filter: FilterItem[] | undefined, state?: Record<string, any>) {
  if (!filter) return [];

  for (const item of filter) {
    if (state?.[item.field] !== undefined) {
      item.value = state[item.field];
      item.view = state[`${item.field}View`];
    }
  }

  return filter;
}

export function getFilterModel(filter: FilterItem[] | undefined, setToDefault = false) {
  return filter?.reduce((acc, item) => {
    if (item.type === FilterTypeEnum.HIDDEN) {
      acc[item.field] = item.value;
    } else {
      acc[item.field] = setToDefault ? item.defaultValue : item.value ?? item.defaultValue;
      acc[`${item.field}View`] = setToDefault ? item.defaultView : item.view ?? item.defaultValue;
    }
    return acc;
  }, {} as any);
}

export function filterHasValue(item: FilterItem) {
  if (item.type === FilterTypeEnum.HIDDEN) {
    return false;
  }
  if (item.type === FilterTypeEnum.DATETIME_RANGE || item.type === FilterTypeEnum.DATE_RANGE) {
    return !!item.value?.startDate || !!item.value?.endDate;
  }
  return item.value && item.value !== 'null';
}

//------------------------------------------------------------------------

//---------------------------- COMMON COLUMNS ----------------------------
export function useBoolTableColumn<T extends GridValidRowModel>(column: ITableColumn<T>) {
  const { t } = useTranslation();
  return new TableColumn<T>({
    ...column,
    valueFormatter: ({ value }) => t(`common:${value ? 'YES' : 'NO'}`),
    width: column.width ?? (column.sortable ? 130 : 100),
    align: 'center',
  });
}

//------------------------------------------------------------------------

//---------------------------- COMMON ACTIONS ----------------------------
interface RowActionsOptions<T> {
  edit?: Pick<TableRowAction<T>, 'hide' | 'confirm'> & { action: (row: T) => string };
  remove?: Pick<TableRowAction<T>, 'action' | 'hide' | 'confirm'>;
  toggleActive?: Pick<TableRowAction<T>, 'action' | 'hide' | 'confirm'> & Partial<Pick<TableRowAction<T>, 'text'>>;
  other?: TableRowAction<T>[];
}

export function useRowActions<T extends BaseEntity>(options: RowActionsOptions<T>, memoDeps?: any[]) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMemo(() => {
    let items: TableRowAction<T>[] = [];

    if (options.edit) {
      items.push({
        value: 'edit',
        text: t('common:button.edit') ?? 'no-locale',
        action: (row: T) => navigate(options.edit ? options.edit.action(row) : ''),
        keepDataAfterAction: true,
        hide: options.edit.hide,
      });
    }

    if (options.other) {
      items = items.concat(options.other);
    }

    if (options.toggleActive) {
      items.push({
        value: 'toggleActive',
        text: options.toggleActive?.text ?? ((row: T) => t(`common:button.toggleActive.${!(row as any).isActive}`)),
        action: (row: T) => (options.toggleActive ? options.toggleActive.action(row) : Promise.resolve()),
        hide: (row: T) =>
          !('isActive' in row) || (options.toggleActive?.hide !== undefined && options.toggleActive.hide(row)),
        confirm: options.toggleActive.confirm,
      });
    }

    if (options.remove) {
      items.push({
        value: 'delete',
        text: t('common:button.delete') ?? 'no-locale',
        action: (row: T) => (options.remove ? options.remove.action(row) : Promise.resolve()),
        hide: options.remove.hide,
        confirm: options.remove.confirm,
      });
    }

    return items;
  }, memoDeps ?? []);
}

export function convertToRowAction<T extends BaseEntity>(action: UserAction<T>): TableRowAction<T> {
  return {
    value: action.key,
    text: action.btnText,
    action: action.action,
    hide: (row) => !action.available(row),
    confirm: action.confirm,
    isNavigate: action.isNavigate,
  }
}

interface GroupActionsOptions {
  remove?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  setActive?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  removeActive?: ((selected: GridRowSelectionModel) => Promise<any>) | null;
  other?: TableGroupAction[];
}

export function useGroupActions(
  { remove, setActive, removeActive, other = [] }: GroupActionsOptions,
  ...memoDeps: any[]
) {
  const { t } = useTranslation();
  return useMemo(() => {
    const items = other;
    if (setActive) {
      items.push({
        value: 'setActive',
        text: t('common:button.toggleActive.true'),
        action: (selected) => setActive(selected),
      });
    }
    if (removeActive) {
      items.push({
        value: 'removeActive',
        text: t('common:button.toggleActive.false'),
        action: (selected) => removeActive(selected),
      });
    }
    if (remove) {
      items.push({
        value: 'delete',
        text: t('common:button.delete'),
        action: (selected) => remove(selected),
      });
    }
    return items;
  }, [...(memoDeps ?? [])]);
}

//------------------------------------------------------------------------
