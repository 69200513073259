interface IProps {
    small?: boolean
}

export function Loader({ small }: IProps) {
    const typeClass = `spinner-grow ${small ? 'spinner-grow-sm' : ''}`;
    return (
        <div className="text-center">
            <div className={`flex-center ${small ? '' : 'py-3'}`}>
                <div className={`${typeClass} text-primary me-1`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className={`${typeClass} text-secondary me-1`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className={`${typeClass} text-success me-1`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className={`${typeClass} text-danger me-1`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className={`${typeClass} text-warning me-1`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className={`${typeClass} text-info`} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}
