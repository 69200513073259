import {
  FilterAutocompleteItem,
  FilterDateRangeItem,
  FilterSelectItem,
  FilterTextItem,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EntityQueryKey } from '../../../query/query.keys';
import { useOptions } from '../../../hooks/UseOptions';
import { GenderEnum } from '../../../model';
import { FilterTypeEnum } from '../../../model/filter/enum/filter.type.enum';

export function useFilter() {
  const genderOptions = useOptions(GenderEnum, 'passport:gender.');
  return [
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common:field.updateDate',
    }),
    UUID_FILTER,
    new FilterAutocompleteItem({
      field: 'user',
      labelKey: 'passport:field.user',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'passport:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    new FilterTextItem({
      field: 'fullName',
      labelKey: 'passport:field.fullName',
    }),
    new FilterTextItem({
      field: 'search',
      labelKey: 'passport:field.numberView',
    }),
    new FilterDateRangeItem(
      {
        field: 'birthDate',
        labelKey: 'passport:field.birthDate',
      },
      FilterTypeEnum.DATE_RANGE,
    ),
    new FilterSelectItem({
      field: 'gender',
      labelKey: 'passport:field.gender',
      options: genderOptions,
    }),
    useBoolFilterItem({
      field: 'isActive',
      labelKey: 'common:field.isActiveM',
    }),
    useBoolFilterItem(
      {
        field: 'isDeleted',
        labelKey: 'common:field.deletedM',
        nullable: false,
      },
      'false',
    ),
  ];
}
