import { Route } from 'react-router-dom';
import { DOCUMENT_ROUTES } from '../../route';
import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import React from 'react';
import { DocumentList } from '../DocumentList';

export const DOCUMENT_ROUTE = (
  <Route path={DOCUMENT_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
    <Route path="" element={<DocumentList />} />
  </Route>
);