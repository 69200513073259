import { CrudService } from '../crud.service';
import { DOCUMENT_ROUTES } from '../../page/route';
import { API } from '../index';
import { DocumentFileType } from '../../model';
import { FileContent } from '../../model/interfaces/file';

class CDocumentService extends CrudService {
  constructor() {
    super('v1/document', DOCUMENT_ROUTES);
  }

  async downloadFile(type: DocumentFileType, documentGuid: string | undefined, employeeUuid?: string | undefined): Promise<FileContent | null> {
    if (!documentGuid) return null;
    if (type === DocumentFileType.SIGNATURE && !employeeUuid) return null;

    const files = (
      await API.post(`${this.baseUrl}/files/${documentGuid}`, {
        include: [type],
        signers: employeeUuid ? [employeeUuid] : undefined,
      })
    ).data;

    return (Array.isArray(files[type]) ? files[type]?.[0] : files[type]) ?? null;
  }
}

export const DocumentService = new CDocumentService();
