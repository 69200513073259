import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/common';
import { Content } from '../../../components/common';
import { Typography } from '@mui/material';

declare global {
  interface Window { cadesplugin: any; }
}

export function AdminHome() {
  const { t } = useTranslation('admin', { keyPrefix: 'home' });

  return (
    <>
      <Title text={t('pageTitle')} />
      <Content>
        <Typography variant="body1">{t('content')}</Typography>
      </Content>
    </>
  );
}
