import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useOptions } from '../../../hooks/UseOptions';
import { GenderEnum, OrganizationTypeEnum, SnilsBrief } from '../../../model';
import { EntityQueryKey } from '../../../query/query.keys';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { SnilsService } from '../../../api/snils/snils.service';
import { SnilsEditDto } from '../../../model/dto/snils';
import { ClientService } from '../../../api/client/client.service';
import { SNILS_ROUTES } from '../../route';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import { EditPage } from '../../../components/common/layout/EditPage';
import { FileControl } from '../../../components/common';
import { EditSnilsValidator } from '../../../service/validator/snils';
import InputControl from '../../../components/common/ui/InputControl';
import SelectControl from '../../../components/common/ui/SelectControl';
import DateControl from '../../../components/common/ui/DateControl';
import { MaskedInput } from '../../../components/common/ui/MaskedInput';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export function SnilsEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const { user } = useLocation().state ?? {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const genders = useOptions(GenderEnum, 'common:gender.');
  const [userError, setUserError] = useState<string>();
  const [disableActions, setDisableActions] = useState(false);
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});
  const { enqueueSnackbar } = useSnackbar();

  const { data: entity } = useQuery(
    [EntityQueryKey.Snils, uuid],
    () => SnilsService.get<SnilsBrief>(uuid ?? 'false'),
    {
      enabled: !!uuid,
      onSuccess: (data) => {
        if (data.isVerified) {
          enqueueSnackbar({ message: t(`verification:message.error.isVerified`), variant: 'error' });
          navigate(SNILS_ROUTES.details(uuid), { replace: true });
          return;
        }
        setDto(new SnilsEditDto(data));
      },
    },
  );
  useDocumentTitle({ params: [SnilsService.fullView(entity)] });
  const [dto, setDto] = useState(new SnilsEditDto(entity));
  const updateDto = (key: keyof SnilsEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));

  const { data: pClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.PHYSICAL, dto.userUuid], () =>
    dto.userUuid
      ? ClientService.list<any>({
          filter: { type: OrganizationTypeEnum.PHYSICAL, user: dto.userUuid },
        })
      : null,
  );

  useEffect(() => {
    setDisableActions(!pClient?.items?.length);
  }, [pClient?.items?.length]);

  useEffect(() => {
    if (user != undefined) {
      setDto((prev) => ({ ...prev, userUuid: user }));
    }
  }, [user]);

  return (
    <EditPage
      titleKey="snils:edit.pageTitle"
      titleParams={[entity?.number ?? '']}
      validator={EditSnilsValidator}
      dto={dto}
      queryKey={EntityQueryKey.Snils}
      routes={SNILS_ROUTES}
      service={SnilsService}
      disableActions={disableActions}
      apiErrors={setApiErrors}
    >
      <AutocompleteControl
        required
        value={dto.userUuid}
        entity={EntityQueryKey.User}
        labelKey="snils:field.user"
        onChange={(val, option) => {
          if (option?.attrs?.isDeleted === true) {
            setUserError(t('snils:error.userDeleted') ?? undefined);
            setDisableActions(true);
          }
          updateDto('userUuid', val)
        }}
        validators={[NotEmpty]}
        disabled={!!uuid}
        error={userError}
      />
      <InputControl labelKey="snils:field.client" value={pClient?.items[0]?.shortName ?? ''} disabled onChange={() => null}
                    error={dto.userUuid && !pClient?.items?.length ? t('snils:error.clientNotFound') : null}/>
      <InputControl required type="masked" labelKey="snils:field.number" value={dto.number ?? ''} onChange={(val) => updateDto('number', val)}
                    inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000-000-000 00', unmask: false }} disabled={entity?.isVerified}
                    validators={[NotEmpty]} error={apiErrors['SGNX:VLDN:SNLS:NUMBER_REQ_ISSNILS']}/>
      <InputControl labelKey="snils:field.lastName" value={dto.lastName} onChange={(val) => updateDto('lastName', val)}
                    validators={[Length(60)]}/>
      <InputControl labelKey="snils:field.firstName" value={dto.firstName} onChange={(val) => updateDto('firstName', val)}
                    validators={[Length(60)]}/>
      <InputControl labelKey="snils:field.secondName" value={dto.secondName} onChange={(val) => updateDto('secondName', val)}
                    validators={[Length(60)]}/>
      <DateControl labelKey="snils:field.birthDate" value={dto.birthDate} onChange={(val) => updateDto('birthDate', val)}/>
      <InputControl labelKey="snils:field.birthAddress" value={dto.birthAddress} onChange={(val) => updateDto('birthAddress', val)}
                    validators={[Length(150)]}/>
      <SelectControl options={genders} nullable labelKey="snils:field.gender" value={dto.gender} onChange={(val) => updateDto('gender', val)}/>
      <DateControl labelKey="snils:field.issueDate" value={dto.issueDate} onChange={(val) => updateDto('issueDate', val)}/>
      <FileControl accept="image/jpeg,image/png,application/pdf" limit={5} labelKey="snils:field.files" value={dto.files} onChange={(val) => updateDto('files', val)}/>
    </EditPage>
  );
}
