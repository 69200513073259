import {
  GridColumnVisibilityModel,
  GridFooterContainer,
  GridRowSelectionModel,
  FooterPropsOverrides,
  GridFooter,
  GridSelectedRowCount,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import { TableColumn, TableGroupAction } from './table.model';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from '../../../../hooks/UseErrorHandler';
import { TableColumnsButton } from './table.columns.button';
import SelectControl from '../../ui/SelectControl';
import { Button } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { QueryKey, useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../../utils';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    selectionModel: GridRowSelectionModel;
    setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
    groupActions: TableGroupAction[];
    columns: TableColumn<any>[];
    columnVisibilityModel: GridColumnVisibilityModel;
    columnVisibilityChange: (newVisibilityModel: GridColumnVisibilityModel) => void;
    setPanelAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setPanelPlacement: React.Dispatch<React.SetStateAction<string | undefined>>;
    refetch: () => Promise<any>;
    queryKey: QueryKey;
  }
}

export function TableFooter(props: FooterPropsOverrides) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const errorHandler = useErrorHandler();
  const [action, setAction] = useState<TableGroupAction | null>(props.groupActions?.[0] ?? null);
  const [columnsAnchor, setColumnsAnchor] = useState<HTMLElement | null>(null);

  const doGroupAction = async () => {
    if (!action) return;

    try {
      await action.action(props.selectionModel);
      enqueueSnackbar(
        t('common:confirm.success', [action.text]),
        { variant: 'success' },
      );

      if (!action.keepDataAfterAction) {
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, ...new Array<QueryKey>().concat(props.queryKey)) });
        await props.refetch();
      }
    } catch (e) {
      errorHandler(String(action.value), e);
    } finally {
      props.setSelectionModel([])
    }
  };

  const handleGroupAction = () => {
    if (!action) return;

    if (action.confirm !== undefined) {
      return confirm({
        title: action.confirm.title ?? action.text,
        description: action.confirm.description ? t(String(action.confirm.description)) : action.confirm.description,
      }).then(() => doGroupAction());
    } else {
      return doGroupAction();
    }
  };

  return (
    <>
      <GridFooterContainer
        ref={setColumnsAnchor}
        sx={{
          backgroundColor: 'white',
          margin: '0 -1px -1px -1px',
        }}
      >
        <GridFooter sx={{ width: '100%', '.MuiDataGrid-selectedRowCount': { display: 'none' } }} />
        <TableColumnsButton
          setPanelAnchor={props.setPanelAnchor}
          panelAnchor={columnsAnchor}
          setPanelPlacement={props.setPanelPlacement}
        />
      </GridFooterContainer>
      {(props.selectionModel?.length || false) && (
        <GridFooterContainer
          sx={{ display: 'flex', justifyContent: 'start', backgroundColor: 'white', margin: '0 -1px -1px -1px' }}
        >
          <GridSelectedRowCount selectedRowCount={props.selectionModel?.length ?? 0} />
          <div style={{ width: '200px', marginRight: '10px' }}>
            <SelectControl
              labelKey=""
              value={action?.value}
              onChange={(value, option) => setAction(option)}
              options={props.groupActions}
            />
          </div>
          {action != null && props.selectionModel.length > 0 && (
            <Button type="button" variant="outlined" color="success" onClick={handleGroupAction}>
              {t('common:button.apply')}
            </Button>
          )}
        </GridFooterContainer>
      )}
    </>
  );
}
