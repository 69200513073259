import './cadesplugin_api';
import {
  CAPICOM_PROPID_KEY_PROV_INFO,
  OIDS,
  CADESCOM_CADES_X_LONG_TYPE_1,
  CADESCOM_CADES_T, TSA, TSA_ERROR_CODE, CADESCOM_CADES_BES,
} from './constants';
import { CertificateInfo } from './interfaces/certificate.info';
import { CertificateListItem } from './interfaces/certificate.list.item';

const { cadesplugin } = window;

class Wrapper {
  private inited = false;
  private cachedCertificates: Record<string, CertificateInfo> = {};
  private cachedCadesCertificates: Record<string, any> = {};
  private cachedList?: CertificateListItem[];

  async init() {
    if (this.inited) return;

    if (!window.cadesplugin) {
      throw new Error('КриптоПро ЭЦП Browser plug-in не обнаружен');
    }

    try {
      await cadesplugin;
      this.inited = true;
    } catch (e) {
      throw new Error(cadesplugin.getLastError(e));
    }
  }

  async getCertificatesList() {
    if (this.cachedList) return this.cachedList;

    await this.init();
    try {
      const cadesStore = await this.openStore();

      let cadesCertificates = await cadesStore.Certificates;
      if (!cadesCertificates) {
        await cadesStore.Close();
        return [];
      }

      cadesCertificates = await cadesCertificates.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
      cadesCertificates = await cadesCertificates.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY,
        CAPICOM_PROPID_KEY_PROV_INFO,
      );
      const cadesCertificatesCount = await cadesCertificates.Count;

      let promises = [];
      for (let i = 1; i <= cadesCertificatesCount; i++) {
        promises.push(cadesCertificates.Item(i));
      }
      cadesCertificates = await Promise.all(promises);

      promises = [];
      for (const i in cadesCertificates) {
        promises.push(cadesCertificates[i].Thumbprint, cadesCertificates[i].SubjectName);
      }
      cadesCertificates = await Promise.all(promises);

      const certificates: CertificateListItem[] = [];
      for (let i = 0; i < cadesCertificates.length; i += 2) {
        const thumbprint = cadesCertificates[i];
        const oDN = this.parseDn(cadesCertificates[i + 1]);
        certificates.push({
          thumbprint,
          name: this.formatCertificateName(oDN),
        });
      }

      await cadesStore.Close();

      this.cachedList = certificates;
      return this.cachedList;
    } catch (e) {
      throw new Error(cadesplugin.getLastError(e));
    }
  }

  async getCertificateInfo(thumbprint: string): Promise<CertificateInfo> {
    if (thumbprint in this.cachedCertificates) {
      return this.cachedCertificates[thumbprint];
    }

    await this.init();

    const cadesCertificate = await this.extractCertificate(thumbprint);

    this.cachedCadesCertificates[thumbprint] = cadesCertificate;

    const cadesCertificateInfo = await Promise.all([
      cadesCertificate.HasPrivateKey(),
      cadesCertificate.IsValid().then((v: any) => v.Result),
      cadesCertificate.IssuerName,
      cadesCertificate.SerialNumber,
      cadesCertificate.SubjectName,
      cadesCertificate.Thumbprint,
      cadesCertificate.ValidFromDate,
      cadesCertificate.ValidToDate,
      cadesCertificate.Version,
      cadesCertificate.PublicKey().then((k: any) => k.Algorithm).then((a: any) => a.FriendlyName),
      cadesCertificate.HasPrivateKey().then((key: any) => !key && ['', undefined] || cadesCertificate.PrivateKey.then((k: any) => Promise.all([
        k.ProviderName, k.ProviderType
      ])))
    ]);

    const info = {
      hasPrivateKey: cadesCertificateInfo[0],
      isValid: cadesCertificateInfo[1],
      issuerName: cadesCertificateInfo[2],
      issuer: this.parseDn(cadesCertificateInfo[2]),
      serialNumber: cadesCertificateInfo[3],
      subjectName: cadesCertificateInfo[4],
      subject: this.parseDn(cadesCertificateInfo[4]),
      thumbprint: cadesCertificateInfo[5],
      validFromDate:cadesCertificateInfo[6],
      validToDate: cadesCertificateInfo[7],
      version: cadesCertificateInfo[8],
      algorithm: cadesCertificateInfo[9],
      providerName: cadesCertificateInfo[10][0],
      providerType: cadesCertificateInfo[10][1],
      name: '',
    };
    info.name = info.subject['CN'];

    this.cachedCertificates[thumbprint] = info as any;
    return info as any;
  }

  signSimple(data: string, thumbprint: string) {
    return this.sign(data, thumbprint, CADESCOM_CADES_BES);
  }

  signTSP(data: string, thumbprint: string) {
    return this.sign(data, thumbprint, CADESCOM_CADES_T);
  }

  signOCSP(data: string, thumbprint: string) {
    return this.sign(data, thumbprint, CADESCOM_CADES_X_LONG_TYPE_1);
  }

  private async sign(data: string, thumbprint: string, type: number) {
    await this.init();
    const cadesCertificate = this.cachedCadesCertificates[thumbprint] ?? await this.extractCertificate(thumbprint);

    const cadesSigner = await cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
    await cadesSigner.propset_Certificate(cadesCertificate);
    await cadesSigner.propset_CheckCertificate(true);

    const cadesSignedData = await cadesplugin.CreateObjectAsync('CAdESCOM.CadesSignedData');
    await cadesSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
    await cadesSignedData.propset_Content(data);

    for (const tsa of TSA) {
      if (type === CADESCOM_CADES_X_LONG_TYPE_1 || type === CADESCOM_CADES_T) {
        await cadesSigner.propset_TSAAddress(tsa);
      }
      try {
        return cadesSignedData.SignCades(cadesSigner, type, true);
      } catch (e) {
        const error = cadesplugin.getLastError(e);
        if (!error.includes(TSA_ERROR_CODE)) {
          throw new Error(error);
        }
      }
    }
  }

  private async extractCertificate(thumbprint: string) {
    const cadesStore = await this.openStore();
    const cadesCertificate = await cadesStore.Certificates
      .then((certificates: any) => certificates.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint))
      .then((certificates: any) => certificates.Count.then((count: number) => {
        if (count === 1) {
          return certificates.Item(1);
        }
        else {
          return null;
        }
      }));

    await cadesStore.Close();

    if(!cadesCertificate) {
      throw new Error("Не обнаружен сертификат c отпечатком " + thumbprint);
    }

    return cadesCertificate;
  }

  private async openStore() {
    const cadesStore = await cadesplugin.CreateObjectAsync('CAPICOM.Store');
    await cadesStore.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
    );
    return cadesStore;
  }

  private parseDn(src: string): Record<string, string> {
    const dn: Record<string, string> = {};
    const pairs =
      src.match(/([а-яёА-ЯЁa-zA-Z0-9\\.\s]+)=(?:("[^"]+?")|(.+?))(?:,|$)/g)?.map((el) => el.replace(/,$/, '')) ?? [];

    pairs.forEach((pair) => {
      const d = pair.match(/([^=]+)=(.*)/);
      if (d && d.length === 3) {
        const rdn = d[1].trim().replace(/^OID\./, '');
        dn[rdn] = d[2]
          .trim()
          .replace(/^"(.*)"$/, '$1')
          .replace(/""/g, '"');
      }
    });

    return this.convertDN(dn);
  }

  private convertDN(dn: Record<string, string>): Record<string, string> {
    const result: Record<string, string> = {};
    for (const field of Object.keys(dn)) {
      const oid = OIDS.find((item) => item.oid == field || item.full == field);
      if (oid) {
        result[oid.short] = dn[field];
      } else {
        result[field] = dn[field];
      }
    }
    return result;
  }

  private formatCertificateName(src: Record<string, string>) {
    return (
      '' +
      src['CN'] +
      (src['INNLE'] ? '; ИНН ЮЛ ' + src['INNLE'] : '') +
      (src['INN'] ? '; ИНН ' + src['INN'] : '') +
      (src['SNILS'] ? '; СНИЛС ' + src['SNILS'] : '')
    );
  }
}

export const CryptoProWrapper = new Wrapper();
