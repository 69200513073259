import { BaseEntity, ListRequestDto, ListResponseDto, UuidEntity } from '../model';
import { EntityRoutes } from '../model/route/entity.routes';
import { API } from './index';
import { EntityInfoType } from '../model/type';
import { EditableEntityDto } from '../model/dto/common';

export abstract class CrudService {
  protected readonly baseUrl: string;
  protected readonly routes: EntityRoutes;

  protected constructor(baseUrl: string, routes: EntityRoutes) {
    this.baseUrl = baseUrl;
    this.routes = routes;
  }

  detailsUrl(identifier: string) {
    return this.routes.details(identifier);
  }

  async get<T extends BaseEntity>(identifier: string, infoType: EntityInfoType = 'brief') {
    return (await API.get<T>(`${this.baseUrl}/${infoType}/${identifier}`)).data;
  }

  protected async create<TEntity extends BaseEntity>(dto: EditableEntityDto<TEntity>, urlModifier = '') {
    return (await API.post<UuidEntity>(`${this.baseUrl}/create${urlModifier}`, dto)).data;
  }

  protected async update<TEntity extends BaseEntity>(identifier: string, dto: EditableEntityDto<TEntity>, urlModifier = '') {
    return (await API.patch<UuidEntity>(`${this.baseUrl}/modify${urlModifier}/${identifier}`, dto)).data;
  }

  async save<TEntity extends BaseEntity>(dto: EditableEntityDto<TEntity>, urlModifier?: string) {
    try {
      return await (dto.uuid
        ? this.update(dto.uuid, dto, urlModifier)
        : this.create(dto, urlModifier));
    } catch (e: any) {
      throw e.response ?? e;
    }
  }

  remove(uuid: string | string[]) {
    return API.delete(`${this.baseUrl}`, { data: { uuid } });
  }

  async list<T extends BaseEntity>(dto: ListRequestDto<T>) {
    const cleanFilter = CrudService.cleanFilter(dto.filter);
    return (await API.post<ListResponseDto<T>>(`${this.baseUrl}/list`, { ...dto, filter: cleanFilter })).data;
  }

  listFn = <T extends BaseEntity>(dto: ListRequestDto<T>) => this.list(dto);

  toggle(toggleField: string, uuid: string | string[], newValue: boolean) {
    return API.patch(`${this.baseUrl}/toggle/${toggleField}`, { newValue, uuid });
  }

  private static cleanFilter<T extends Record<string, unknown>>(filter: T | undefined) {
    if (!filter) return undefined;

    const cleanFilter = {} as T;
    for (const key in filter as T) {
      if (!key.endsWith('View')) {
        cleanFilter[key] = CrudService.cleanFilterValue(filter[key]);
      }
    }

    return Object.values(cleanFilter).some((val) => val !== undefined) ? cleanFilter : undefined;
  }

  private static cleanFilterValue(value: any) {
    switch (typeof value) {
      case 'string':
        return value !== '' && value !== 'null' ? value : undefined;
      case 'object':
        return Array.isArray(value) ? value : CrudService.cleanFilter(value);
      default:
        return value;
    }
  }
}
