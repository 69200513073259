import { VerificationRequestBrief } from '../../../model/interfaces/verification';
import { UserAction } from '../../../model/actions/user.action';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import { useAddAction, useEditAction, useRemoveAction } from '../../../model/actions/common.actions';
import { VERIFICATION_ROUTES } from '../../route';
import { VerificationService } from '../../../api/verification/verification.service';
import { VerificationRequestStatusEnum } from '../../../model';
import { useTranslation } from 'react-i18next';

type AvailableActions = 'add' | 'edit' | 'remove' | 'send' | 'accept' | 'reject';

export function useActions(): Record<AvailableActions, UserAction<VerificationRequestBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: VerificationRequestBrief) => entity != undefined && user != null && (user.isAdmin || user.uuid === entity.user.uuid),
    [user],
  );

  const add = useAddAction<VerificationRequestBrief>(VERIFICATION_ROUTES);
  const edit = useEditAction<VerificationRequestBrief>(VERIFICATION_ROUTES, (entity) => canModify(entity) && (entity?.status === VerificationRequestStatusEnum.DRAFT || entity?.status === VerificationRequestStatusEnum.SENT && user?.isAdmin == true));
  const remove = useRemoveAction<VerificationRequestBrief>(VerificationService, (entity) => canModify(entity) && entity?.status === VerificationRequestStatusEnum.DRAFT);

  return {
    add,
    edit,
    remove,
    send: {
      key: 'send',
      btnText: () => t('verification:button.send'),
      btnColor: () => 'success',
      available: (entity) => canModify(entity) && entity?.status === VerificationRequestStatusEnum.DRAFT,
      action: (entity) => VerificationService.changeStatus(entity?.uuid, VerificationRequestStatusEnum.SENT, undefined),
      confirm: () => ({
        title: 'common:confirm.common.title',
        description: 'verification:confirm.send.description',
      }),
      successMessage: t('verification:message.success.status') as string,
    },
    accept: {
      key: 'accept',
      btnText: () => t('verification:button.accept'),
      btnColor: () => 'success',
      available: (entity) => user != null && user.isAdmin && entity?.status === VerificationRequestStatusEnum.SENT,
      action: (entity) => VerificationService.changeStatus(entity?.uuid, VerificationRequestStatusEnum.ACCEPTED, undefined),
      confirm: () => ({
        title: 'common:confirm.common.title',
        description: 'verification:confirm.accept.description',
      }),
      successMessage: t('verification:message.success.status') as string,
    },
    reject: {
      key: 'reject',
      btnText: () => t('verification:button.reject'),
      btnColor: () => 'warning',
      available: (entity) => user != null && user.isAdmin && entity?.status === VerificationRequestStatusEnum.SENT,
      action: () => false,
    },
  };
}