import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { DocumentBrief, DocumentFileType, DocumentStatusEnum } from '../../../model';
import { CLIENT_ROUTES, DOCUMENT_ROUTES, EMPLOYEE_ROUTES, SIGNER_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';
import { DownloadLink } from '../../../components/common';
import { DocumentService } from '../../../api/document/document.service';
import { FileMediaType } from '../../../model/interfaces/file';

function canPrint(mediaType: FileMediaType) {
  return mediaType === 'text/plain' || mediaType === 'application/pdf' || mediaType === 'image/jpeg' || mediaType === 'image/png';
}

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      new TableLinkColumn<DocumentBrief>({
        headerName: t('document:field.title'),
        field: 'title',
        linkProps: (row) => ({ to: DOCUMENT_ROUTES.details(row.guid) }),
      }),
      new TableLinkColumn<DocumentBrief>({
        headerName: t('document:field.signers'),
        field: 'signers',
        text: () => t('document:field.signers'),
        linkProps: (row) => ({
          to: SIGNER_ROUTES.list(),
          state: { document: row.guid },
        }),
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.status'),
        field: 'status',
        valueFormatter: ({ value }) => t(`document:status.${value}`),
        sortable: true,
      }),
      new TableLinkColumn<DocumentBrief>({
        headerName: t('document:field.client'),
        field: 'client',
        valueGetter: ({ row }) => row.owner.client.guid,
        text: (row) => row.owner.client.view,
        linkProps: (row) => ({
          to: CLIENT_ROUTES.details(row.owner.client.guid),
        }),
        sortable: true,
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.file'),
        field: 'file',
        renderCell: ({ row }) =>
          row.file?.uuid ? (
            <DownloadLink downloader={() => DocumentService.downloadFile(DocumentFileType.ORIGINAL, row.guid)}>
              {t('common:button.download')}
            </DownloadLink>
          ) : null,
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.signReport'),
        field: 'signReport',
        renderCell: ({ row }) =>
          row.status === DocumentStatusEnum.SIGNED ? (
            <DownloadLink downloader={() => DocumentService.downloadFile(DocumentFileType.REPORT, row.guid)}>
              {t('common:button.download')}
            </DownloadLink>
          ) : null,
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.archive'),
        field: 'archive',
        renderCell: ({ row }) =>
          row.status === DocumentStatusEnum.SIGNED ? (
            <DownloadLink downloader={() => DocumentService.downloadFile(DocumentFileType.ARCHIVE, row.guid)}>
              {t('common:button.download')}
            </DownloadLink>
          ) : null,
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.print'),
        field: 'print',
        renderCell: ({ row }) =>
          (row.status === DocumentStatusEnum.SIGNED && canPrint(row.file.mediaType)) ? (
            <DownloadLink downloader={() => DocumentService.downloadFile(DocumentFileType.PRINT, row.guid)}>
              {t('common:button.download')}
            </DownloadLink>
          ) : null,
      }),
      new TableLinkColumn<DocumentBrief>({
        headerName: t('document:field.owner'),
        field: 'owner',
        valueGetter: ({ row }) => row.ownerUuid,
        text: (row) => UserService.getFullName(row.owner.user),
        linkProps: (row) => ({
          to: EMPLOYEE_ROUTES.details(row.ownerUuid),
        }),
        sortable: true,
      }),
      new TableDateTimeColumn<DocumentBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<DocumentBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
      new TableColumn<DocumentBrief>({
        headerName: t('document:field.externalId'),
        field: 'externalId',
      }),
    ],
    [],
  );
}
