import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SnilsBrief } from '../../../model';
import { convertToRowAction, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<SnilsBrief>[], TableGroupAction[], UserAction<SnilsBrief>[]] {
  const actions = useUserActions();

  const rowActions = useRowActions<SnilsBrief>({
    remove: convertToRowAction(actions.remove),
    toggleActive: convertToRowAction(actions.toggleActive),
    other: [
      convertToRowAction(actions.toggleVerified),
      convertToRowAction(actions.edit),
    ],
  });

  const toolbarActions = [actions.add];

  return [rowActions, [], toolbarActions];
}
