import { useMemo } from 'react';
import { TableColumn, TableLinkColumn } from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { CLIENT_ROUTES, DOCUMENT_ROUTES, EMPLOYEE_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';
import { SignQueueListItem } from '../../../model/interfaces/signqueue';
import { DownloadLink } from '../../../components/common';
import { DocumentService } from '../../../api/document/document.service';
import { DocumentFileType, SignTypeEnum } from '../../../model';
import { Stack } from '@mui/material';
import { FileService } from '../../../api/file/file.service';

export function useColumns() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      new TableLinkColumn<SignQueueListItem>({
        headerName: t('signer:field.employee'),
        field: 'employee',
        text: (row) => UserService.getFullName(row.employee?.user),
        linkProps: (row) => ({ to: EMPLOYEE_ROUTES.details(row.employee?.uuid) }),
        sortable: true,
      }),
      new TableColumn<SignQueueListItem>({
        headerName: t('signer:field.status'),
        field: 'status',
        valueFormatter: ({ value }) => t(`signer:status.${value}`),
        sortable: true,
      }),
      new TableLinkColumn<SignQueueListItem>({
        headerName: t('signer:field.document'),
        field: 'document',
        text: (row) => row.document?.view,
        linkProps: (row) => ({ to: DOCUMENT_ROUTES.details(row.document.guid) }),
      }),
      new TableLinkColumn<SignQueueListItem>({
        headerName: t('signer:field.client'),
        field: 'client',
        text: (row) => row.employee?.client?.view,
        linkProps: (row) => ({ to: CLIENT_ROUTES.details(row.employee?.client?.guid) }),
        sortable: true,
      }),
      new TableColumn<SignQueueListItem>({
        headerName: t('signer:field.signType'),
        field: 'signType',
        valueFormatter: ({ value }) => t(`signer:signType.${value}`),
        sortable: true,
      }),
      new TableColumn<SignQueueListItem>({
        headerName: t('signer:field.certificate'),
        field: 'certificateUuid',
        renderCell: ({ row }) =>
          row.certificateUuid ? (<Stack direction="column">
            <span>{row.certificateUuid}</span>
            {row.signType === SignTypeEnum.SIGNX && <DownloadLink downloader={() => FileService.download(row.certificateUuid)}>
              {t('common:button.download')}
            </DownloadLink>}
          </Stack>) : null,
      }),
      new TableColumn<SignQueueListItem>({
        headerName: t('signer:field.signature'),
        field: 'signature',
        renderCell: ({ row }) =>
          row.signatureUuid ? (
            <DownloadLink downloader={() => DocumentService.downloadFile(DocumentFileType.SIGNATURE, row.document?.guid, row.employee?.uuid)}>
              {t('common:button.download')}
            </DownloadLink>
          ) : null,
      }),
    ],
    [],
  );
}
