import { EntityRoutes } from '../route/entity.routes';
import { UserAction } from './user.action';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BaseEntity } from '../interfaces';
import { CrudService } from '../../api/crud.service';

export function useAddAction<T extends BaseEntity>(
  routes: EntityRoutes,
  available: (entity?: T) => boolean = () => true,
): UserAction<T> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    key: 'add',
    btnText: () => t('common:button.add'),
    available,
    action: () => navigate(routes.create()),
    isNavigate: true,
  };
}

export function useEditAction<T extends BaseEntity>(
  routes: EntityRoutes,
  available: (entity?: T) => boolean,
): UserAction<T> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return {
    key: 'edit',
    btnText: () => t('common:button.edit'),
    available,
    action: (entity) => navigate(routes.edit(entity?.uuid || '')),
    isNavigate: true,
  };
}

export function useRemoveAction<T extends BaseEntity>(
  service: CrudService,
  available: (entity?: T) => boolean,
  localeNS = 'common',
): UserAction<T> {
  const { t } = useTranslation();

  return {
    key: 'remove',
    btnText: () => t('common:button.delete'),
    btnColor: () => 'error',
    available,
    action: (entity) => service.remove(entity?.uuid ?? ''),
    confirm: () => ({
      description: `${localeNS}:confirm.delete.description`,
    }),
    isNavigate: false,
  };
}

interface UseToggleActiveActionProps<T> {
  service: CrudService;
  available: (entity?: T) => boolean;
  localeBtnNS?: string;
  localeConfirmNS?: string;
}

export function useToggleActiveAction<T extends BaseEntity & { isActive: boolean }>({
  service,
  available,
  localeBtnNS = 'common',
  localeConfirmNS = 'common',
}: UseToggleActiveActionProps<T>): UserAction<T> {
  const { t } = useTranslation();

  return {
    key: 'toggleActive',
    btnText: (entity) => t(`${localeBtnNS}:button.toggleActive.${!entity?.isActive}`),
    btnColor: (entity) => entity?.isActive ? 'warning' : 'success',
    available,
    action: (entity) => service.toggle('isActive', entity?.uuid ?? '', !entity?.isActive),
    confirm: (entity) => ({
      description: `${localeConfirmNS}:confirm.toggleActive.${!entity?.isActive}`,
    }),
    isNavigate: false,
  };
}
