import { Route } from 'react-router-dom';
import { SIGNER_ROUTES } from '../../route';
import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import React from 'react';
import { SignerList } from '../SignerList';

export const SIGNER_ROUTE = (
  <Route path={SIGNER_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
    <Route path="" element={<SignerList />} />
  </Route>
);