import ModelValidator from '../model.validator';
import validator from 'validator';
import { PassportEditDto } from '../../../model/dto/passport/passport.edit.dto';
import { PassportTypeEnum } from '../../../model';
import isNumeric = validator.isNumeric;

class Validator extends ModelValidator<PassportEditDto> {
  validate(model: PassportEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.userUuid, errors);
    this.checkRequired(model.lastName, errors);
    this.checkLength(model.lastName, errors, 60);
    this.checkRequired(model.firstName, errors);
    this.checkLength(model.firstName, errors, 60);
    this.checkLength(model.secondName, errors, 60);
    this.checkRequired(model.number, errors);
    this.checkRequired(model.birthDate, errors);
    this.checkRequired(model.issuedBy, errors);
    this.checkLength(model.issuedBy, errors, 200);
    this.checkRequired(model.issueDate, errors);
    switch (model.type) {
      case PassportTypeEnum.PASSPORT:
        this.checkRequired(model.series, errors);
        this.checkLength(model.series, errors, 4, undefined, 4);
        this.check(model.series, errors, isNumeric);
        this.checkLength(model.number, errors, 6, undefined, 6);
        this.check(model.number, errors, isNumeric);
        this.checkRequired(model.birthAddress, errors);
        this.checkLength(model.birthAddress, errors, 150);
        this.checkRequired(model.departmentCode, errors);
        this.checkLength(model.departmentCode, errors, 7, undefined, 7);
        this.check(model.departmentCode, errors, (value) => /^\d{3}-\d{3}$/.test(value), 'departmentCode');
    }
    return errors;
  }
}

export const EditPassportValidator = new Validator();
