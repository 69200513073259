import { Divider, IconButton, List, Toolbar } from '@mui/material';
import { Drawer } from './drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ADMIN_MENU_ITEMS, LEFT_MENU_ITEMS, SECONDARY_MENU_ITEMS } from './items';
import { MenuItem } from './menu.item';
import { AuthService } from '../../../../auth/auth.service';
import { useContext } from 'react';
import { PageContext } from '../Page/page.context';

interface IProps {
  menuOpen: boolean;
  toggleDrawer: () => void;
}

export function LeftMenu({ menuOpen, toggleDrawer }: IProps) {
  const user = AuthService.getUser();
  const { adminMenu } = useContext(PageContext);
  const items = adminMenu && user?.isAdmin ? ADMIN_MENU_ITEMS : LEFT_MENU_ITEMS;
  return (
    <Drawer variant="permanent" open={menuOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {items.map((item, idx) => (
          <MenuItem key={idx} item={item}/>
        ))}
      </List>
      <List component="nav" sx={{ marginTop: 'auto' }} dense>
        {(SECONDARY_MENU_ITEMS).map((item, idx) => (
          <MenuItem key={idx} item={item}/>
        ))}
      </List>
    </Drawer>
  );
}
