import { ReactNode, JSXElementConstructor, Children, ReactElement, isValidElement } from 'react';

export function useSlots(children: ReactNode, slots: Record<string | 'main', JSXElementConstructor<any>>) {
  const result: Record<keyof typeof slots, ReactElement[]> = { main: [] };
  Children.forEach(children, (child) => {
    if (!isValidElement(child)) return;

    let fromSlot = false;
    for (const slot of Object.keys(slots)) {
      if (!result[slot]) {
        result[slot] = [];
      }
      if (child.type === slots[slot]) {
        result[slot].push(child);
        fromSlot = true;
      }
    }
    if (!fromSlot) {
      result.main.push(child);
    }
  });
  return result;
}