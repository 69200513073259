import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputControl from '../../../components/common/ui/InputControl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EntityQueryKey } from '../../../query/query.keys';
import { UserService } from '../../../api/user/user.service';
import { UserEditDto, UserBrief } from '../../../model';
import { EditPage } from '../../../components/common/layout/EditPage';
import CheckboxControl from '../../../components/common/ui/CheckboxControl';
import { USER_ROUTES } from '../../route';
import { EditUserValidator } from '../../../service/validator/user';
import { Length, NotEmpty, StrongPassword } from '../../../hooks/UseValidation/validators';
import { CheckCircleOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { ErrorRoute } from '../../error/routes';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { resetQueryFn } from '../../../utils';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { PhoneControl } from '../../../components/common/ui/PhoneControl';
import { AuthService } from '../../../auth/auth.service';
import { PasswordControl } from '../../../components/common';
import { useTranslation } from 'react-i18next';

export function UserEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [loading, setLoading] = useState({ emailVerified: false, phoneVerified: false });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const errorHandler = useErrorHandler();
  const user = AuthService.getUser();

  const { data: entity } = useQuery([EntityQueryKey.User, uuid], () => UserService.get<UserBrief>(uuid ?? 'false'), {
    enabled: !!uuid,
    onSuccess: (data) => {
      if (data.isAdmin && data.uuid !== user?.uuid) {
        navigate(ErrorRoute.ACCESS_DENIED, { replace: true });
      }
      setDto(new UserEditDto(data));
    },
  });
  useDocumentTitle({ params: [UserService.getFullName(entity)] });
  const [dto, setDto] = useState(new UserEditDto(entity));
  const updateDto = (key: keyof UserEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));

  const mutation = useMutation((field: 'emailVerified' | 'phoneVerified') => {
    if (!uuid || !entity) return Promise.resolve(null);

    setLoading((prev) => ({ ...prev, [field]: true }));
    return UserService.toggle(field, uuid, !entity[field]);
  }, {
    onSuccess: async (data, field) => {
      if (entity && data != null) {
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.User) });
        queryClient.setQueryData([EntityQueryKey.User, uuid], { ...entity, [field]: !entity[field] });
      }
    },
    onError: (error) => {
      errorHandler('user toggle', error);
    },
    onSettled: (data, error, field)  => {
      setLoading((prev) => ({ ...prev, [field]: false }));
    }
  })

  return (
    <EditPage titleKey="user:edit.pageTitle" titleParams={[UserService.getFullName(entity)]} dto={dto} queryKey={EntityQueryKey.User} routes={USER_ROUTES} validator={EditUserValidator} service={UserService} validationErrors={setValidationErrors}>
      <CheckboxControl disabled={entity?.isAdmin || dto.uuid === user?.uuid} labelKey="common:field.isActiveM" value={dto.isActive} onChange={(val) => updateDto('isActive', val)}/>
      <InputControl labelKey="user:field.lastName" value={dto.lastName} required onChange={(val) => updateDto('lastName', val)} validators={[NotEmpty, Length(255)]}/>
      <InputControl labelKey="user:field.firstName" value={dto.firstName} required onChange={(val) => updateDto('firstName', val)} validators={[NotEmpty, Length(255)]}/>
      <InputControl labelKey="user:field.secondName" value={dto.secondName} onChange={(val) => updateDto('secondName', val)} validators={[Length(255)]}/>
      <InputControl labelKey="common:field.email" disabled={!!uuid} value={dto.email} autoComplete="email username" required onChange={(val) => updateDto('email', val)} validators={[NotEmpty, Length(255)]} inputProps={{
        endAdornment:
          (uuid && !!entity) ? <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => mutation.mutate('emailVerified')}
              edge="end"
              disabled={entity.isAdmin || entity.uuid === user?.uuid || loading.emailVerified}
            >
              {loading.emailVerified
                ? <CircularProgress size={20} sx={{ mr: '2px' }} />
                : <CheckCircleOutlined color={entity.emailVerified ? 'success' : 'disabled'} />
              }
            </IconButton>
          </InputAdornment>
            : null
      }}/>
      <PhoneControl value={dto.phone} onChange={(val) => updateDto('phone', val)} validators={[Length(255)]} inputProps={{
        endAdornment:
          (uuid && !!entity?.phone) ? <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => mutation.mutate('phoneVerified')}
              edge="end"
              disabled={entity.isAdmin || entity.uuid === user?.uuid || loading.phoneVerified}
            >
              {loading.phoneVerified
                ? <CircularProgress size={20} sx={{ mr: 1 }} />
                : <CheckCircleOutlined color={entity.phoneVerified ? 'success' : 'disabled'} />
              }
            </IconButton>
          </InputAdornment>
        : null,
      }}/>
      <PasswordControl labelKey="user:field.password" value={dto.password} onChange={(val) => updateDto('password', val === '' ? undefined : val)} validators={[StrongPassword, Length(255)]}/>
      <PasswordControl labelKey="user:field.passwordConfirmation" value={dto.passwordConfirmation} onChange={(val) => updateDto('passwordConfirmation', val === '' ? undefined : val)} validators={[Length(255)]} error={validationErrors.includes('mismatch') ? t('user:error.passwordConfirmationMismatch') : null}/>
    </EditPage>
  )
}