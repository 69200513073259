import axios from 'axios';
import { API_URL } from '../model/constants';
import { LocalStorageService } from '../utils';
import { LoggedInUser, SignedIn } from '../model';
import { IssuerToken } from '../model/interfaces/account';

class CAuthService {
  private static readonly SIGNED_IN_DATA = 'signed-in-data';

  async signIn(email: string, password: string, admin = false) {
    const response = await axios.post<SignedIn>(`${API_URL}v1/auth/${admin ? 'admin/' : ''}signIn`, {
      email,
      password,
    });
    if (response.data.auth.authToken) {
      LocalStorageService.setItem(CAuthService.SIGNED_IN_DATA, response.data);
      return response.data.user.uuid;
    }

    return null;
  }

  signOut() {
    localStorage.removeItem(CAuthService.SIGNED_IN_DATA);
  }

  async refresh() {
    const data = this.getData();
    if (!data?.auth?.refreshToken) {
      return null;
    }

    const response = await axios.get<IssuerToken>(
      `${API_URL}v1/auth/refresh?refresh_token=${data.auth.refreshToken}`,
    );
    if (response.data.authToken) {
      data.auth = response.data;
      LocalStorageService.setItem(CAuthService.SIGNED_IN_DATA, data);
    }

    return response.data.authToken;
  }

  getData(): SignedIn | null {
    return LocalStorageService.getItem<SignedIn>(CAuthService.SIGNED_IN_DATA);
  }

  getUser(): LoggedInUser | null {
    return this.getData()?.user ?? null;
  }

  getAuth(): IssuerToken | null {
    return this.getData()?.auth ?? null;
  }
}

export const AuthService = new CAuthService();
