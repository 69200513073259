import {
  FilterAutocompleteItem,
  FilterDateRangeItem, FilterSelectItem,
  FilterTextItem, GUID_FILTER,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { useOptions } from '../../../hooks/UseOptions';
import { OrganizationTypeEnum } from '../../../model';
import { EntityQueryKey } from '../../../query/query.keys';

export function useFilter() {
  const typeOptions = useOptions(OrganizationTypeEnum, 'client:type.short.')
  return [
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common:field.updateDate',
    }),
    UUID_FILTER,
    GUID_FILTER,
    new FilterTextItem({
      field: 'search',
      labelKey: 'client:field.shortName',
    }),
    new FilterSelectItem({
      field: 'type',
      labelKey: 'client:field.type',
      options: typeOptions,
    }),
    new FilterTextItem({
      field: 'inn',
      labelKey: 'client:field.inn',
    }),
    new FilterTextItem({
      field: 'ogrn',
      labelKey: 'client:field.ogrn',
    }),
    new FilterTextItem({
      field: 'snils',
      labelKey: 'client:field.snils',
    }),
    new FilterAutocompleteItem({
      field: 'guidRegion',
      labelKey: 'client:field.region',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Region,
    }),
    useBoolFilterItem({
      field: 'isVerified',
      labelKey: 'client:field.isVerified',
    }),
    useBoolFilterItem({
      field: 'isActive',
      labelKey: 'common:field.isActiveM',
    }),
    useBoolFilterItem(
      {
        field: 'deleted',
        labelKey: 'common:field.deletedM',
        nullable: false,
      },
      'false',
    ),
    useBoolFilterItem(
      {
        field: 'isLiquidated',
        labelKey: 'client:field.isLiquidated',
      }
    ),
    new FilterAutocompleteItem({
      field: 'owner',
      labelKey: 'client:field.owner',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    new FilterAutocompleteItem({
      field: 'user',
      labelKey: 'client:field.user',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
  ];
}
