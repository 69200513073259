import { EntityRoutes } from './entity.routes';

export class UserRoutes extends EntityRoutes {

  constructor(base: string, identifier: string) {
    super(base, identifier);
  }

  get changePassword() {
    return `${this.base}/change-password`;
  }
}