import {
  FilterAutocompleteItem,
  FilterDateRangeItem,
  FilterTextItem,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EntityQueryKey } from '../../../query/query.keys';

export function useFilter() {
  return [
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common:field.updateDate',
    }),
    UUID_FILTER,
    new FilterTextItem({
      field: 'search',
      labelKey: 'user:field.fullName',
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'employee:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    new FilterAutocompleteItem({
      field: 'user',
      labelKey: 'employee:field.user',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.User,
    }),
    useBoolFilterItem({
      field: 'isConfirmed',
      labelKey: 'employee:field.isConfirmed',
    }),
    useBoolFilterItem({
      field: 'isActive',
      labelKey: 'common:field.isActiveM',
    }),
    useBoolFilterItem(
      {
        field: 'deleted',
        labelKey: 'common:field.deletedM',
        nullable: false,
      },
      'false',
    ),
    useBoolFilterItem({
      field: 'isDefault',
      labelKey: 'employee:field.isDefault',
    }),
  ]
}