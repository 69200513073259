import ModelValidator from '../model.validator';
import validator from 'validator';
import isEmail = validator.isEmail;

class Validator extends ModelValidator<string> {
  validate(model: string): string[] {
    const erroredTabs: string[] = [];
    this.checkRequired(model, erroredTabs);
    this.check(model, erroredTabs, isEmail);
    return erroredTabs;
  }
}

export const ForgotPasswordValidator = new Validator();
