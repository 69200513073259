import { File } from '../model/interfaces/file';

export function concat(delimiter: string, ...str: any[]) {
  return str?.filter((s) => !!s?.toString()?.length).join(delimiter) ?? '';
}

function fileSizeUnit(iter: number) {
  switch (iter) {
    case 0:
      return 'byte';
    case 1:
      return 'kilobyte';
    default:
      return 'megabyte';
  }
}
export function formatFileSize(contentLength: number | undefined): [unit: string, params: any] {
  let size = contentLength ?? 0;
  let iter = 0;
  while (size > 1024 && iter < 2) {
    iter++;
    size /= 1024;
  }
  return ['common:file.size', { size: size, unit: fileSizeUnit(iter) }];
}

export function getFileView(file: File): [key: string, params: any] {
  return ['common:file.view', { name: file.name, ...formatFileSize(file.size)[1] }]
}

export function clearByCondition<T>(data: T, key: keyof T, condition: boolean) {
  if (typeof data[key] === 'string' && condition) {
    data[key] = '' as any;
  }
}
