import { Box, Button, Chip, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { Search, Clear } from '@mui/icons-material';
import { GridPreferencePanelsValue } from '@mui/x-data-grid/hooks/features/preferencesPanel/gridPreferencePanelsValue';
import { useGridApiContext } from '@mui/x-data-grid/hooks/utils/useGridApiContext';
import { useGridSelector } from '@mui/x-data-grid/hooks/utils/useGridSelector';
import { gridPreferencePanelStateSelector } from '@mui/x-data-grid/hooks/features/preferencesPanel/gridPreferencePanelSelector';
import React, { useCallback, useState } from 'react';
import { FilterItem } from '../../../../model/filter';
import { filterHasValue, getFilterModel } from './table.utils';
import { UserAction } from '../../../../model/actions/user.action';

interface IProps {
  filter: FilterItem[];
  filterModel: Record<string, any>;
  setFilterModel: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  setPanelAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  setPanelPlacement: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetch: (auxModel?: Record<string, any>) => void;
  toolbarActions?: UserAction<any>[];
}

export function TableToolbar(props: IProps) {
  const { t } = useTranslation();
  const [filterPanelAnchor, setFilterPanelAnchor] = useState<HTMLElement | null>(null);
  const apiRef = useGridApiContext();
  const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);
  const toggleFilter = () => {
    if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
      apiRef.current.hideFilterPanel();
    } else {
      props.setPanelAnchor(filterPanelAnchor);
      props.setPanelPlacement('bottom-start');
      apiRef.current.showFilterPanel();
    }
  };

  const getFilterView = useCallback((filter: FilterItem[], filterModel: Record<string, any>) => {
    const views = filter.filter((item) => item.field !== 'search' && filterHasValue(item));
    return (
      <Box display="flex" alignItems="center">
        {views.slice(0, 3).map((item) => (
          <Chip
            key={item.field}
            label={`${t(item.labelKey)}: ${item.view ?? item.value}`}
            onDelete={() => props.fetch({ ...filterModel, [item.field]: item.defaultValue, [`${item.field}View`]: item.defaultView })}
            style={{ maxWidth: '180px' }}
          />
        ))}
        {views.length > 3 && <Chip key="more" label={t('common:filter.more', [views.length - 3])} />}
      </Box>
    );
  }, []);

  return (
    <Box sx={{ backgroundColor: grey[100], margin: '-1px -1px 0 -1px', paddingBottom: '24px' }}>
      <Stack direction="row" spacing={1}>
        {props.toolbarActions?.map((item, idx) => (
          <Button key={idx} onClick={item.action} variant="outlined" sx={{ flexShrink: 0, height: '42px' }} color={item.btnColor?.()}>
            {t(item.btnText())}
          </Button>
        ))}
        <Box
          ref={setFilterPanelAnchor}
          sx={{
            width: '100%',
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)!important',
            borderRadius: '4px',
          }}
        >
          <Stack direction="row">
            {getFilterView(props.filter, props.filterModel)}
            <TextField
              size="small"
              fullWidth
              placeholder={
                t(props.filter.find((item) => item.field === 'search')?.labelKey ?? 'common:filter.title') ?? ''
              }
              focused={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        return props.fetch();
                      }}
                    >
                      <Search />
                    </IconButton>
                    <IconButton edge="end" onClick={() => props.fetch(getFilterModel(props.filter, true))}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: props.filterModel.search === undefined,
                sx: {
                  '& fieldset': { border: 'none' },
                  '& input': { cursor: 'pointer' },
                },
              }}
              onChange={(event) => props.setFilterModel((prev) => ({ ...prev, search: event.target.value }))}
              onClick={toggleFilter}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  return props.fetch();
                }
              }}
              autoComplete="off"
              value={props.filterModel.search}
            />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
