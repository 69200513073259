import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectItem } from '../../model/interfaces/common/select.item';

export const useOptions = <T>(src: Record<string, T>, prefix: string): SelectItem[] => {
  const { t } = useTranslation();
  return useMemo(
    () => Object.entries(src).map(([key, value]) => ({ value: String(value), text: t(`${prefix}${key}`) })),
    [],
  );
};
