import { useTranslation } from 'react-i18next';
import { useColumns } from './table.definition';
import { useFilter } from './filter.definition';
import { useActions } from './action.definition';
import { DataTable, Title } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { VerificationService } from '../../../api/verification/verification.service';

export function VerificationRequestList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('verification:list.pageTitle')} />
      <DataTable
        id="verification-request-list"
        queryKey={EntityQueryKey.Verification}
        columns={columns}
        fetchData={VerificationService.listFn}
        filter={filter}
        sortBy="createDate"
        sortOrder="desc"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}