import { CrudService } from '../crud.service';
import { ChangePasswordDto, UserBrief, UserEditDto } from '../../model';
import { USER_ROUTES } from '../../page/route';
import { API } from '../index';
import { AuthService } from '../../auth/auth.service';
import axios from 'axios';
import { API_URL } from '../../model/constants';

class CUserService extends CrudService {
  constructor() {
    super('v1/user', USER_ROUTES);
  }

  getFullName(user: UserBrief | undefined) {
    return [user?.lastName, user?.firstName, user?.secondName].filter((s) => !!s).join(' ');
  }

  forgotPassword(email: string) {
    return axios.post(`${API_URL}${this.baseUrl}/forgot`, { email })
  }

  async changePassword(dto: ChangePasswordDto, token?: string | null) {
    if (AuthService.getAuth()) {
      return API.patch(`${this.baseUrl}/changePassword`, {
        ...dto,
        uuid: AuthService.getUser()?.uuid,
      })
    } else {
      return axios.patch(`${API_URL}${this.baseUrl}-settings/changePassword`, {
        ...dto,
        token,
      })
    }
  }

  protected async update(uuid: string, dto: UserEditDto) {
    if (uuid === AuthService.getUser()?.uuid) {
      delete dto.isActive;
    }
    return super.update(uuid, dto);
  }

  async confirmSettings(confirmationToken: string) {
    return (await axios.post(`${API_URL}${this.baseUrl}-settings`, { confirmationToken })).data;
  }

  async confirmEmail(token: string | null, isApi: boolean | null) {
    return (await axios.post(`${API_URL}${this.baseUrl}/confirm-email`, { token, isApi })).data;
  }
}

export const UserService = new CUserService();
