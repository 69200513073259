import { FilterItem } from './filter.item';
import { FilterItemProps } from './interfaces/filter.item.props';
import { FilterTypeEnum } from './enum/filter.type.enum';
import { AutocompleteResponse } from '../interfaces';
import { EntityQueryKey } from '../../query/query.keys';

export class FilterAutocompleteItem extends FilterItem {
  fetch: (queryKey: EntityQueryKey, term: string | (string | number)[], skip?: number) => Promise<AutocompleteResponse>;
  queryKey: EntityQueryKey;

  constructor(
    props: Omit<FilterItemProps, 'type'> & {
      fetch: (queryKey: EntityQueryKey, term: string | (string | number)[], skip?: number) => Promise<AutocompleteResponse>;
      queryKey: EntityQueryKey;
    },
  ) {
    super({ ...props, type: FilterTypeEnum.AUTOCOMPLETE });
    this.defaultValue = props.multiple ? [] : null;
    this.fetch = props.fetch;
    this.queryKey = props.queryKey;
  }
}
