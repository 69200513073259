import { CrudService } from '../crud.service';
import { CERTIFICATE_ROUTES } from '../../page/route';
import { API } from '../index';

class CCertificateService extends CrudService {
  constructor() {
    super('v1/certificate', CERTIFICATE_ROUTES);
  }

  async revoke(uuid: string) {
    return (await API.patch<boolean>(`${this.baseUrl}/revoke/${uuid}`)).data;
  }

  async renew(uuid: string) {
    return (await API.post<boolean>(`${this.baseUrl}/renew/${uuid}`)).data;
  }
}

export const CertificateService = new CCertificateService();
