import { UserAction } from '../../../model/actions/user.action';
import { useAddAction, useRemoveAction } from '../../../model/actions/common.actions';
import { DOCUMENT_ROUTES } from '../../route';
import { DocumentBrief, DocumentStatusEnum } from '../../../model';
import { DocumentService } from '../../../api/document/document.service';

type AvailableActions = 'add' | 'remove';

export function useActions(): Record<AvailableActions, UserAction<DocumentBrief>> {
  const add = useAddAction<DocumentBrief>(DOCUMENT_ROUTES);
  const remove = useRemoveAction<DocumentBrief>(DocumentService, (entity) => entity?.activeVersion == true && entity.status === DocumentStatusEnum.DRAFT);

  return { add, remove };
}