import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { DocumentBrief } from '../../../model';
import {
  convertToRowAction,
  useGroupActions,
  useRowActions,
} from '../../../components/common/data/DataTable/table.utils';
import { DocumentService } from '../../../api/document/document.service';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<DocumentBrief>[], TableGroupAction[], UserAction<DocumentBrief>[]] {
  const actions = useUserActions();

  const rowActions = useRowActions<DocumentBrief>({
    remove: convertToRowAction(actions.remove),
  });

  const groupActions = useGroupActions({
    remove: (selected) => DocumentService.remove(selected as string[]),
  });

  const toolbarActions = [actions.add];

  return [rowActions, groupActions, toolbarActions];
}
