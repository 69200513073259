import IValidator from './validator';
import validator from 'validator';
import ValidationRule from '../validation.rule.enum';

export class MatchValidator implements IValidator<string> {
  rule = ValidationRule.Match;
  private readonly pattern: RegExp;

  constructor(pattern: RegExp) {
    this.pattern = pattern;
  }

  validate(value: string) {
    return validator.matches(value, this.pattern) ? null : (['common:validation.formatMismatch'] as [string, ...any[]]);
  }
}