import ModelValidator from '../model.validator';
import { UserEditDto } from '../../../model';
import validator from 'validator';
import isEmail = validator.isEmail;
import isStrongPassword = validator.isStrongPassword;

class Validator extends ModelValidator<UserEditDto> {
  validate(model: UserEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.lastName, errors);
    this.checkRequired(model.firstName, errors);
    this.checkRequired(model.email, errors);
    this.check(model.email, errors, isEmail);
    this.checkLength(model.lastName, errors, 255);
    this.checkLength(model.firstName, errors, 255);
    this.checkLength(model.secondName, errors, 255);
    this.checkLength(model.email, errors, 255);
    this.checkLength(model.phone ?? '', errors, 255);
    this.checkLength(model.password ?? '', errors, 255);
    this.checkLength(model.passwordConfirmation ?? '', errors, 255);
    this.check(model.password, errors, isStrongPassword);
    if (model.password !== model.passwordConfirmation) {
      errors.push('mismatch');
    }
    return errors;
  }
}

export const EditUserValidator = new Validator();
