import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { CheckCircleOutlined, SvgIconComponent } from '@mui/icons-material';

interface IProps {
  labelKey: string;
  value?: string | number | null;
  optional?: boolean;
  badge?: SvgIconComponent;
  showBadge?: boolean;
  badgeColor?: 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export function TextDisplay(props: PropsWithChildren<IProps>) {
  const { t } = useTranslation();
  const Badge = useMemo(() => props.badge ?? CheckCircleOutlined, [props.badge]);

  if (props.optional && !props.value) {
    return null;
  }

  return <>
    <Grid2 xs={4}>
      <Typography fontWeight="bold" className="display-label">{t(props.labelKey)}</Typography>
    </Grid2>
    <Grid2 xs={8} display="flex" alignItems="center">
      <span>{props.value}</span>
      {props.showBadge !== undefined && props.value && <Badge color={props.showBadge ? (props.badgeColor ?? 'success') : 'disabled'} sx={{ ml: 1 }}/>}
      {props.children}
    </Grid2>
  </>
}