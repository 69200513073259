import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { UserBrief } from '../../../model';
import { UserService } from '../../../api/user/user.service';
import { AuthService } from '../../../auth/auth.service';
import {
  convertToRowAction,
  useGroupActions,
  useRowActions,
} from '../../../components/common/data/DataTable/table.utils';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<UserBrief>[], TableGroupAction[], UserAction<UserBrief>[]] {
  const { t } = useTranslation();
  const actions = useUserActions()

  const rowActions = useRowActions<UserBrief>({
    remove: convertToRowAction(actions.remove),
    toggleActive: convertToRowAction(actions.toggleActive),
    other: [
      convertToRowAction(actions.edit),
      convertToRowAction(actions.toggleAdmin),
      convertToRowAction(actions.toggleEmail),
      convertToRowAction(actions.togglePhone),
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => UserService.remove(selected as string[]),
    setActive: (selected) => UserService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => UserService.toggle('isActive', selected as string[], false),
    other: [
      ...(AuthService.getUser()?.isSuperAdmin ? [{
        value: 'setAdmin',
        text: t(`user:button.toggleAdmin.true`),
        action: (sm: GridRowSelectionModel) => UserService.toggle('isAdmin', sm as string[], true),
        confirm: {
          description: t('user:confirm.toggleAdmin.true'),
        },
      },
      {
        value: 'removeAdmin',
        text: t(`user:button.toggleAdmin.false`),
        action: (sm: GridRowSelectionModel) => UserService.toggle('isAdmin', sm as string[], false),
        confirm: {
          description: t('user:confirm.toggleAdmin.false'),
        },
      }] : []),
      {
        value: 'setEmail',
        text: t(`user:button.toggleEmail.true`),
        action: (sm) => UserService.toggle('emailVerified', sm as string[], true),
        confirm: {
          description: 'user:confirm.toggleEmail.true',
        },
      },
      {
        value: 'removeEmail',
        text: t(`user:button.toggleEmail.false`),
        action: (sm) => UserService.toggle('emailVerified', sm as string[], false),
        confirm: {
          description: 'user:confirm.toggleEmail.false',
        },
      },
      {
        value: 'setPhone',
        text: t(`user:button.togglePhone.true`),
        action: (sm) => UserService.toggle('phoneVerified', sm as string[], true),
        confirm: {
          description: 'user:confirm.togglePhone.true',
        },
      },
      {
        value: 'removePhone',
        text: t(`user:button.togglePhone.false`),
        action: (sm) => UserService.toggle('phoneVerified', sm as string[], false),
        confirm: {
          description: 'user:confirm.togglePhone.false',
        },
      },
    ],
  });

  const toolbarActions = [actions.add];

  return [rowActions, groupActions, toolbarActions];
}
