import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { UserBrief } from '../../../model';
import { DateDisplay, DetailPage, LinkDisplay, TextDisplay } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { USER_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';
import { Typography, useTheme } from '@mui/material';
import { useActions } from '../actions';

export function UserDetails() {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<UserBrief>();
  const actions = useActions();
  const theme = useTheme();

  return (
    <DetailPage
      queryKey={EntityQueryKey.User}
      entitySetter={setEntity}
      routes={USER_ROUTES}
      service={UserService}
      titleParams={(entity) => [UserService.getFullName(entity)]}
      actions={[actions.edit, actions.toggleActive, actions.toggleEmail, actions.togglePhone, actions.toggleAdmin, actions.remove]}
    >
      <TextDisplay labelKey="user:field.fullName" value={UserService.getFullName(entity)}>
        {entity?.isDeleted && <Typography variant="body2" color={theme.palette.error.main} ml={1} >({t('common:field.deletedM')})</Typography>}
      </TextDisplay>
      <TextDisplay labelKey="common:field.isActiveM" value={t(`common:bool.${entity?.isActive}`)}/>
      <TextDisplay labelKey="common:field.email" value={entity?.email} showBadge={entity?.emailVerified}/>
      <TextDisplay labelKey="common:field.phone" value={entity?.phone} showBadge={entity?.phoneVerified}/>
      <DetailPage.Service>
        <TextDisplay labelKey="common:field.uuid" value={entity?.uuid}/>
        <TextDisplay labelKey="user:field.isAdmin" value={t(`common:bool.${entity?.isAdmin}`)}/>
        <LinkDisplay labelKey="common:field.createdBy" to={USER_ROUTES.details(entity?.createdBy.uuid)} text={UserService.getFullName(entity?.createdBy)}/>
        <DateDisplay labelKey="common:field.createDate" value={entity?.createDate}/>
        <LinkDisplay labelKey="common:field.updatedBy" to={USER_ROUTES.details(entity?.updatedBy.uuid)} text={UserService.getFullName(entity?.updatedBy)}/>
        <DateDisplay labelKey="common:field.updateDate" value={entity?.updateDate}/>
      </DetailPage.Service>
    </DetailPage>
  );
}
