import { CrudService } from '../crud.service';
import { SIGNER_ROUTES } from '../../page/route';

class CSignQueueService extends CrudService {
  constructor() {
    super('v1/signqueue', SIGNER_ROUTES);
  }
}

export const SignQueueService = new CSignQueueService();
