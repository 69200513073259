import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { FormHelperText, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSlots } from '../../../../hooks/UseSlots';

function ReadonlyBlock({ children }: PropsWithChildren) {
  return <>{ children }</>
}

function EditableBlock({ children }: PropsWithChildren) {
  return <>{ children }</>
}

interface IProps {
  readonly: boolean;
  linkTextKey?: string;
}

function ReadonlySwitch (props: PropsWithChildren<IProps>) {
  const linkTextKey = useMemo(() => props.linkTextKey ?? 'common:component.ReadonlySwitch.readonly', [props.linkTextKey]);
  const { main, readonlyBlock, editableBlock } = useSlots(props.children, {
    readonlyBlock: ReadonlyBlock,
    editableBlock: EditableBlock,
  });

  const { t } = useTranslation();
  const [showReadOnly, setShowReadonly] = useState(props.readonly);
  useEffect(() => {
    setShowReadonly(props.readonly);
  }, [props.readonly]);

  return (
    <>
        {showReadOnly && readonlyBlock.length > 0 && readonlyBlock}
        {!showReadOnly && editableBlock.length > 0 && editableBlock}
        {readonlyBlock.length > 0 && editableBlock.length > 0 && <FormHelperText sx={{ mb: 1, textAlign: 'right' }}>
          <Link component="button" onClick={() => setShowReadonly(!showReadOnly)}>{t(`${linkTextKey}.${showReadOnly}`)}</Link>
        </FormHelperText>}
      {main}
    </>
  );
}

ReadonlySwitch.Readonly = ReadonlyBlock;
ReadonlySwitch.Editable = EditableBlock;

export { ReadonlySwitch };