import { useTranslation } from 'react-i18next';
import { DataTable, Title } from '../../../components/common';
import { useFilter } from './filter.definition';
import { useColumns } from './table.definition';
import { EntityQueryKey } from '../../../query/query.keys';
import { useActions } from './action.definition';
import { CertificateTypeEnum } from '../../../model';
import { CertificateService } from '../../../api/certificate/certificate.service';

interface IProps {
  type: CertificateTypeEnum;
}

export function CertificateList({ type }: IProps) {
  const { t } = useTranslation();
  const columns = useColumns(type);
  const filter = useFilter(type);
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t(`certificate:list.${type}.pageTitle`)} />
      <DataTable
        id="certificate-list"
        queryKey={EntityQueryKey.Certificate}
        columns={columns}
        fetchData={CertificateService.listFn}
        filter={filter}
        sortBy="createDate"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}