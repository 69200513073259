import {
  ConditionalBlock,
  DateDisplay,
  DetailPage,
  FileDisplay,
  LinkDisplay,
  TextDisplay,
} from '../../../components/common';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EntityQueryKey } from '../../../query/query.keys';
import { PassportBrief, PassportTypeEnum } from '../../../model';
import { CLIENT_ROUTES, PASSPORT_ROUTES, USER_ROUTES } from '../../route';
import { PassportService } from '../../../api/passport/passport.service';
import { UserService } from '../../../api/user/user.service';
import { useActions } from '../actions';

export function PassportDetails() {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<PassportBrief>();
  const actions = useActions();

  return (
    <DetailPage
      queryKey={EntityQueryKey.Passport}
      entitySetter={setEntity}
      titleParams={(entity) => [t(`passport:type.${entity?.type}`), PassportService.getNumberView(entity)]}
      service={PassportService}
      routes={PASSPORT_ROUTES}
      actions={[actions.edit, actions.toggleActive, actions.toggleVerified, actions.remove]}
    >
      <TextDisplay labelKey="passport:field.type" value={t(`passport:type.${entity?.type}`)} />
      <ConditionalBlock value={entity?.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <TextDisplay labelKey="passport:field.numberView" value={PassportService.getNumberView(entity)} showBadge={entity?.isVerified}/>
      </ConditionalBlock>
      <LinkDisplay
        labelKey="common:field.user"
        to={USER_ROUTES.details(entity?.userUuid)}
        text={UserService.getFullName(entity?.user)}
      />
      <LinkDisplay
        labelKey="common:field.client"
        to={CLIENT_ROUTES.details(entity?.clientGuid)}
        text={entity?.client?.fullName}
        showBadge={entity?.client?.isVerified}
      />
      <TextDisplay labelKey="passport:field.fullName" value={[entity?.lastName, entity?.firstName, entity?.secondName].filter((s) => !!s).join(' ')} />
      <TextDisplay labelKey="passport:field.gender" value={entity?.gender ? t(`common:gender.${entity?.gender}`) : ''}/>
      <DateDisplay labelKey="passport:field.birthDate" value={entity?.birthDate}/>
      <ConditionalBlock value={entity?.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <TextDisplay labelKey="passport:field.birthAddress" value={entity?.birthAddress}/>
      </ConditionalBlock>
      <TextDisplay labelKey="passport:field.issuedBy" value={entity?.issuedBy}/>
      <ConditionalBlock value={entity?.type} conditions={[PassportTypeEnum.PASSPORT]}>
        <TextDisplay labelKey="passport:field.departmentCode" value={entity?.departmentCode}/>
      </ConditionalBlock>
      <DateDisplay labelKey="passport:field.issueDate" value={entity?.issueDate}/>
      <FileDisplay files={entity?.files}/>
      <DetailPage.Service>
        <TextDisplay labelKey="common:field.uuid" value={entity?.uuid}/>
        <TextDisplay labelKey="common:field.user" value={entity?.userUuid}/>
        <TextDisplay labelKey="common:field.client" value={entity?.clientGuid}/>
        <LinkDisplay labelKey="common:field.createdBy" to={USER_ROUTES.details(entity?.createdBy.uuid)} text={UserService.getFullName(entity?.createdBy)}/>
        <DateDisplay labelKey="common:field.createDate" value={entity?.createDate}/>
        <LinkDisplay labelKey="common:field.updatedBy" to={USER_ROUTES.details(entity?.updatedBy.uuid)} text={UserService.getFullName(entity?.updatedBy)}/>
        <DateDisplay labelKey="common:field.updateDate" value={entity?.updateDate}/>
      </DetailPage.Service>
    </DetailPage>
  );
}
