import { TextDisplay } from '../TextDisplay';
import moment from 'moment';

interface IProps {
  labelKey: string;
  value?: Date | string | number | null;
  optional?: boolean;
  format?: string;
}

export function DateDisplay(props: IProps) {
  const format = props.format ?? 'DD.MM.YYYY';
  return <TextDisplay labelKey={props.labelKey} optional={props.optional} value={props.value ? moment(props.value).format(format) : ''}/>
}