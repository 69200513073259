import { SnilsBrief } from '../../interfaces';
import { EditableEntityDto, FileDto } from '../common';

export class SnilsEditDto extends EditableEntityDto<SnilsBrief> {
  userUuid = '';
  clientGuid = '';
  lastName = '';
  firstName = '';
  secondName = '';
  number = '';
  birthDate = '';
  birthAddress = '';
  gender = '';
  issueDate = '';
  files: FileDto[];

  constructor(entity: SnilsBrief|undefined) {
    super(entity);
    Object.entries(entity ?? {}).forEach(([key, val]) => {
      if (this.hasOwnProperty(key)) {
        this[key as keyof SnilsEditDto] = val as never ?? '';
      }
    });
    this.files = entity?.files?.map((file) => new FileDto(file.name, file.mediaType, file.uuid)) ?? [];
  }
}