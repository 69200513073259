import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_URL } from '../model/constants';
import { AuthService } from '../auth/auth.service';

const API = axios.create({ baseURL: API_URL });

API.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    config.headers.Authorization = AuthService.getAuth()?.authToken ? `Bearer ${AuthService.getAuth()?.authToken}` : undefined;
    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const authToken = await AuthService.refresh();
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      return API(originalRequest);
    }
    return Promise.reject(error);
  },
);

export { API };
