import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '../../../../hooks/UseValidation';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { FormControl, FormHelperText, IconButton } from '@mui/material';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Clear, Event } from '@mui/icons-material';

interface IProps extends InputControlProps<string | null> {
  timePicker?: boolean;
  minDateTime?: string | Date;
  maxDateTime?: string | Date;
}

export default function DateControl({ timePicker = false, ...props }: IProps) {
  const { t } = useTranslation();
  const validate = useValidation(props.validators);
  const { validateOn } = useContext(ValidationContext);
  const [errorText, setErrorText] = useState<string>();

  useEffect(() => {
    setErrorText(validate(props.value, props.tab ?? 'main'));
  }, [props.value, validateOn]);

  const updateValue = (value: Dayjs | null) => {
    if (value && !timePicker) {
      value = value.hour(0).minute(0)
    }
    props.onChange(value?.toJSON() ?? null, getView(value?.toDate()));
  };

  const getView = (value: Date | undefined) => {
    return value ? (timePicker ? value.toLocaleString() : value.toLocaleDateString()) : null;
  };

  return (
    <FormControl fullWidth required={props.required} error={!!errorText}>
      <DateTimePicker
        label={t(props.labelKey)}
        showDaysOutsideCurrentMonth
        slots={{
          openPickerButton: (opbProps) => (
            <>
              <IconButton
                tabIndex={0}
                aria-label={t('common:button.clear') ?? ''}
                onClick={() => updateValue( null)}
                disabled={!props.value}
              >
                <Clear color="disabled" fontSize="small" />
              </IconButton>
              <IconButton {...opbProps}>
                <Event />
              </IconButton>
            </>
          ),
        }}
        slotProps={{
          textField: {
            size: 'small',
            margin: 'dense',
            required: props.required,
            error: !!errorText,
          },
        }}
        maxDateTime={props.maxDateTime ? dayjs(props.maxDateTime) : undefined}
        minDateTime={props.minDateTime ? dayjs(props.minDateTime) : undefined}
        value={props.value ? dayjs(props.value) : null}
        onChange={(value) => updateValue(value)}
        format={`DD.MM.YYYY${timePicker ? ' hh:mm:ss' : ''}`}
        viewRenderers={{
          hours: timePicker ? renderTimeViewClock : null,
          minutes: timePicker ? renderTimeViewClock : null,
          seconds: timePicker ? renderTimeViewClock : null,
        }}
        views={['day']}
      />
      {props.hintKey && <FormHelperText>{t(props.hintKey)}</FormHelperText>}
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
