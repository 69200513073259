import { Route } from 'react-router-dom';
import { CLIENT_ROUTES } from '../../route';
import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import React from 'react';
import { ClientList } from '../ClientList';
import { ClientEdit } from '../ClientEdit';
import { ClientDetails } from '../ClientDetails';

export const CLIENT_ROUTE = (
  <Route>
    <Route path={CLIENT_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<ClientList />} />
    </Route>
    <Route path={CLIENT_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<ClientEdit />} />
    </Route>
    <Route path={CLIENT_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<ClientEdit />} />
    </Route>
    <Route path={CLIENT_ROUTES.details()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<ClientDetails />} />
    </Route>
  </Route>
);