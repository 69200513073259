import { Avatar, Box, colors, Container, CssBaseline, Typography } from '@mui/material';
import React, { FormEvent, useCallback, useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Footer } from '../../../components/common';
import { useTranslation } from 'react-i18next';
import InputControl from '../../../components/common/ui/InputControl';
import { IsEmail, NotEmpty } from '../../../hooks/UseValidation/validators';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { ForgotPasswordValidator } from '../../../service/validator/restore/forgot.password.validator';
import { UserService } from '../../../api/user/user.service';
import { LoadingButton } from '@mui/lab';

enum STEP {
  EMAIL = 'EMAIL',
  TOKEN = 'TOKEN',
}

export function ForgotPassword() {
  const { t } = useTranslation('restore', { keyPrefix: 'forgotPassword' });
  const errorHandler = useErrorHandler();
  const [validateOn, setValidateOn] = useState(false);
  const [step, setStep] = useState<STEP>(STEP.EMAIL);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async (e: FormEvent, email: string) => {
    e.preventDefault();
    setValidateOn(true);
    window.scrollTo(0, 0);
      const errors = ForgotPasswordValidator.validate(email);
      if (errors.length === 0) {
        try {
          setLoading(true);
          await UserService.forgotPassword(email);
          setValidateOn(false);
          setStep(STEP.TOKEN);
        } catch (e) {
          errorHandler('forgotPassword', e, {
            notFound: { key: 'restore:error.userNotFound' },
            internal: { key: 'restore:error.failedSend' }
          });
        } finally {
          setLoading(false);
        }
      }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('pageTitle')}
        </Typography>
        <Box component="form" onSubmit={(e) => submit(e, email)} noValidate sx={{ mt: 1, minWidth: '400px' }} autoComplete="on">
          <ValidationContext.Provider value={{ validateOn }}>
            {
              {
                [STEP.EMAIL]: <>
                  <Typography color={colors.grey} variant="body2">{t(`hint`)}</Typography>
                  <InputControl
                    name="username"
                    required
                    labelKey="restore:forgotPassword.field.email"
                    autoComplete="username email"
                    value={email}
                    onChange={(value) => setEmail(value)}
                    validators={[NotEmpty, IsEmail]}
                    size="medium"
                  />
                  <LoadingButton type="submit" loading={loading} loadingPosition="end" endIcon={<></>} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    {t('submit')}
                  </LoadingButton>
                </>,
                [STEP.TOKEN]: <Typography align="center">{t('successMessage')}</Typography>
              }[step]
            }
          </ValidationContext.Provider>
        </Box>
      </Box>
      <Footer sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
