import { GridOverlay } from '@mui/x-data-grid';
import React from 'react';
import { Loader } from '../../Loader';

export function TableLoader() {
  return (
    <GridOverlay sx={{ background: '#fff' }}>
      <Loader />
    </GridOverlay>
  );
}
