import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import {
  convertToRowAction,
  useGroupActions,
  useRowActions,
} from '../../../components/common/data/DataTable/table.utils';
import { VerificationRequestBrief } from '../../../model/interfaces/verification';
import { VerificationService } from '../../../api/verification/verification.service';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<VerificationRequestBrief>[], TableGroupAction[], UserAction<VerificationRequestBrief>[]] {
  const actions = useUserActions();

  const rowActions = useRowActions<VerificationRequestBrief>({
    remove: convertToRowAction(actions.remove),
    other: [
      convertToRowAction(actions.edit),
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => VerificationService.remove(selected as string[]),
  });

  const toolbarActions = [actions.add];

  return [rowActions, groupActions, toolbarActions];
}
