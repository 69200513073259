import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ClientBrief, OrganizationTypeEnum, VerifyingDataEnum, VersionStatusEnum } from '../../../model';
import { ConditionalBlock, DateDisplay, DetailPage, LinkDisplay, TextDisplay } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { CLIENT_ROUTES, PASSPORT_ROUTES, SNILS_ROUTES, USER_ROUTES } from '../../route';
import { ClientService } from '../../../api/client/client.service';
import { UserService } from '../../../api/user/user.service';
import { useQuery } from 'react-query';
import { Typography, useTheme } from '@mui/material';
import { useActions } from '../actions';

export function ClientDetails() {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<ClientBrief>();
  const theme = useTheme();
  const actions = useActions();
  const { data: physical } = useQuery(
    [EntityQueryKey.Client, OrganizationTypeEnum.PHYSICAL, entity?.owner.uuid],
    () =>
      ClientService.list<any>({
        filter: { type: OrganizationTypeEnum.PHYSICAL, user: entity?.owner.uuid, isActive: true },
      }),
    {
      enabled: entity !== undefined && entity.type !== OrganizationTypeEnum.PHYSICAL,
      select: (data) => data.items[0],
    },
  );

  return (
    <DetailPage
      queryKey={EntityQueryKey.Client}
      entitySetter={setEntity}
      routes={CLIENT_ROUTES}
      service={ClientService}
      titleParams={(entity) => [entity?.shortName]}
      actions={[actions.edit, actions.toggleActive, actions.toggleVerified, actions.remove]}
    >
      {entity?.versionStatus === VersionStatusEnum.DELETED && <TextDisplay labelKey="">
        <Typography fontWeight="bold" color={theme.palette.error.main} >{t('common:field.deletedM')}</Typography>
      </TextDisplay>}
      <TextDisplay labelKey="client:field.type" value={t(`client:type.full.${entity?.type}`)} />
      <TextDisplay labelKey="common:field.isVerifiedM" value={t(`common:bool.${entity?.isVerified}`)} />
      <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={entity?.type}>
        <LinkDisplay labelKey="client:field.fio" to={USER_ROUTES.details(entity?.owner.uuid)} text={entity?.fullName} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.FIO)} />
      </ConditionalBlock>
      <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={entity?.type} negate>
        <LinkDisplay labelKey="client:field.owner" to={USER_ROUTES.details(entity?.owner.uuid)} text={UserService.getFullName(entity?.owner)} />
        <LinkDisplay labelKey="client:field.physical" to={physical ? CLIENT_ROUTES.details(physical?.guid) : ''} text={physical?.fullName} showBadge={physical?.isVerified}  />
      </ConditionalBlock>
      <TextDisplay labelKey="common:field.isActiveM" value={t(`common:bool.${entity?.isActive}`)}/>
      <TextDisplay labelKey="client:field.inn" value={entity?.inn} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.INN)}/>
      <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={entity?.type}>
        <LinkDisplay labelKey="client:field.passport" to={entity?.passportUuid ? PASSPORT_ROUTES.details(entity.passportUuid) : ''} text={entity?.passport}  showBadge={entity?.verifiedData.includes(VerifyingDataEnum.PASSPORT)}/>
        <LinkDisplay labelKey="client:field.snils" to={entity?.snilsUuid ? SNILS_ROUTES.details(entity.snilsUuid) : ''} text={entity?.snils}  showBadge={entity?.verifiedData.includes(VerifyingDataEnum.SNILS)}/>
      </ConditionalBlock>
      <TextDisplay labelKey="client:field.ogrnip" value={entity?.ogrnip} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.OGRNIP)} optional={entity?.type !== OrganizationTypeEnum.INDIVIDUAL}/>
      <TextDisplay labelKey="client:field.ogrn" value={entity?.ogrn} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.OGRN)} optional={entity?.type !== OrganizationTypeEnum.JURIDICAL}/>
      <TextDisplay labelKey="client:field.kpp" value={entity?.kpp} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.KPP)} optional={entity?.type !== OrganizationTypeEnum.JURIDICAL}/>
      <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={entity?.type} negate>
        <TextDisplay labelKey="client:field.fullName" value={entity?.fullName} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.FULL_NAME)}/>
        <TextDisplay labelKey="client:field.shortName" value={entity?.shortName} />
      </ConditionalBlock>
      <TextDisplay labelKey="client:field.address" value={entity?.address} showBadge={entity?.verifiedData.includes(VerifyingDataEnum.ADDRESS)}/>
      <ConditionalBlock conditions={[OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL]} value={entity?.type}>
        <TextDisplay labelKey="common:field.email" value={entity?.email}/>
        <TextDisplay labelKey="common:field.phone" value={entity?.phone}/>
        <DateDisplay labelKey="client:field.liquidationDate" value={entity?.liquidationDate}/>
      </ConditionalBlock>
      <DetailPage.Service>
        <TextDisplay labelKey="common:field.uuid" value={entity?.uuid}/>
        <TextDisplay labelKey="common:field.guid" value={entity?.guid}/>
        <TextDisplay labelKey="common:field.status" value={entity?.versionStatus}/>
        <LinkDisplay labelKey="common:field.createdBy" to={USER_ROUTES.details(entity?.createdBy.uuid)} text={UserService.getFullName(entity?.createdBy)}/>
        <DateDisplay labelKey="common:field.createDate" value={entity?.createDate}/>
        <LinkDisplay labelKey="common:field.updatedBy" to={USER_ROUTES.details(entity?.updatedBy.uuid)} text={UserService.getFullName(entity?.updatedBy)}/>
        <DateDisplay labelKey="common:field.updateDate" value={entity?.updateDate}/>
      </DetailPage.Service>
    </DetailPage>
  );
}
