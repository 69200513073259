import { Trans, useTranslation } from 'react-i18next';
import { Title } from '../../../components/common/typography';
import { Content } from '../../../components/common';
import { Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

export function NotFound() {
  const { t, i18n } = useTranslation('error');
  const location = useLocation();

  return (
    <>
      <Title text={t('notFound.title')} />
      <Content>
        <Typography variant="h5" component="h2">{t('notFound.reason.title')}</Typography>
        <ul>
          <li>{t('notFound.reason.r1', [location.pathname])}</li>
          <li>{t('notFound.reason.r2')}</li>
          <li>{t('notFound.reason.r3')}</li>
        </ul>
        <Typography variant="h5" component="h2">{t('notFound.suggest.title')}</Typography>
        <ul>
          <li>{t('notFound.suggest.s1')}</li>
          <li>{t('notFound.suggest.s2', [location.pathname])}</li>
          <li>
            <Trans i18n={i18n} i18nKey="error:notFound.suggest.s3" components={{
              homeLink: <Link href="/" />
            }}/>
          </li>
        </ul>
      </Content>
    </>
  );
}
