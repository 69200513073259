import { PassportBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AuthService } from '../../../auth/auth.service';
import { PASSPORT_ROUTES } from '../../route';
import { PassportService } from '../../../api/passport/passport.service';
import { UserAction } from '../../../model/actions/user.action';
import {
  useAddAction,
  useEditAction,
  useRemoveAction,
  useToggleActiveAction,
} from '../../../model/actions/common.actions';

type AvailableActions = 'add' | 'edit' | 'remove' | 'toggleActive' | 'toggleVerified';

export function useActions(): Record<AvailableActions, UserAction<PassportBrief>> {
  const { t } = useTranslation();
  const user = useMemo(() => AuthService.getUser(), []);
  const canModify = useCallback(
    (entity?: PassportBrief) => entity != undefined && !entity.isDeleted && user != null && (user.isAdmin || user.uuid === entity.userUuid),
    [user],
  );
  const add = useAddAction<PassportBrief>(PASSPORT_ROUTES);
  const edit = useEditAction<PassportBrief>(PASSPORT_ROUTES, (entity) => !entity?.isVerified && canModify(entity));
  const remove = useRemoveAction<PassportBrief>(PassportService, (entity) => canModify(entity));
  const toggleActive = useToggleActiveAction<PassportBrief>({
    service: PassportService,
    available: (entity) => entity?.isVerified === true && canModify(entity),
    localeBtnNS: 'passport',
    localeConfirmNS: 'passport',
  });

  return {
    add,
    edit,
    remove,
    toggleActive,
    toggleVerified: {
      key: 'toggleVerified',
      btnText: () => t('client:button.toggleVerified.false'),
      btnColor: () => 'warning',
      available: (entity) => user?.isAdmin === true && entity?.isVerified === true,
      action: (entity) => PassportService.toggle('isVerified', entity?.uuid ?? '', false),
      confirm: () => ({
        description: 'passport:confirm.toggleVerified',
      }),
    }
  };
}
