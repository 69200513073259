export enum VerifyingDataEnum {
  FIO = 'FIO',
  FULL_NAME = 'FULL_NAME',
  INN = 'INN',
  OGRN = 'OGRN',
  OGRNIP = 'OGRNIP',
  SNILS = 'SNILS',
  KPP = 'KPP',
  ADDRESS = 'ADDRESS',
  PASSPORT = 'PASSPORT',
}
