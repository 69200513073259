import ModelValidator from '../model.validator';
import { SnilsEditDto } from '../../../model/dto/snils';

class Validator extends ModelValidator<SnilsEditDto> {
  validate(model: SnilsEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.userUuid, errors);
    this.checkLength(model.lastName, errors, 60);
    this.checkLength(model.firstName, errors, 60);
    this.checkLength(model.secondName, errors, 60);
    this.checkRequired(model.number, errors);
    this.checkLength(model.birthAddress, errors, 150);
    return errors;
  }
}

export const EditSnilsValidator = new Validator();
