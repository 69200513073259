import { PassportTypeEnum } from '../../enum';
import { PassportBrief } from '../../interfaces';
import { EditableEntityDto, FileDto } from '../common';

export class PassportEditDto extends EditableEntityDto<PassportBrief> {
  type = PassportTypeEnum.PASSPORT;
  userUuid = '';
  clientGuid = '';
  lastName = '';
  firstName = '';
  secondName = '';
  number = '';
  birthDate = '';
  gender = '';
  issuedBy = '';
  issueDate = '';
  files: FileDto[];

  // PASSPORT
  series = '';
  birthAddress = '';
  departmentCode = '';

  constructor(entity: PassportBrief|undefined) {
    super(entity);
    Object.entries(entity ?? {}).forEach(([key, val]) => {
      if (this.hasOwnProperty(key)) {
        this[key as keyof PassportEditDto] = val as never ?? '';
      }
    });
    this.files = entity?.files?.map((file) => new FileDto(file.name, file.mediaType, file.uuid)) ?? [];
  }
}