import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableDateColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../api/user/user.service';
import { SnilsBrief } from '../../../model';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { SnilsService } from '../../../api/snils/snils.service';
import { ClientService } from '../../../api/client/client.service';
import { DownloadLink } from '../../../components/common';
import { Stack } from '@mui/material';
import { getFileView } from '../../../utils';
import { FileService } from '../../../api/file/file.service';

export function useColumns() {
  const { t } = useTranslation();
  const isDeleted = useBoolTableColumn<SnilsBrief>({
    headerName: t('common:field.deletedM'),
    field: 'isDeleted',
  });
  const isActive = useBoolTableColumn<SnilsBrief>({
    headerName: t('common:field.isActiveM'),
    field: 'isActive',
    sortable: true,
  });
  const isVerified = useBoolTableColumn<SnilsBrief>({
    headerName: t('common:field.isVerifiedM'),
    field: 'isVerified',
    sortable: true,
  });

  return useMemo(
    () => [
      new TableColumn<SnilsBrief>({
        headerName: t('snils:field.fullName'),
        field: 'lastName,firstName,secondName',
        valueGetter: ({ row }) => SnilsService.getFullName(row),
        sortable: true,
      }),
      new TableLinkColumn<SnilsBrief>({
        headerName: t('snils:field.number'),
        field: 'number',
        linkProps: (row) => ({ to: SnilsService.detailsUrl(row.uuid) }),
        sortable: false,
      }),
      new TableDateColumn<SnilsBrief>({
        headerName: t('snils:field.issueDate'),
        field: 'issueDate',
        sortable: false,
      }),
      new TableDateColumn<SnilsBrief>({
        headerName: t('snils:field.birthDate'),
        field: 'birthDate',
        sortable: false,
      }),
      new TableColumn<SnilsBrief>({
        headerName: t('snils:field.gender'),
        field: 'gender',
        sortable: false,
        valueFormatter: ({ value }) => t(`common:gender.${value}`),
      }),
      new TableLinkColumn<SnilsBrief>({
        headerName: t('snils:field.user'),
        field: 'userUuid',
        linkProps: (row) => ({ to: UserService.detailsUrl(row.userUuid) }),
        text: (row) => row.userView?.view,
        sortable: true,
      }),
      new TableLinkColumn<SnilsBrief>({
        headerName: t('snils:field.client'),
        field: 'clientGuid',
        linkProps: (row) => ({ to: ClientService.detailsUrl(row.clientGuid) }),
        text: (row) => row.clientView?.view,
        sortable: true,
      }),
      isVerified,
      new TableColumn<SnilsBrief>({
        headerName: t('snils:field.files'),
        field: 'files',
        sortable: false,
        renderCell: ({ row }) =>
          row.files?.length > 0 ? (
            <Stack direction="column" sx={{ overflow: 'hidden' }}>
              {row.files.map((file, idx) => {
                const view = t(...getFileView(file)) ?? file.name;
                return (
                  <DownloadLink
                    key={idx}
                    size="small"
                    title={view as any}
                    sx={{ maxWidth: '100%' }}
                    downloader={() => FileService.download(file.uuid)}
                  >
                    <small
                      style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                      {t(...getFileView(file)) as any}
                    </small>
                  </DownloadLink>
                );
              })}
            </Stack>
          ) : null,
        minWidth: 150,
        flex: 1,
      }),
      new TableDateTimeColumn<SnilsBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<SnilsBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
      isActive,
      isDeleted,
    ],
    [],
  );
}
