import ModelValidator from '../model.validator';
import { LoginDto } from '../../../model';
import validator from 'validator';
import isEmail = validator.isEmail;

class Validator extends ModelValidator<LoginDto> {
  validate(model: LoginDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.email, errors);
    this.checkRequired(model.password, errors);
    this.check(model.email, errors, isEmail);
    return errors;
  }
}

export const SignInValidator = new Validator();
