import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { ClientService } from '../../../api/client/client.service';
import { Stack } from '@mui/material';
import { VerificationService } from '../../../api/verification/verification.service';
import { VerificationRequestBrief, VerificationRequestData } from '../../../model/interfaces/verification';

export function useColumns() {
  const { t } = useTranslation();
  const isAutomatic = useBoolTableColumn<VerificationRequestBrief>({
    headerName: t('verification:field.isAutomatic'),
    field: 'isAutomatic',
    sortable: true,
  });

  return useMemo(
    () => [
      new TableLinkColumn<VerificationRequestBrief>({
        headerName: t('common:field.id'),
        field: 'number',
        linkProps: (row) => ({ to: VerificationService.detailsUrl(row.uuid) }),
        sortable: false,
        width: 50,
      }),
      new TableColumn<VerificationRequestBrief>({
        headerName: t('verification:field.type'),
        field: 'type',
        sortable: true,
        valueFormatter: ({ value }) => value.view,
      }),
      new TableColumn<VerificationRequestBrief>({
        headerName: t('common:field.status'),
        field: 'status',
        sortable: true,
        valueFormatter: ({ value }) => t(`verification:status.${value}`),
      }),
      new TableLinkColumn<VerificationRequestBrief>({
        headerName: t('common:field.client'),
        field: 'client',
        linkProps: (row) => ({ to: ClientService.detailsUrl(row.client.guid) }),
        text: (row) => row.client.view,
        sortable: true,
      }),
      new TableColumn<VerificationRequestBrief>({
        headerName: t('verification:field.data'),
        field: 'data',
        sortable: false,
        renderCell: ({ value }) =>
            <Stack direction="column" sx={{ overflow: 'hidden' }}>
              {value?.map((item: VerificationRequestData, idx: number) => <small key={idx}>{item.dataType.view}: {item.value}</small>)}
            </Stack>,
        minWidth: 150,
        flex: 1,
      }),
      isAutomatic,
      new TableDateTimeColumn<VerificationRequestBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<VerificationRequestBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
    ],
    [],
  );
}
