import { FileOwnerTypeEnum } from '../../model';
import { API } from '../index';

class CFileService {
  private readonly baseUrl = 'v1/file'

  async download(uuid: string | undefined, ownerType?: FileOwnerTypeEnum) {
    if (!uuid) return null;

    return (await API.get(`${this.baseUrl}/${uuid}`, { params: { ownerType } })).data;
  }
}

export const FileService = new CFileService();
