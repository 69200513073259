import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { EmployeeBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import {
  convertToRowAction,
  useGroupActions,
  useRowActions,
} from '../../../components/common/data/DataTable/table.utils';
import { EmployeeService } from '../../../api/employee/employee.service';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<EmployeeBrief>[], TableGroupAction[], UserAction<EmployeeBrief>[]] {
  const { t } = useTranslation();
  const actions = useUserActions();

  const rowActions = useRowActions<EmployeeBrief>({
    remove: convertToRowAction(actions.remove),
    toggleActive: convertToRowAction(actions.toggleActive),
    other: [
      convertToRowAction(actions.toggleAdmin),
      convertToRowAction(actions.toggleConfirmed),
      convertToRowAction(actions.toggleDefault),
      convertToRowAction(actions.edit),
    ]
  });

  const groupActions = useGroupActions({
    remove: (selected) => EmployeeService.remove(selected as string[]),
    setActive: (selected) => EmployeeService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => EmployeeService.toggle('isActive', selected as string[], false),
    other: [
      {
        value: 'setAdmin',
        text: t(`employee:button.toggleAdmin.true`),
        action: (sm) => EmployeeService.toggle('isAdmin', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleAdmin.true'),
        },
      },
      {
        value: 'removeAdmin',
        text: t(`employee:button.toggleAdmin.false`),
        action: (sm) => EmployeeService.toggle('isAdmin', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleAdmin.false'),
        },
      },
      {
        value: 'setConfirm',
        text: t(`employee:button.toggleConfirmed.true`),
        action: (sm) => EmployeeService.toggle('isConfirmed', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleConfirmed.true'),
        },
      },
      {
        value: 'removeConfirm',
        text: t(`employee:button.toggleConfirmed.false`),
        action: (sm) => EmployeeService.toggle('isConfirmed', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleConfirmed.false'),
        },
      },
      {
        value: 'setDefault',
        text: t(`employee:button.toggleDefault.true`),
        action: (sm) => EmployeeService.toggle('isDefault', sm as string[], true),
        confirm: {
          description: t('employee:confirm.toggleDefault.true'),
        },
      },
      {
        value: 'removeDefault',
        text: t(`employee:button.toggleDefault.false`),
        action: (sm) => EmployeeService.toggle('isDefault', sm as string[], false),
        confirm: {
          description: t('employee:confirm.toggleDefault.false'),
        },
      },
    ],
  });

  const toolbarActions = [actions.add];

  return [rowActions, groupActions, toolbarActions];
}