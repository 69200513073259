import { PropsWithChildren } from 'react';
import { Grid, Paper } from '@mui/material';

export function Content({ children }: PropsWithChildren) {
  return (
    <Grid item xs={12} pl={0}>
      <Paper elevation={3} sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 1,
        py: 2,
      }}>
        {children}
      </Paper>
    </Grid>
  )
}