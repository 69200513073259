import { EditableEntityDto } from '../common';
import { EmployeeBrief } from '../../interfaces';

export class EmployeeEditDto extends EditableEntityDto<EmployeeBrief> {
  user = '';
  client = '';
  post = '';
  isActive = true;
  isConfirmed = false;
  isDefault = false;
  isAdmin = false;

  constructor(entity: EmployeeBrief | undefined,) {
    super(entity);
    Object.entries(entity ?? {}).forEach(([key, val]) => {
      if (this.hasOwnProperty(key)) {
        this[key as keyof EmployeeEditDto] = val as never;
      }
    });
    this.user = entity?.user?.uuid ?? '';
    this.client = entity?.clientGuid ?? '';
  }
}