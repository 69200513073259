import { useTranslation } from 'react-i18next';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { EmployeeBrief } from '../../../model';
import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { EmployeeService } from '../../../api/employee/employee.service';
import { ClientService } from '../../../api/client/client.service';
import { UserService } from '../../../api/user/user.service';

export function useColumns() {
  const { t } = useTranslation();
  const isDeleted = useBoolTableColumn<EmployeeBrief>({
    headerName: t('common:field.deletedM'),
    field: 'isDeleted',
  });
  const isActive = useBoolTableColumn<EmployeeBrief>({
    headerName: t('common:field.isActiveM'),
    field: 'isActive',
    sortable: true,
  });
  const isDefault = useBoolTableColumn<EmployeeBrief>({
    headerName: t('employee:field.isDefault'),
    field: 'isDefault',
    sortable: true,
  });
  const isConfirmed = useBoolTableColumn<EmployeeBrief>({
    headerName: t('employee:field.isConfirmed'),
    field: 'isConfirmed',
    sortable: true,
    width: 160,
  });
  const isAdmin = useBoolTableColumn<EmployeeBrief>({
    headerName: t('employee:field.isAdmin'),
    field: 'isAdmin',
    width: 150,
  });

  return useMemo(() => [
    new TableLinkColumn<EmployeeBrief>({
      headerName: t('user:field.fullName'),
      field: 'user',
      text: (row) => UserService.getFullName(row.user),
      linkProps: (row) => ({ to: EmployeeService.detailsUrl(row.uuid) }),
      sortable: true,
    }),
    new TableLinkColumn<EmployeeBrief>({
      headerName: t('employee:field.client'),
      field: 'client',
      text: (row) => row.client?.view,
      linkProps: (row) => ({ to: ClientService.detailsUrl(row.clientGuid) }),
      sortable: true,
    }),
    new TableColumn<EmployeeBrief>({
      headerName: t('employee:field.post'),
      field: 'post',
    }),
    isDefault,
    isConfirmed,
    isAdmin,
    new TableLinkColumn<EmployeeBrief>({
      headerName: t('employee:field.user'),
      field: 'userUuid',
      text: (row) => row.user.uuid,
      linkProps: (row) => ({ to: UserService.detailsUrl(row.user.uuid) }),
      sortable: true,
    }),
    new TableDateTimeColumn<EmployeeBrief>({
      headerName: t('common:field.createDate'),
      field: 'createDate',
      sortable: true,
    }),
    new TableDateTimeColumn<EmployeeBrief>({
      headerName: t('common:field.updateDate'),
      field: 'updateDate',
      sortable: true,
    }),
    isActive,
    isDeleted,
  ], []);
}
