import { CrudService } from '../crud.service';
import { SNILS_ROUTES } from '../../page/route';
import { SnilsBrief } from '../../model';

class CSnilsService extends CrudService {
  constructor() {
    super('v1/snils', SNILS_ROUTES);
  }

  getFullName(passport: SnilsBrief | undefined) {
    return [passport?.lastName, passport?.firstName, passport?.secondName].filter((s) => !!s).join(' ');
  }

  fullView(entity: undefined | SnilsBrief) {
    return `${this.getFullName(entity)} ${entity?.number ?? ''}`;
  }
}

export const SnilsService = new CSnilsService();
