import queryCache from './query.cache';
import { QueryClient } from 'react-query';

const createQueryClient = (onErrorFn: (err: any) => void) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        keepPreviousData: true,
        staleTime: 300000,
        retry: 0,
        onError: (err) => onErrorFn(err),
      },
      mutations: {
        onError: (err) => onErrorFn(err),
      },
    },
    queryCache: queryCache,
  });

export default createQueryClient;
