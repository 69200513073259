import { createContext } from 'react';

interface IPageContext {
  adminMenu: boolean;
  toggleAdminMenu: (state?: boolean) => void;
}

export const PageContext = createContext<IPageContext>({
  adminMenu: false,
  toggleAdminMenu: () => void 0,
})