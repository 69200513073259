import { useTranslation } from 'react-i18next';
import { useColumns } from './table.definition';
import { useFilter } from './filter.definition';
import { useActions } from './action.definition';
import { DataTable, Title } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { SignQueueService } from '../../../api/document/sign.queue.service';

export function SignerList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('signer:list.pageTitle')} />
      <DataTable
        id="signer-list"
        queryKey={EntityQueryKey.Signer}
        columns={columns}
        fetchData={SignQueueService.listFn}
        filter={filter}
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
        itemId={(row) => `${row.employee?.uuid}_${row.document?.guid}`}
      />
    </>
  );
}