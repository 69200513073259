import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { SNILS_ROUTES } from '../../route';
import { SnilsList } from '../SnilsList';
import { SnilsEdit } from '../SnilsEdit';
import { SnilsDetails } from '../SnilsDetails';

export const SNILS_ROUTE = (
  <Route>
    <Route path={SNILS_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<SnilsList />} />
    </Route>
    <Route path={SNILS_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<SnilsEdit />} />
    </Route>
    <Route path={SNILS_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<SnilsEdit />} />
    </Route>
    <Route path={SNILS_ROUTES.details()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<SnilsDetails />} />
    </Route>
  </Route>
);
