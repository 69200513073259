import ModelValidator from '../model.validator';
import { ChangePasswordDto } from '../../../model';

class Validator extends ModelValidator<ChangePasswordDto> {
  validate(model: ChangePasswordDto, checkOldPassword: boolean): string[] {
    const errors: string[] = [];
    this.checkRequired(model.password, errors);
    this.checkRequired(model.passwordConfirmation, errors);
    if (errors.length === 0 && model.password !== model.passwordConfirmation) {
      errors.push('mismatch');
    }
    if (checkOldPassword) {
      this.checkRequired(model.oldPassword, errors);
    }
    return errors;
  }
}

export const ChangePasswordValidator = new Validator();
