import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { VerificationRequestBrief } from '../../../model/interfaces/verification';
import {
  DateDisplay,
  DetailPage,
  Dialog,
  FileDisplay,
  InnerBlock,
  LinkDisplay,
  TextDisplay,
} from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { CLIENT_ROUTES, PASSPORT_ROUTES, SNILS_ROUTES, USER_ROUTES, VERIFICATION_ROUTES } from '../../route';
import { VerificationService } from '../../../api/verification/verification.service';
import { VerificationRequestStatusEnum, VerifyingDataEnum } from '../../../model';
import { UserService } from '../../../api/user/user.service';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import { resetQueryFn } from '../../../utils';
import { useErrorHandler } from '../../../hooks/UseErrorHandler';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import InputControl from '../../../components/common/ui/InputControl';
import { Link as RouteLink } from 'react-router-dom';
import { useActions } from '../actions';

export function VerificationRequestDetails() {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<VerificationRequestBrief>();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [rejectCause, setRejectCause] = useState('');
  const actions = useActions();

  const reject =
    () => {
      setLoading(true);
      return changeStatusMutation.mutate(rejectCause);
    };

  const changeStatusMutation = useMutation(
    (rejectCause: string) =>
      VerificationService.changeStatus(entity?.uuid, VerificationRequestStatusEnum.REJECTED, rejectCause),
    {
      onSuccess: async () => {
        await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, EntityQueryKey.Verification) });
        enqueueSnackbar({ message: t('verification:message.success.status'), variant: 'success' });
      },
      onError: (error) => {
        errorHandler(`Failed to change status`, error);
      },
      onSettled: () => {
        setLoading(false);
        setRejectCause('');
      },
    },
  );

  return (
    <DetailPage
      queryKey={EntityQueryKey.Verification}
      entitySetter={setEntity}
      titleParams={(entity) => [entity?.number]}
      routes={VERIFICATION_ROUTES}
      service={VerificationService}
      actions={[actions.edit, actions.send, actions.accept, actions.remove]}
      loading={loading}
    >
      <TextDisplay labelKey='common:field.status' value={t(`verification:status.${entity?.status}`)} />
      <TextDisplay labelKey='verification:field.rejectCause' value={entity?.rejectCause} optional />
      <LinkDisplay
        labelKey='common:field.client'
        to={CLIENT_ROUTES.details(entity?.client.guid)}
        text={entity?.client.view}
      />
      <TextDisplay labelKey='verification:field.type' value={entity?.type.view} />
      {entity?.sourceClient && (
        <LinkDisplay
          labelKey={`client:type.full.${entity.sourceClient.type}`}
          to={CLIENT_ROUTES.details(entity?.sourceClient.guid)}
          text={entity?.sourceClient.view}
          showBadge={entity?.sourceClient.isVerified}
        />
      )}
      <InnerBlock labelKey='verification:field.data'>
        {entity?.data.map((item, idx) => {
          switch (item.dataType.code) {
            case VerifyingDataEnum.SNILS:
              return (
                <LinkDisplay
                  key={idx}
                  labelKey={item.dataType.view}
                  text={item.value}
                  to={SNILS_ROUTES.details(item.complexValueUuid as string)}
                />
              );
            case VerifyingDataEnum.PASSPORT:
              return (
                <LinkDisplay
                  key={idx}
                  labelKey={item.dataType.view}
                  text={item.value}
                  to={PASSPORT_ROUTES.details(item.complexValueUuid as string)}
                />
              );
            default:
              return <TextDisplay key={idx} labelKey={item.dataType.view} value={item.value} />;
          }
        })}
      </InnerBlock>
      <FileDisplay files={entity?.files} />
      <Grid2 container xs={12}>
        <Accordion variant='elevation' disableGutters sx={{ width: '100%' }}>
          <AccordionSummary sx={{ my: 0 }}>{t('verification:details.history')}</AccordionSummary>
          <AccordionDetails>
            <DataGrid
              columns={[
                {
                  headerName: t('common:field.date') as string,
                  field: 'date',
                  valueFormatter: ({ value }) => moment(value).format('DD.MM.YYYY'),
                  sortable: false,
                },
                {
                  headerName: t('common:field.status') as string,
                  field: 'status',
                  width: 120,
                  valueFormatter: ({ value }) => t(`verification:status.${value}`),
                  sortable: false,
                },
                {
                  headerName: t('common:field.user') as string,
                  field: 'user',
                  renderCell: ({ row }) => <Box className='MuiDataGrid-cellContent'>
                    <Link component={RouteLink} to={USER_ROUTES.details(row.user.uuid)}>
                      {row.user.view}
                    </Link>
                  </Box>,
                  sortable: false,
                  flex: 1,
                },
              ]}
              rows={entity?.history ?? []}
              getRowId={(row) => row.date}
              disableColumnMenu
              disableColumnSelector
              disableRowSelectionOnClick
              slots={{ footer: Box }}
              density='compact'
            />
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <DetailPage.Service>
        <TextDisplay labelKey='common:field.uuid' value={entity?.uuid} />
        <TextDisplay labelKey='verification:field.isAutomatic' value={t(`common:bool.${entity?.automatic}`)} />
        <TextDisplay labelKey='common:field.client' value={entity?.client.guid} />
        <LinkDisplay
          labelKey='common:field.createdBy'
          to={USER_ROUTES.details(entity?.user.uuid)}
          text={UserService.getFullName(entity?.createdBy)}
        />
        <DateDisplay labelKey='common:field.createDate' value={entity?.createDate} />
        <LinkDisplay
          labelKey='common:field.updatedBy'
          to={USER_ROUTES.details(entity?.updatedBy.uuid)}
          text={UserService.getFullName(entity?.updatedBy)}
        />
        <DateDisplay labelKey='common:field.updateDate' value={entity?.updateDate} />
      </DetailPage.Service>
      <DetailPage.Buttons>
        {actions.reject.available(entity) && [
          <LoadingButton key={actions.reject.key} loading={loading} variant='outlined'
                         color={actions.reject.btnColor?.()} onClick={() => setOpen(true)}>
            {actions.reject.btnText?.()}
          </LoadingButton>,

          <Dialog key='rejectCause' open={open} onClose={() => setOpen(false)} onConfirm={reject}
                  confirmButtonKey='verification:button.reject'
                  confirmButtonProps={{ color: 'warning', disabled: !rejectCause }}>
            <InputControl labelKey='verification:field.rejectCause' value={rejectCause}
                          onChange={(val) => setRejectCause(val)} />
          </Dialog>,
        ]}
      </DetailPage.Buttons>
    </DetailPage>
  );
}
