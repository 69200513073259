export const OIDS = [
  { oid: '1.2.643.3.131.1.1',    short: 'INN',    full: 'ИНН' },
  { oid: '1.2.643.100.4',        short: 'INNLE',  full: 'ИНН ЮЛ' },
  { oid: '1.2.643.100.1',        short: 'OGRN',   full: 'ОГРН' },
  { oid: '1.2.643.100.5',        short: 'OGRNIP', full: 'ОГРНИП' },
  { oid: '1.2.643.100.3',        short: 'SNILS',  full: 'СНИЛС' },
  { oid: '1.2.840.113549.1.9.1', short: 'E',      full: 'emailAddress' },
  { oid: '2.5.4.3',              short: 'CN',     full: 'commonName' },
  { oid: '2.5.4.4',              short: 'SN',     full: 'surname' },
  { oid: '2.5.4.42',             short: 'G',      full: 'givenName' },
  { oid: '2.5.4.6',              short: 'C',      full: 'countryName' },
  { oid: '2.5.4.7',              short: 'L',      full: 'localityName' },
  { oid: '2.5.4.8',              short: 'S',      full: 'stateOrProvinceName' },
  { oid: '2.5.4.9',              short: 'STREET', full: 'streetAddress' },
  { oid: '2.5.4.10',             short: 'O',      full: 'organizationName' },
  { oid: '2.5.4.11',             short: 'OU',     full: 'organizationalUnitName' },
  { oid: '2.5.4.12',             short: 'T',      full: 'title' },
];

export const CAPICOM_PROPID_KEY_PROV_INFO = 2;

export const CADESCOM_CADES_BES = 1;            	// Тип подписи CAdES-BES.
export const CADESCOM_CADES_T = 0x5;              // Тип подписи CAdES-T.
export const CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d; // Тип подписи CAdES-X Long Type 1.

export const TSA = [
  'http://pki.tax.gov.ru/tsp/tsp.srf',                  // (TSP-сервер ФНС)
  'http://tax4.tensor.ru/tsp-tensor_gost2012/tsp.srf',  // (Тензор)
  'http://qs.cryptopro.ru/tsp/tsp.srf',                 // (Такском)
];
export const TSA_ERROR_CODE = '0xC2100100';