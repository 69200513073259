import React, { ReactEventHandler } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './css/main.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto';
import { CookiesProvider } from 'react-cookie';
import { ScrollToTop } from './components/common';
import { createRoot } from 'react-dom/client';
import './service/i18n';

declare module 'react' {
  interface DOMAttributes<T> {
    onResize?: ReactEventHandler<T> | undefined;
    onResizeCapture?: ReactEventHandler<T> | undefined;
    nonce?: string | undefined;
  }
}

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
