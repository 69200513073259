import { EditableEntityDto, FileDto } from '../common';
import { VerificationRequestBrief } from '../../interfaces/verification';
import { VerifyingDataDto } from './verifying.data.dto';

export class VerificationRequestEditDto extends EditableEntityDto<VerificationRequestBrief> {
  clientGuid = '';
  typeGuid = '';
  typeCode = '';
  data: VerifyingDataDto[] = [];
  files: FileDto[];
  sourceType = '';
  source = '';

  constructor(entity: VerificationRequestBrief | undefined) {
    super(entity);
    if (entity != undefined) {
      this.clientGuid = entity.client.guid;
      this.typeGuid = entity.type.guid;
      this.typeCode = entity.type.code;
      this.data = entity.data.map(
        (item) =>
          new VerifyingDataDto(item.dataType.guid, item.value, item.dataType.view, item.dataType.code, item.complexValueUuid),
      );
      this.sourceType = entity.sourceType;
      this.source = entity.source ?? '';
    }
    this.files = entity?.files?.map((file) => new FileDto(file.name, file.mediaType, file.uuid)) ?? [];
  }
}
