import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { SignQueueListItem } from '../../../model/interfaces/signqueue';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<SignQueueListItem>[], TableGroupAction[], UserAction<SignQueueListItem>[]] {
  const actions = useUserActions();

  return [[], [], [actions.add]];
}
