import { Icon, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { File } from '../../../../model/interfaces/file';
import { useFileIcon } from '../../../../hooks/UseFileIcon';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { getFileView } from '../../../../utils';
import { FileService } from '../../../../api/file/file.service';
import { DownloadLink } from '../../ui';

interface IProps {
  labelKey?: string;
  files: File | File[] | undefined;
}

export function FileDisplay(props: IProps) {
  const getIcon = useFileIcon();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Grid2 xs={4}>
        <Typography fontWeight="bold" className="display-label">{t(props.labelKey ?? 'common:field.files')}</Typography>
      </Grid2>
      <Grid2 xs={8}>
        <Stack direction="column">
          {new Array<File>().concat(props.files ?? []).map((file, idx) => {
            const view: any = t(...getFileView(file)) ?? file.name;
            return [<DownloadLink key={file.uuid} title={view} downloader={() => FileService.download(file.uuid)}>
              <Stack key={idx} direction="row">
                <Icon sx={{mr: 1, display: 'block' }}>{getIcon(file.mediaType)}</Icon>
                <span>{view}</span>
              </Stack>
            </DownloadLink>,
              <small key={idx} style={{ color: theme.palette.text.secondary }}>SHA: {file.sha256}</small>]
          })}
        </Stack>
      </Grid2>
    </>
  );
}
