import { useTranslation } from 'react-i18next';
import { useColumns } from './table.definition';
import { useFilter } from './filter.definition';
import { useActions } from './action.definition';
import { DataTable, Title } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { ClientService } from '../../../api/client/client.service';

export function ClientList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('client:list.pageTitle')} />
      <DataTable
        id="client-list"
        queryKey={EntityQueryKey.Client}
        columns={columns}
        fetchData={ClientService.listFn}
        filter={filter}
        sortBy="shortName"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}
