import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EntityQueryKey } from '../../../query/query.keys';
import { ClientBrief, ListResponseDto, OrganizationTypeEnum, VerifyingDataEnum } from '../../../model';
import { ErrorRoute } from '../../error/routes';
import { EditPage } from '../../../components/common/layout/EditPage';
import { EditClientValidator } from '../../../service/validator/client';
import { ClientEditDto } from '../../../model/dto/client';
import { ClientService } from '../../../api/client/client.service';
import { CLIENT_ROUTES, PASSPORT_ROUTES, SNILS_ROUTES } from '../../route';
import { useOptions } from '../../../hooks/UseOptions';
import RadioControl from '../../../components/common/ui/RadioControl';
import InputControl from '../../../components/common/ui/InputControl';
import AutocompleteControl from '../../../components/common/ui/AutocompleteControl';
import CheckboxControl from '../../../components/common/ui/CheckboxControl';
import { MaskedInput } from '../../../components/common/ui/MaskedInput';
import DateControl from '../../../components/common/ui/DateControl';
import { useTranslation } from 'react-i18next';
import { IsEmail, Length, NotEmpty, NumberStringValidator } from '../../../hooks/UseValidation/validators';
import { CheckCircleOutlined, ControlPointOutlined } from '@mui/icons-material';
import { useDocumentTitle } from '../../../hooks/UseDocumentTitle';
import { PhoneControl } from '../../../components/common/ui/PhoneControl';
import { ReadonlySwitch, BadgedInputControl, ConditionalBlock } from '../../../components/common';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { Box, IconButton, useTheme } from '@mui/material';

export function ClientEdit() {
  const uuid = useParams<{ uuid: string }>().uuid;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const types = useOptions(OrganizationTypeEnum, 'client:type.short.')
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});
  const theme = useTheme();

  const { data: entity } = useQuery([EntityQueryKey.Client, uuid], () => ClientService.get<ClientBrief>(uuid ?? 'false', 'version'), {
    enabled: !!uuid,
    onSuccess: (data) => {
      if (data.isAdmin || !data.activeVersion) {
        navigate(ErrorRoute.ACCESS_DENIED, { replace: true });
      }
      setDto({ ...new ClientEditDto(data) });
    },
  });
  useDocumentTitle({ params: [entity?.shortName] });
  const [dto, setDto] = useState(new ClientEditDto(entity));
  const updateDto = (key: keyof ClientEditDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));
  const innMask = (dto: ClientEditDto) => dto.type === OrganizationTypeEnum.JURIDICAL ? '0000000000' : '000000000000';

  const { data: pClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.PHYSICAL, dto.user], () => ClientService.list<any>({
      filter: { type: OrganizationTypeEnum.PHYSICAL, user: dto.user, isActive: true }
    }), {
    enabled: !!dto.user,
  });
  const { data: iClient } = useQuery([EntityQueryKey.Client, OrganizationTypeEnum.INDIVIDUAL, dto.user], () => ClientService.list<any>({
      filter: { type: OrganizationTypeEnum.INDIVIDUAL, user: dto.user, isActive: true }
    }), {
    enabled: !!dto.user,
  });

  useEffect(() => {
    if (dto.user) {
      if (dto.type === OrganizationTypeEnum.PHYSICAL && !!iClient?.items?.length && !dto.inn && iClient.items[0].verifiedData.includes(VerifyingDataEnum.INN)) {
        setDto((prev) => ({ ...prev, inn: iClient.items[0].inn }));
      }
      if (dto.type === OrganizationTypeEnum.INDIVIDUAL && !!pClient?.items?.length) {
        const item = pClient.items[0];
        const newData: Partial<ClientEditDto> = {
          guidRegion: item.guidRegion,
          district: item.district,
          locality: item.locality,
          street: item.street,
          house: item.house,
          room: item.room,
          building: item.building,
          additionalInfo: item.additionalInfo,
          index: item.index,
          address: item.address,
        }
        if (!dto.inn && item.verifiedData.includes(VerifyingDataEnum.INN)) {
          newData.inn = item.inn;
        }
        setDto((prev) => ({
          ...prev,
          ...newData,
        }));
      }
    }
  }, [dto.user, dto.type, pClient?.items, iClient?.items])

  const disableActions = useMemo(() =>
    !!dto.user && !uuid && ((dto.type === OrganizationTypeEnum.PHYSICAL && !!pClient?.items?.length) ||(dto.type === OrganizationTypeEnum.INDIVIDUAL && !!iClient?.items?.length)),
    [uuid, dto.user, dto.type, pClient, iClient]);

  const clientError = useCallback((uuid: string|undefined, dto: ClientEditDto, pClient?: ListResponseDto<any>, iClient?: ListResponseDto<any>) => {
    if (!!uuid || !dto.user) {
      return null;
    }
    switch(dto.type) {
      case OrganizationTypeEnum.PHYSICAL:
        return !!pClient?.items?.length ? t('client:physicalExists') : null;
      case OrganizationTypeEnum.INDIVIDUAL:
        return !!iClient?.items?.length ? t('client:individualExists') : null
      default:
        return null;
    }
  }, []);

  return (
    <EditPage titleKey="client:edit.pageTitle" titleParams={[entity?.shortName]} validator={EditClientValidator} dto={dto} queryKey={EntityQueryKey.Client} routes={CLIENT_ROUTES} service={ClientService}
              disableActions={disableActions} apiErrors={setApiErrors}>
      <RadioControl row items={types} labelKey="client:field.type" value={dto.type} onChange={(val) => updateDto('type', val)} disabled={!!uuid}/>
      {(!uuid || dto.type === OrganizationTypeEnum.JURIDICAL) && <AutocompleteControl required value={dto.user} entity={EntityQueryKey.User} labelKey="client:field.owner" onChange={(val) => { updateDto('user', val); } } validators={[NotEmpty]} disabled={!!uuid}
                           error={clientError(uuid, dto, pClient, iClient)}/>}
      {dto.type === OrganizationTypeEnum.PHYSICAL &&
        <BadgedInputControl labelKey="client:field.fio" value={dto.fullName} active={entity?.verifiedData.includes(VerifyingDataEnum.FIO)} onChange={(val) => updateDto('fullName', val)} validators={[Length(511)]} />}
      {dto.type !== OrganizationTypeEnum.PHYSICAL && dto.user && (<>
        <InputControl labelKey="client:field.physical" value={pClient?.items?.[0]?.shortName ?? t('client:physicalMissed')} disabled={true} onChange={() => null}
                      inputProps={{
                        endAdornment:
                          (pClient?.items?.[0]?.isVerified) ? <CheckCircleOutlined color="success" />
                            : null
                      }}/>
      </>)}
      <CheckboxControl labelKey="common:field.isActiveM" value={dto.isActive} onChange={(val) => updateDto('isActive', val)}/>
      <BadgedInputControl required={dto.type !== OrganizationTypeEnum.PHYSICAL} type="masked" labelKey="client:field.inn" value={dto.inn} onChange={(val) => updateDto('inn', val)}
                    disabled={entity?.verifiedData.includes(VerifyingDataEnum.INN)}
                    customComponentProps={{ mask: innMask(dto) }} validators={dto.type === OrganizationTypeEnum.PHYSICAL ? [] : [NotEmpty]}
                    error={apiErrors['SGNX:VLDN:CLNT:INN_REQ_ISINN']} active={entity?.verifiedData.includes(VerifyingDataEnum.INN)} hideBadge={!uuid}
                    inputProps={{
                      inputComponent: MaskedInput,
                    }}/>
      {!!uuid && <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={dto.type}>
        <ReadonlySwitch readonly={entity?.passport != null}>
          {entity?.passport != null && <ReadonlySwitch.Readonly>
            <BadgedInputControl labelKey="client:field.passport" value={entity?.passport} disabled active onChange={() => null} />
          </ReadonlySwitch.Readonly>}
          <ReadonlySwitch.Editable>
            <Box display="flex" flexDirection="row" alignItems="center">
            <AutocompleteControl value={dto.passportUuid} entity={EntityQueryKey.Passport} auxQueryKey={['isVerified_true', dto.user]} labelKey="client:field.passport" onChange={(val) => updateDto('passportUuid', val)}
                                 options={(queryKey, term, skip) => AutocompleteService.fetch(queryKey, term, skip, { isVerified: true, user: dto.user })}
                                 renderOption={(props, option) => {
                                   return (
                                     <li {...props} key={option.value}>
                                       <span style={{ color: theme.palette[option.attrs?.isActive ? 'success' : 'error'].main }}>{option.text}</span>
                                       <CheckCircleOutlined sx={{ ml: 1 }} color={option.attrs?.isVerified ?'success' : 'disabled'}/>
                                     </li>
                                   );
                                 }}/>
            {!!uuid && <IconButton sx={{ mt: 1 }} onClick={() => navigate(PASSPORT_ROUTES.create(), { state: { user: entity?.owner.uuid } })}>
              <ControlPointOutlined color="success"/>
            </IconButton>}
          </Box>
          </ReadonlySwitch.Editable>
        </ReadonlySwitch>
        <ReadonlySwitch readonly={entity?.snils != null}>
          {entity?.snils != null && <ReadonlySwitch.Readonly>
            <BadgedInputControl labelKey="client:field.snils" value={entity?.snils} disabled active onChange={() => null} />
          </ReadonlySwitch.Readonly>}
          <ReadonlySwitch.Editable>
            <Box display="flex" flexDirection="row" alignItems="center">
            <AutocompleteControl value={dto.snilsUuid} entity={EntityQueryKey.Snils} auxQueryKey={['isVerified_true', dto.user]} labelKey="client:field.snils" onChange={(val) => updateDto('snilsUuid', val)}
                                 options={(queryKey, term, skip) => AutocompleteService.fetch(queryKey, term, skip, { isVerified: true, user: dto.user })}
                                 renderOption={(props, option) => {
                                   return (
                                     <li {...props} key={option.value}>
                                       <span style={{ color: theme.palette[option.attrs?.isActive ? 'success' : 'error'].main }}>{option.text}</span>
                                       <CheckCircleOutlined sx={{ ml: 1 }} color={option.attrs?.isVerified ?'success' : 'disabled'}/>
                                     </li>
                                   );
                                 }}/>
              {!!uuid && <IconButton sx={{ mt: 1 }} onClick={() => navigate(SNILS_ROUTES.create(), { state: { user: entity?.owner.uuid } })}>
                <ControlPointOutlined color="success"/>
              </IconButton>}
            </Box>
          </ReadonlySwitch.Editable>
        </ReadonlySwitch>
      </ConditionalBlock>}
      <ConditionalBlock conditions={[OrganizationTypeEnum.INDIVIDUAL]} value={dto.type}>
        <InputControl required type="masked" labelKey="client:field.ogrnip" value={dto.ogrnip ?? ''} onChange={(val) => updateDto('ogrnip', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:OGRNIP_REQ_ISOGRN']}/>
      </ConditionalBlock>
      <ConditionalBlock conditions={[OrganizationTypeEnum.JURIDICAL]} value={dto.type}>
        <InputControl required type="masked" labelKey="client:field.ogrn" value={dto.ogrn ?? ''} onChange={(val) => updateDto('ogrn', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '0000000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:OGRN_REQ_ISOGRN']}/>
        <InputControl required type="masked" labelKey="client:field.kpp" value={dto.kpp ?? ''} onChange={(val) => updateDto('kpp', val)}
                      inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000000' }} disabled={entity?.isVerified} validators={[NotEmpty]}
                      error={apiErrors['SGNX:VLDN:CLNT:KPP_REQ_ISKPP']}/>
      </ConditionalBlock>
      <ConditionalBlock conditions={[OrganizationTypeEnum.PHYSICAL]} value={dto.type} negate>
        <InputControl required={dto.type === OrganizationTypeEnum.JURIDICAL} labelKey="client:field.fullName" value={dto.fullName} onChange={(val) => updateDto('fullName', val)} validators={dto.type === OrganizationTypeEnum.JURIDICAL ? [NotEmpty, Length(511)] : [Length(511)]}/>
        <InputControl required={dto.type === OrganizationTypeEnum.JURIDICAL} labelKey="client:field.shortName" value={dto.shortName} onChange={(val) => updateDto('shortName', val)} validators={dto.type === OrganizationTypeEnum.JURIDICAL ? [NotEmpty, Length(255)] : [Length(511)]}/>
      </ConditionalBlock>
      <ReadonlySwitch readonly={true} linkTextKey="client:button.addressSwitch">
        <ReadonlySwitch.Readonly>
          <BadgedInputControl labelKey="client:field.address" value={dto.address} disabled active={entity?.verifiedData.includes(VerifyingDataEnum.ADDRESS)} onChange={() => null} />
        </ReadonlySwitch.Readonly>
        <ReadonlySwitch.Editable>
          <AutocompleteControl value={dto.guidRegion} entity={EntityQueryKey.Region} labelKey="client:field.region" onChange={(val) => updateDto('guidRegion', val)}/>
          <InputControl type="masked" labelKey="client:field.index" value={dto.index} onChange={(val) => updateDto('index', val)}
                        inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000' }} validators={[NumberStringValidator, Length(6)]} />
          <InputControl labelKey="client:field.district" value={dto.district} onChange={(val) => updateDto('district', val)} validators={[Length(255)]}/>
          <InputControl labelKey="client:field.locality" value={dto.locality} onChange={(val) => updateDto('locality', val)} validators={[Length(255)]}/>
          <InputControl labelKey="client:field.street" value={dto.street} onChange={(val) => updateDto('street', val)} validators={[Length(255)]}/>
          <InputControl labelKey="client:field.house" value={dto.house} onChange={(val) => updateDto('house', val)} validators={[Length(255)]}/>
          <InputControl labelKey="client:field.building" value={dto.building} onChange={(val) => updateDto('building', val)} validators={[Length(255)]}/>
          <InputControl labelKey={`client:field.room.${dto.type}`} value={dto.room} onChange={(val) => updateDto('room', val)} validators={[Length(255)]}/>
          <InputControl labelKey="client:field.additionalInfo" value={dto.additionalInfo} onChange={(val) => updateDto('additionalInfo', val)} validators={[Length(255)]}/>
        </ReadonlySwitch.Editable>
      </ReadonlySwitch>
      <ConditionalBlock conditions={[OrganizationTypeEnum.INDIVIDUAL, OrganizationTypeEnum.JURIDICAL]} value={dto.type}>
        <InputControl required type="email" labelKey="common:field.email" value={dto.email} onChange={(val) => updateDto('email', val)} validators={[NotEmpty, IsEmail, Length(255)]}/>
        <PhoneControl value={dto.phone} onChange={(val) => updateDto('phone', val)} />
        <DateControl labelKey="client:field.liquidationDate" value={dto.liquidationDate} onChange={(val) => updateDto('liquidationDate', val)}/>
      </ConditionalBlock>
    </EditPage>
  );
}