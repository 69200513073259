import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { CertificateItemBrief } from '../../../model';
import { convertToRowAction, useRowActions } from '../../../components/common/data/DataTable/table.utils';
import { useActions as useUserActions } from '../actions';
import { UserAction } from '../../../model/actions/user.action';

export function useActions(): [TableRowAction<CertificateItemBrief>[], TableGroupAction[], UserAction<CertificateItemBrief>[]] {
  const actions = useUserActions();

  const rowActions = useRowActions<CertificateItemBrief>({
    other: [
      convertToRowAction(actions.revoke),
      convertToRowAction(actions.renew),
  ]
  });

  return [rowActions, [], []];
}
