import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { EmployeeBrief } from '../../../model';
import { DateDisplay, DetailPage, LinkDisplay, TextDisplay } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { CLIENT_ROUTES, EMPLOYEE_ROUTES, USER_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';
import { Typography, useTheme } from '@mui/material';
import { useActions } from '../actions';
import { EmployeeService } from '../../../api/employee/employee.service';

export function EmployeeDetails() {
  const { t } = useTranslation();
  const [entity, setEntity] = useState<EmployeeBrief>();
  const actions = useActions();
  const theme = useTheme();

  return (
    <DetailPage
      queryKey={EntityQueryKey.Employee}
      entitySetter={setEntity}
      routes={EMPLOYEE_ROUTES}
      service={EmployeeService}
      titleParams={(entity) => [UserService.getFullName(entity?.user)]}
      actions={[actions.edit, actions.toggleActive, actions.toggleConfirmed, actions.toggleDefault, actions.toggleAdmin, actions.remove]}
    >
      <LinkDisplay labelKey="user:field.fullName" to={USER_ROUTES.details(entity?.user.uuid)} text={UserService.getFullName(entity?.user)}>
        {entity?.isDeleted && <Typography variant="body2" color={theme.palette.error.main} ml={1} >({t('common:field.deletedM')})</Typography>}
      </LinkDisplay>
      <LinkDisplay labelKey="common:field.client" to={CLIENT_ROUTES.details(entity?.clientBrief.guid)} text={entity?.clientBrief.shortName} showBadge={entity?.clientBrief.isVerified}/>
      <TextDisplay labelKey="employee:field.post" value={entity?.post}/>
      <TextDisplay labelKey="common:field.isActiveM" value={t(`common:bool.${entity?.isActive}`)}/>
      <TextDisplay labelKey="employee:field.isConfirmed" value={t(`common:bool.${entity?.isConfirmed}`)}/>
      <TextDisplay labelKey="employee:field.isDefault" value={t(`common:bool.${entity?.isDefault}`)}/>
      <TextDisplay labelKey="employee:field.isAdmin" value={t(`common:bool.${entity?.isAdmin}`)}/>
      <DetailPage.Service>
        <TextDisplay labelKey="common:field.uuid" value={entity?.uuid}/>
        <LinkDisplay labelKey="common:field.createdBy" to={USER_ROUTES.details(entity?.createdBy.uuid)} text={UserService.getFullName(entity?.createdBy)}/>
        <DateDisplay labelKey="common:field.createDate" value={entity?.createDate}/>
        <LinkDisplay labelKey="common:field.updatedBy" to={USER_ROUTES.details(entity?.updatedBy.uuid)} text={UserService.getFullName(entity?.updatedBy)}/>
        <DateDisplay labelKey="common:field.updateDate" value={entity?.updateDate}/>
      </DetailPage.Service>
    </DetailPage>
  );
}
