import { CrudService } from '../crud.service';
import { CLIENT_ROUTES } from '../../page/route';
import { ClientEditDto } from '../../model/dto/client';
import { UuidEntity } from '../../model';
import { PrettyClientEditDto } from '../../service/prettify/client';

class CClientService extends CrudService {
  constructor() {
    super('v1/client', CLIENT_ROUTES);
  }

  async save(dto: ClientEditDto, urlModifier?: string): Promise<UuidEntity> {
    return super.save(PrettyClientEditDto(dto), urlModifier);
  }
}

export const ClientService = new CClientService();
