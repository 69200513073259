import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import {
  CertificateItemBrief,
  CertificateTypeEnum,
} from '../../../model';
import { CLIENT_ROUTES, EMPLOYEE_ROUTES, USER_ROUTES } from '../../route';
import { UserService } from '../../../api/user/user.service';
import { DownloadLink } from '../../../components/common';
import { FileService } from '../../../api/file/file.service';

export function useColumns(type: CertificateTypeEnum) {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns = [
      new TableColumn<CertificateItemBrief>({
        headerName: t('common:field.uuid'),
        field: 'uuid',
      }),
      new TableColumn<CertificateItemBrief>({
        headerName: t('certificate:field.name'),
        field: 'name',
      }),
      new TableColumn<CertificateItemBrief>({
        headerName: t('certificate:field.serialNumber'),
        field: 'serialNumber',
      }),
      new TableColumn<CertificateItemBrief>({
        headerName: t('certificate:field.thumbprint'),
        field: 'thumbprint',
      }),
      new TableColumn<CertificateItemBrief>({
        headerName: t('certificate:field.status'),
        field: 'status',
        valueGetter: ({ row }) => {
          if (row.isRevoked) {
            return t('certificate:status.revoked');
          }
          if (row.isValid) {
            return t('certificate:status.valid');
          }
          return t('certificate:status.invalid');
        },
      }),
      new TableLinkColumn<CertificateItemBrief>({
        headerName: t('common:field.user'),
        field: 'user',
        text: (row) => row.employee?.user?.uuid ?? '',
        linkProps: (row) => ({ to: USER_ROUTES.details(row.employee?.user?.uuid) }),
        sortable: true,
      }),
      new TableLinkColumn<CertificateItemBrief>({
        headerName: t('common:field.client'),
        field: 'client',
        text: (row) => row.client?.shortName ?? '',
        linkProps: (row) => ({ to: CLIENT_ROUTES.details(row.clientGuid) }),
        sortable: true,
      }),
      new TableLinkColumn<CertificateItemBrief>({
        headerName: t('common:field.employee'),
        field: 'employee',
        text: (row) => UserService.getFullName(row.employee?.user),
        linkProps: (row) => ({ to: EMPLOYEE_ROUTES.details(row.employeeUuid) }),
        sortable: true,
      }),
      new TableDateTimeColumn<CertificateItemBrief>({
        headerName: t('certificate:field.validFromDate'),
        field: 'validFromDate',
        sortable: true,
      }),
      new TableDateTimeColumn<CertificateItemBrief>({
        headerName: t('certificate:field.validToDate'),
        field: 'validToDate',
        sortable: true,
      }),
      new TableDateTimeColumn<CertificateItemBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
    ];

    if (type === CertificateTypeEnum.INNER_OPENSSL) {
      columns.push(new TableColumn<CertificateItemBrief>({
        headerName: t('certificate:field.file'),
        field: 'print',
        renderCell: ({ row }) =>
          <DownloadLink downloader={() => FileService.download(row.uuid)}>
            {t('common:button.download')}
          </DownloadLink>
      }));
    }

    return columns;
  }, [type]);
}
