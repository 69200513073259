import React, { useContext, useEffect, useState } from 'react';
import { useValidation } from '../../../../hooks/UseValidation';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { Box, FormControl, FormHelperText, IconButton, Stack } from '@mui/material';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';
import { DateOrTimeView, DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Clear, Event } from '@mui/icons-material';
import { DateFilter } from '../../../../model';

interface IProps extends InputControlProps<DateFilter>{
  withTime?: boolean;
}

export default function DateRangeControl({ withTime = true, ...props }: IProps) {
  const { t } = useTranslation();
  const validate = useValidation(props.validators);
  const { validateOn } = useContext(ValidationContext);
  const [errorText, setErrorText] = useState<string>();
  const views = ['year', 'day'].concat(withTime ? ['hours', 'minutes'] : []) as DateOrTimeView[];

  useEffect(() => {
    setErrorText(validate(props.value, props.tab ?? 'main'));
  }, [props.value, validateOn]);

  const updateValue = (field: string, value: Dayjs | null, hours?: number, minutes?: number) => {
    const newValue = {
      ...props.value,
      [field]:
        value
          ?.hour(hours ?? 0)
          .minute(minutes ?? 0)
          .toJSON() ?? null,
    };
    props.onChange(newValue, getView(newValue));
  };

  const getView = (value: DateFilter) => {
    if (value?.startDate && value?.endDate) {
      return `${new Date(value.startDate).toLocaleDateString()} - ${new Date(value.endDate).toLocaleDateString()}`;
    } else if (value?.startDate) {
      return `> ${new Date(value.startDate).toLocaleDateString()}`;
    } else if (value?.endDate) {
      return `< ${new Date(value.endDate).toLocaleDateString()}`;
    }

    return '';
  };

  return (
    <FormControl fullWidth>
      <Stack direction="row" alignItems="center">
        <DateTimePicker
          label={t('common:filter.dateFrom', [`$t(${props.labelKey})`])}
          showDaysOutsideCurrentMonth
          slots={{
            openPickerButton: (opbProps) => (
              <>
                <IconButton
                  tabIndex={0}
                  aria-label={t('common:button.clear') ?? ''}
                  onClick={() => updateValue('startDate', null)}
                  disabled={props.value?.startDate == null}
                >
                  <Clear color="disabled" fontSize="small" />
                </IconButton>
                <IconButton {...opbProps}>
                  <Event />
                </IconButton>
              </>
            ),
          }}
          slotProps={{
            textField: {
              size: 'small',
              margin: 'dense',
            },
          }}
          maxDateTime={dayjs(props.value.endDate)}
          value={props.value.startDate ? dayjs(props.value.startDate) : null}
          onChange={(value) => updateValue('startDate', value, 0, 0)}
          views={views}
        />
        <Box mx={2}>-</Box>
        <DateTimePicker
          label={t('common:filter.dateTo', [`$t(${props.labelKey})`])}
          showDaysOutsideCurrentMonth
          slots={{
            openPickerButton: (opbProps) => (
              <>
                <IconButton
                  tabIndex={0}
                  aria-label={t('common:button.clear') ?? ''}
                  onClick={() => updateValue('endDate', null)}
                  disabled={props.value?.endDate == null}
                >
                  <Clear color="disabled" fontSize="small" />
                </IconButton>
                <IconButton {...opbProps}>
                  <Event />
                </IconButton>
              </>
            ),
          }}
          slotProps={{
            textField: {
              size: 'small',
              margin: 'dense',
            },
          }}
          minDateTime={dayjs(props.value.startDate)}
          value={props.value.endDate ? dayjs(props.value.endDate) : null}
          onChange={(value) => updateValue('endDate', value, 23, 59)}
          views={views}
        />
      </Stack>
      {props.hintKey && <FormHelperText>{t(props.hintKey)}</FormHelperText>}
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
