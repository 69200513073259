import { API } from './index';
import { AutocompleteResponse } from '../model';
import { EntityQueryKey } from '../query/query.keys';

class CAutocompleteService {

  async fetch(entity: EntityQueryKey, term: string|(string|number)[], skip = 0, filter: Record<string, any> = {}, identifierName?: string) {
    if (Array.isArray(term) && !term.length) return { items: [], nextOffset: 0 };
    return (await API.post<AutocompleteResponse>(`v1/autocomplete/${entity}`, {
      term: Array.isArray(term) ? undefined : term,
      identifiers: Array.isArray(term) ? term : undefined,
      skip,
      filter,
      identifierName,
    })).data;
  }

  fetchFn = (entity: EntityQueryKey, term: string|(string|number)[], skip?: number) => this.fetch(entity, term, skip);

  empty() {
    return Promise.resolve<AutocompleteResponse>({ items: [], nextOffset: false });
  }
}

export const AutocompleteService = new CAutocompleteService();