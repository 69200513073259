import { BoolEnum } from '../../enum';
import { useOptions } from '../../../hooks/UseOptions';
import { FilterSelectItem } from '../filter.select.item';
import { FilterItemProps } from '../interfaces/filter.item.props';

export function useBoolFilterItem(props: Pick<FilterItemProps, 'labelKey' | 'field' | 'nullable'>, defaultValue?: string) {
  const options = useOptions(BoolEnum, 'common:');
  const item = new FilterSelectItem({
    labelKey: props.labelKey,
    field: props.field,
    options: options,
    nullable: props.nullable,
  });
  if (defaultValue) {
    item.defaultValue = defaultValue;
    item.defaultView = options.find((o) => o.value === defaultValue)?.text;
    item.value = defaultValue;
    item.view = item.defaultView;
  }
  return item;
}
