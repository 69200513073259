import { PropsWithChildren, useCallback } from 'react';
import { Button, ButtonProps, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  onClose: () => any;
  titleKey?: string;
  confirmButtonKey?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonKey?: string;
  cancelButtonProps?: ButtonProps;
  onConfirm: () => any;
  onCancel?: () => any;
}

const DEFAULT_PROPS = {
  titleKey: 'common:confirm.common.title',
  confirmButtonKey: 'common:button.ok',
  cancelButtonKey: 'common:button.cancel',
}

export function Dialog(props: PropsWithChildren<IProps>) {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    props.onConfirm();
    props.onClose();
  }, [props.onConfirm, props.onClose]);

  const handleCancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel();
    }
    props.onClose();
  }, [props.onCancel, props.onClose]);

  return (
    <MuiDialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>{t(props.titleKey ?? DEFAULT_PROPS.titleKey)}</DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button {...props.cancelButtonProps} onClick={handleCancel}>{t(props.cancelButtonKey ?? DEFAULT_PROPS.cancelButtonKey)}</Button>
        <Button {...props.confirmButtonProps} onClick={handleConfirm}>{t(props.confirmButtonKey ?? DEFAULT_PROPS.confirmButtonKey)}</Button>
      </DialogActions>
    </MuiDialog>
  );
}