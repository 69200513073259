import { useMemo } from 'react';
import {
  TableColumn,
  TableDateTimeColumn,
  TableLinkColumn,
} from '../../../components/common/data/DataTable/table.model';
import { useTranslation } from 'react-i18next';
import { ClientBrief, OrganizationTypeEnum } from '../../../model';
import { useBoolTableColumn } from '../../../components/common/data/DataTable/table.utils';
import { ClientService } from '../../../api/client/client.service';
import { UserService } from '../../../api/user/user.service';
import { EMPLOYEE_ROUTES } from '../../route';

export function useColumns() {
  const { t } = useTranslation();
  const isDeleted = useBoolTableColumn<ClientBrief>({
    headerName: t('common:field.deletedM'),
    field: 'isDeleted',
    valueGetter: ({ row }) => !row.activeVersion,
  });
  const isActive = useBoolTableColumn<ClientBrief>({
    headerName: t('common:field.isActiveM'),
    field: 'isActive',
    sortable: true,
  });
  const isVerified = useBoolTableColumn<ClientBrief>({
    headerName: t('client:field.isVerified'),
    field: 'isVerified',
    sortable: true,
  });
  const isLiquidated = useBoolTableColumn<ClientBrief>({
    headerName: t('client:field.isLiquidated'),
    field: 'liquidationDate',
  });

  return useMemo(
    () => [
      new TableLinkColumn<ClientBrief>({
        headerName: t('client:field.shortName'),
        field: 'shortName',
        text: (row) => (row.type === OrganizationTypeEnum.JURIDICAL ? row.shortName : row.fullName),
        linkProps: (row) => ({ to: ClientService.detailsUrl(row.guid) }),
        sortable: true,
      }),
      isVerified,
      new TableColumn<ClientBrief>({
        headerName: t('client:field.inn'),
        field: 'inn',
      }),
      new TableColumn<ClientBrief>({
        headerName: t('client:field.ogrnTable'),
        field: 'ogrn',
        valueGetter: ({ row }) => row.ogrnip ?? row.ogrn,
      }),
      new TableColumn<ClientBrief>({
        headerName: t('client:field.snils'),
        field: 'snils',
      }),
      new TableLinkColumn<ClientBrief>({
        headerName: t('client:field.owner'),
        field: 'lastName',
        text: (row) => row.owner ? UserService.getFullName(row.owner) : t('common:field.user'),
        linkProps: (row) => ({
          to: UserService.detailsUrl(row.owner?.uuid),
        }),
      }),
      new TableLinkColumn<ClientBrief>({
        headerName: t('client:field.employees'),
        field: 'employees',
        text: () => t('common:button.go'),
        linkProps: (row) => ({
          to: EMPLOYEE_ROUTES.list(),
          state: { client: row.guid, clientView: row.shortName },
        }),
      }),
      new TableDateTimeColumn<ClientBrief>({
        headerName: t('common:field.createDate'),
        field: 'createDate',
        sortable: true,
      }),
      new TableDateTimeColumn<ClientBrief>({
        headerName: t('common:field.updateDate'),
        field: 'updateDate',
        sortable: true,
      }),
      isActive,
      isLiquidated,
      isDeleted,
    ],
    [],
  );
}
