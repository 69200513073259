import { useTranslation } from 'react-i18next';
import { Title } from '../../../components/common/typography';
import { Content } from '../../../components/common';
import { Typography } from '@mui/material';

export function AccessDenied() {
  const { t } = useTranslation('error');

  return (
    <>
      <Title text={t('accessDenied.title')} />
      <Content>
        <Typography variant="h6" component="h2">{t('accessDenied.reason.title')}</Typography>
      </Content>
    </>
  );
}
