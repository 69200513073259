import { FilterTypeEnum } from './enum/filter.type.enum';
import { FilterItemProps } from './interfaces/filter.item.props';
import { AutocompleteResponse, SelectItem } from '../interfaces';
import { EntityQueryKey } from '../../query/query.keys';

export class FilterItem {
  type: FilterTypeEnum;
  field: string;
  labelKey: string;
  fetch?: (queryKey: EntityQueryKey, term: string | (string | number)[], skip?: number) => Promise<AutocompleteResponse>;
  options?: SelectItem[];
  multiple?: boolean;
  value?: any;
  view?: string;
  defaultValue?: any;
  defaultView?: string;
  nullable: boolean;
  queryKey?: EntityQueryKey;

  constructor(props: FilterItemProps) {
    this.type = props.type;
    this.field = props.field;
    this.labelKey = props.labelKey;
    this.fetch = props.fetch;
    this.options = props.options;
    this.multiple = props.multiple;
    this.nullable = props.nullable === undefined || props.nullable;
    this.queryKey = props.queryKey;
  }
}
