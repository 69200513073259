import ModelValidator from '../model.validator';
import { VerificationRequestEditDto } from '../../../model/dto/verification';

class Validator extends ModelValidator<VerificationRequestEditDto> {
  validate(model: VerificationRequestEditDto): string[] {
    const errors: string[] = [];
    this.checkRequired(model.clientGuid, errors);
    this.checkRequired(model.typeGuid, errors);
    this.checkRequired(model.data, errors);
    for (const d of model.data ?? []) {
      this.checkRequired(d.complexValueUuid ?? d.value, errors);
    }
    if (model.sourceType) {
      this.checkRequired(model.source, errors);
    }
    return errors;
  }
}

export const EditVerificationRequestValidator = new Validator();
