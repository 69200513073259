import {
  FilterAutocompleteItem,
  FilterDateRangeItem,
  FilterTextItem,
  useBoolFilterItem,
  UUID_FILTER,
} from '../../../model/filter';
import { AutocompleteService } from '../../../api/autocomplete.service';
import { EntityQueryKey } from '../../../query/query.keys';

export function useFilter() {
  return [
    new FilterDateRangeItem({
      field: 'createDate',
      labelKey: 'common:field.createDate',
    }),
    new FilterDateRangeItem({
      field: 'updateDate',
      labelKey: 'common:field.updateDate',
    }),
    UUID_FILTER,
    new FilterTextItem({
      field: 'search',
      labelKey: 'user:field.fullName',
    }),
    new FilterTextItem({
      field: 'email',
      labelKey: 'common:field.email',
    }),
    new FilterTextItem({
      field: 'phone',
      labelKey: 'common:field.phone',
    }),
    new FilterAutocompleteItem({
      field: 'client',
      labelKey: 'user:field.client',
      fetch: AutocompleteService.fetchFn,
      queryKey: EntityQueryKey.Client,
    }),
    useBoolFilterItem({
      field: 'isEmailVerified',
      labelKey: 'user:field.isEmailVerified',
    }),
    useBoolFilterItem({
      field: 'isPhoneVerified',
      labelKey: 'user:field.isPhoneVerified',
    }),
    useBoolFilterItem({
      field: 'isActive',
      labelKey: 'common:field.isActiveM',
    }),
    useBoolFilterItem({
      field: 'isAdmin',
      labelKey: 'user:field.isAdmin',
    }),
    useBoolFilterItem(
      {
        field: 'deleted',
        labelKey: 'common:field.deletedM',
        nullable: false,
      },
      'false',
    ),
  ];
}
