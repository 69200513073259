import { LeftMenuItem } from '../../../../model';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMenuItemClick } from './use.menu.item.click';
import FontAwesomeScalableIcon from '../FontAwesomeScalableIcon';

interface IProps {
  item: LeftMenuItem;
}

export function MenuItem({ item }: IProps) {
  const { t } = useTranslation('leftMenu');
  const [cookies] = useCookies([`menu-item:${item.id}`]);
  const [isOpen, setIsOpen] = useState(cookies[`menu-item:${item.id}`] === 'true');
  const onClick = useMenuItemClick(`menu-item:${item.id}`, setIsOpen);

  return (
    <>
      <ListItemButton onClick={(e) => onClick(e, item)}>
        {item.icon && (
          <ListItemIcon sx={{ pl: '5px' }}>
            <FontAwesomeScalableIcon icon={item.icon} />
          </ListItemIcon>
        )}
        <ListItemText primary={t(item.textKey)} />
        {item.children?.length && <>{isOpen ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItemButton>
      {item.children?.length && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 2 }}>
            {item.children.map((subItem, idx) => (
              <MenuItem key={idx} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
