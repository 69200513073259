import { FilterItem } from './filter.item';
import { FilterItemProps } from './interfaces/filter.item.props';
import { FilterTypeEnum } from './enum/filter.type.enum';

interface IProps extends  Omit<FilterItemProps, 'type' | 'labelKey'> {
  value: any;
}

export class FilterHiddenItem extends FilterItem {
  constructor(props: IProps) {
    super({ type: FilterTypeEnum.HIDDEN, labelKey: '', ...props });
    this.value = props.value;
  }
}