import { CertificateItemBrief } from '../../../model';
import { UserAction } from '../../../model/actions/user.action';
import { useTranslation } from 'react-i18next';
import { CertificateService } from '../../../api/certificate/certificate.service';

type AvailableActions = 'revoke' | 'renew';

export function useActions(): Record<AvailableActions, UserAction<CertificateItemBrief>> {
  const { t } = useTranslation();

  return {
    revoke: {
      key: 'revoke',
      btnText: () => t('certificate:button.revoke'),
      btnColor: () => 'error',
      available: (entity) => entity?.isRevoked === false,
      action: (entity) => CertificateService.revoke(entity?.uuid ?? ''),
      confirm: () => ({
        description: 'certificate:confirm.revoke',
      }),
    },
    renew: {
      key: 'renew',
      btnText: () => t('certificate:button.renew'),
      btnColor: () => 'success',
      available: (entity) => entity?.isRevoked === true || entity?.isValid === false,
      action: (entity) => CertificateService.renew(entity?.uuid ?? ''),
      confirm: () => ({
        description: 'certificate:confirm.renew',
      }),
    },
  }
}