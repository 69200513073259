import { RestrictedRoute } from '../../../components/common/route/RestrictedRoute';
import { ROUTE_RESTRICTIONS } from '../../../service/routeRestriction/route.rextrictions';
import { Route } from 'react-router-dom';
import React from 'react';
import { VERIFICATION_ROUTES } from '../../route';
import { VerificationRequestList } from '../VerificationRequestList';
import { VerificationRequestEdit } from '../VerificationRequestEdit';
import { VerificationRequestDetails } from '../VerificationRequestDetails';

export const VERIFICATION_ROUTE = (
  <Route>
    <Route path={VERIFICATION_ROUTES.list()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<VerificationRequestList />} />
    </Route>
    <Route path={VERIFICATION_ROUTES.create()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<VerificationRequestEdit />} />
    </Route>
    <Route path={VERIFICATION_ROUTES.edit()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<VerificationRequestEdit />} />
    </Route>
    <Route path={VERIFICATION_ROUTES.details()} element={<RestrictedRoute all={[ROUTE_RESTRICTIONS.IS_ADMIN]} />}>
      <Route path="" element={<VerificationRequestDetails />} />
    </Route>
  </Route>
);
