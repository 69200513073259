import { useTranslation } from 'react-i18next';
import { DataTable, Title } from '../../../components/common';
import { useFilter } from './filter.definition';
import { useColumns } from './table.definition';
import { UserService } from '../../../api/user/user.service';
import { EntityQueryKey } from '../../../query/query.keys';
import { useActions } from './action.definition';

export function UserList() {
  const { t } = useTranslation();
  const columns = useColumns();
  const filter = useFilter();
  const [rowActions, groupActions, toolbarActions] = useActions();

  return (
    <>
      <Title text={t('user:list.pageTitle')} />
      <DataTable
        id="user-list"
        queryKey={EntityQueryKey.User}
        columns={columns}
        fetchData={UserService.listFn}
        filter={filter}
        sortBy="lastName,firstName,secondName"
        rowActions={rowActions}
        groupActions={groupActions}
        toolbarActions={toolbarActions}
      />
    </>
  );
}