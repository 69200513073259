import { useTranslation } from 'react-i18next';
import { TableGroupAction, TableRowAction } from '../../../components/common/data/DataTable/table.model';
import { ClientBrief } from '../../../model';
import { ClientService } from '../../../api/client/client.service';
import {
  convertToRowAction,
  useGroupActions,
  useRowActions,
} from '../../../components/common/data/DataTable/table.utils';
import { UserAction } from '../../../model/actions/user.action';
import { useActions as useUserActions } from '../actions';

export function useActions(): [TableRowAction<ClientBrief>[], TableGroupAction[], UserAction<ClientBrief>[]] {
  const actions = useUserActions();
  const { t } = useTranslation();

  const rowActions = useRowActions<ClientBrief>({
    remove: convertToRowAction(actions.remove),
    toggleActive: convertToRowAction(actions.toggleActive),
    other: [
      convertToRowAction(actions.edit),
      convertToRowAction(actions.toggleVerified),
    ],
  });

  const groupActions = useGroupActions({
    remove: (selected) => ClientService.remove(selected as string[]),
    setActive: (selected) => ClientService.toggle('isActive', selected as string[], true),
    removeActive: (selected) => ClientService.toggle('isActive', selected as string[], false),
    other: [
      {
        value: 'removeVerified',
        text: t(`client:button.toggleVerified.false`),
        action: (sm) => ClientService.toggle('isVerified', sm as string[], false),
        confirm: {
          description: t('client:confirm.toggleVerified.false'),
        },
      },
    ],
  });

  const toolbarActions = [actions.add];

  return [rowActions, groupActions, toolbarActions];
}
