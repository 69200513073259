import { AppBar } from './app.bar';
import {
  Badge,
  Box,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useContext, useState } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SignOutRoute } from '../../../../page/signOut/routes';
import { grey } from '@mui/material/colors';
import { AuthService } from '../../../../auth/auth.service';
import { PageContext } from '../Page/page.context';

interface IProps {
  menuOpen: boolean;
  toggleDrawer: () => void;
}

export function Header({ menuOpen, toggleDrawer }: IProps) {
  const { t } = useTranslation();
  const user = AuthService.getUser();
  const { adminMenu, toggleAdminMenu } = useContext(PageContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="absolute" open={menuOpen}>
      <Toolbar
        sx={{
          bgcolor: 'white',
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          aria-label="open drawer"
          color="primary"
          onClick={toggleDrawer}
          sx={{
            marginRight: '30px',
            ...(menuOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h3"
          sx={{
            ...(menuOpen && { marginLeft: '-10px' }),
          }}
        >
          SIGN
          <Typography variant="h3" component="span" color={grey.A400}>
            X
          </Typography>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key={1} onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <Link underline="none" href="/profile">
                <ListItemText>{t('header:profile')}</ListItemText>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>
                <Link underline="none" href={SignOutRoute.SIGN_OUT}>
                  <ListItemText>{t('header:logout')}</ListItemText>
                </Link>
              </ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <IconButton color="primary">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {user?.isAdmin === true && (
          <Box ml={2}>
            <Switch checked={adminMenu} onChange={() => toggleAdminMenu()} />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
