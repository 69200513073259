import { DateDisplay, DetailPage, FileDisplay, LinkDisplay, TextDisplay } from '../../../components/common';
import { EntityQueryKey } from '../../../query/query.keys';
import { SnilsService } from '../../../api/snils/snils.service';
import { CLIENT_ROUTES, SNILS_ROUTES, USER_ROUTES } from '../../route';
import { useState } from 'react';
import { SnilsBrief } from '../../../model';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../api/user/user.service';
import { useActions } from '../actions';

export function SnilsDetails() {
  const { t } = useTranslation();
  const actions = useActions();
  const [entity, setEntity] = useState<SnilsBrief>();
  return (
    <DetailPage<SnilsBrief>
      queryKey={EntityQueryKey.Snils}
      titleParams={(entity) => [entity?.number]}
      service={SnilsService}
      routes={SNILS_ROUTES}
      actions={[actions.edit, actions.toggleActive, actions.toggleVerified, actions.remove]}
      entitySetter={setEntity}
    >
      <LinkDisplay labelKey="common:field.user" to={USER_ROUTES.details(entity?.userUuid)} text={UserService.getFullName(entity?.user)} editTo={USER_ROUTES.edit(entity?.userUuid)} />
      <LinkDisplay labelKey="common:field.client" to={CLIENT_ROUTES.details(entity?.clientGuid)} text={entity?.client?.fullName} editTo={CLIENT_ROUTES.edit(entity?.client?.uuid)} showBadge={entity?.client?.isVerified}/>
      <TextDisplay labelKey="snils:field.number" value={entity?.number} showBadge={entity?.isVerified}/>
      <TextDisplay labelKey="snils:field.fullName" value={[entity?.lastName, entity?.firstName, entity?.secondName].filter((s) => !!s).join(' ')}/>
      <DateDisplay labelKey="snils:field.birthDate" value={entity?.birthDate}/>
      <TextDisplay labelKey="snils:field.birthAddress" value={entity?.birthAddress}/>
      <TextDisplay labelKey="snils:field.gender" value={entity?.gender ? t(`common:gender.${entity?.gender}`) : ''}/>
      <DateDisplay labelKey="snils:field.issueDate" value={entity?.issueDate}/>
      <FileDisplay files={entity?.files}/>
      <DetailPage.Service>
        <TextDisplay labelKey="common:field.uuid" value={entity?.uuid}/>
        <TextDisplay labelKey="common:field.user" value={entity?.userUuid}/>
        <TextDisplay labelKey="common:field.client" value={entity?.clientGuid}/>
        <LinkDisplay labelKey="common:field.createdBy" to={USER_ROUTES.details(entity?.createdBy.uuid)} text={UserService.getFullName(entity?.createdBy)}/>
        <DateDisplay labelKey="common:field.createDate" value={entity?.createDate}/>
        <LinkDisplay labelKey="common:field.updatedBy" to={USER_ROUTES.details(entity?.updatedBy.uuid)} text={UserService.getFullName(entity?.updatedBy)}/>
        <DateDisplay labelKey="common:field.updateDate" value={entity?.updateDate}/>
      </DetailPage.Service>
    </DetailPage>
  );
}
